import { Assets } from '../Assets';

import { Link } from 'react-router-dom';

const MainContentContactUs = () => {

    return (
        <div className="laiqaMainContentBg">
            <div className="laiqaMainContentWrapper">
                <div className="laiqaMainContentMidLayer">
                    <img src={Assets.mainContentContactUs} id="wellnessPlansBanner1" />
                    {/* <img src={ Assets.laiqaMainContentMidLayerResp } id="wellnessPlansBanner2" /> */}
                </div>
            </div>
            <div className="breadcrumbContactUs">
                <div className="breadcrumbWrapper">
                    <div className="contactUsBreadcrumb">
                        <ul>
                            <li><Link to="/">Home • </Link></li>
                            <li id="selectedBreadCrumb"><Link to="/blog"> Contact us</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainContentContactUs;