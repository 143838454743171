import React, { useState, useEffect } from 'react'
import { Assets } from '../Assets';
import DatePicker from "react-datepicker";
import { GetAPI, PostAPI, PostAPIRazer } from '../utility/APiCall';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import Axios from '../Axios/axios'
import BookingConfirmation from './BookingConfirmation';

function BookDetails() {

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [datadoc, setDocData] = useState();
  const [getslots, setSlots] = useState();
  const [getslotsList, setSlotsGetList] = useState([]);
  const [getslotsTime, setSlotsGetTime] = useState();
  //console.log('getslotsTime',getslotsTime, getslotsList);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [appointDate, setAppointmentDate] = useState();
  const [getAmt, setGetAmt] = useState();
  const [getslotID, setGetSlotID] = useState();
  const [activeDate, setActiveDate] = useState(null);
  const [activeDateSel, setActiveDateSel] = useState(false);


  console.log('activeDate>>', activeDate);

  const [currentMonthYear, setCurrentMonthYear] = useState('');

  useEffect(() => {
    // Get current month and year
    const currentDate = new Date();
    const month = currentDate.toLocaleString('default', { month: 'long' });
    const year = currentDate.getFullYear();
    setCurrentMonthYear(`${month}, ${year}`);
  }, []);

  useEffect(() => {
    const DocIdd = sessionStorage.getItem('DocId');
    const DocId = DocIdd;
    var url = `/teleconsultation/doctor/${DocId}`;
    GetAPI(url, (res) => {
      console.log("API Response for doctor details", res);
      setDocData(res?.data[0]);
      setSlots(res?.slots);
      setGetAmt(res?.data[0]?.amount);
    });
  }, []);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };


  const [selectedSlot, setSelectedSlot] = useState(null);
  console.log('activeDate', activeDate);

  const renderSelectedDates = () => {

    const selectedDates = [];
    const today = new Date(); // Get today's date

    // Calculate the end date as today + 6 days
    const endDate = new Date(today);
    endDate.setDate(endDate.getDate() + 6);

    // Start from today
    let currentDate = new Date(today);

    // Loop through the dates until reaching the end date
    while (currentDate <= endDate) {
      // Add the date to the list if it's not in the past
      if (currentDate >= today) {
        selectedDates.push(new Date(currentDate));
      }
      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);
    }

    const handleClick = (date) => {
      setActiveDate(date); // Set the clicked date as active
      getSlotDate(date); // Call getSlotDate with the clicked date
      //alert('check');
      setActiveDateSel(true);
      setSelectedSlot(false);
      //selectedSlot == item ? 'active' : ''
    };

    console.log('activeDateSel', activeDateSel);

    return selectedDates.map((date, index) => (
      <li key={index}
        onClick={() => handleClick(date)}
        //className={activeDateSel === date ? 'active' : ''}
        className={activeDate?.toDateString() === date.toDateString() ? 'active' : ''}
      >
        {date.getDate()}
        <span>{getDayOfWeek(date)}</span>
      </li>
    ));

  };

  const getDayOfWeek = (date) => {
    // Array of weekday names
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    // Get the index of the weekday (0 to 6)
    const dayIndex = date.getDay();
    // Return the weekday name based on the index
    return weekdays[dayIndex];
  };

  const getSlotDate = (selectedDate) => {
    const formattedDate = `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}`;
    console.log('Formatted date:', formattedDate);
    setAppointmentDate(formattedDate);
    // Check if formattedDate is valid
    if (formattedDate) {
      const slotForSelectedDate = getslots?.find(slot => slot?.slot_date == formattedDate);
      // const slotForSelectedID = getslots?.find(slot => slot?.id == formattedDate);

      // console.log('slotForSelectedID',slotForSelectedID);

      // setGetSlotID(slotForSelectedID);

      if (slotForSelectedDate) {

        const slotID = slotForSelectedDate.id;
        console.log('Slot ID for selected date:', slotID);
        setGetSlotID(slotID);

        const slotTimes = slotForSelectedDate.slot_times.split(',');
        setSlotsGetList(prevState => {
          if (Array.isArray(slotTimes) && slotTimes.length > 0) {
            const formattedSlotTimes = slotTimes.map(slotTime => slotTime.slice(0, -3));
            return formattedSlotTimes;
            //return slotTimes;
          } else {
            return ['No slots available for this date'];
          }
        });
      } else {
        setSlotsGetList(['No slots available for this date']);
      }
    } else {
      console.log('Invalid formattedDate');
    }

  };



  const getslottime = (slotTime, SlotID) => {
    // Do something with the selected slot time
    console.log('Selected slot time:', slotTime);
    console.log('Selected slot ID:', SlotID);
    setSlotsGetTime(slotTime);
    setSelectedSlot(slotTime);
    // setGetSlotID(SlotID);
  };

  const DocIdd = sessionStorage.getItem('DocId');

  const userIdd = sessionStorage.getItem('getUID');

  const [data, setData] = useState({
    doctor_id: DocIdd,
    patient_id: userIdd,
    slot_id: getslotID,
    appointment_reason: "",
    appointment_date: appointDate,
    app_type: "",
    type: "",
    amount: getAmt,
    report_ids: "",
  });

  // console.log('datadoc>>',datadoc);

  // console.log('getAmt', getAmt);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const userIdd = sessionStorage.getItem('getUID');
    const form = event.currentTarget;

    if (form.checkValidity()) {
      if (event.target.name === 'insert') {
        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

        if (!res) {
          alert('Razorpay SDK failed to load. Are you online?');
          return;
        }

        const url = `/teleconsultation/add_appointment`;
        const formatDate = new FormData();
        formatDate.append('doctor_id', DocIdd);
        formatDate.append('patient_id', userIdd);
        formatDate.append('slot_id', getslotID);
        formatDate.append('appointment_reason', 'web');
        formatDate.append('appointment_date', appointDate);
        formatDate.append('app_type', 'online');
        formatDate.append('type', 1);
        formatDate.append('amount', getAmt);
        formatDate.append('report_ids', '');

        const params = { url, data: formatDate, body: formatDate, };
        console.log('params:', params);

        PostAPI(params, (res) => {
          if (res.status === 1) {
            console.log('API Response:', res);

            const data = res;
            //const { order_id, payment_id, app_id, razor_order_id, txn_message } = data;
            const { order_id, payment_id, app_id, razor_order_id, txn_message } = res;
            console.log('data>>>>>', data);

            if (!order_id || !razor_order_id) {
              console.error('Order ID or Razor Order ID is missing');
              alert("Order creation failed. Please try again.");
              return;
            }

            const amountInPaise = getAmt * 100; // Convert amount to paise
            console.log('Amount in Paise:', amountInPaise);

            const options = {
              key: "rzp_test_dV1CkVjvbk70lo", // rzp_live_pzbQ85kiye8FlL
              image: Assets?.laiqaLogo,
              // key: 'rzp_live_pzbQ85kiye8FlL', // Replace with your actual key //rzp_live_pzbQ85kiye8FlL // rzp_test_Wt3x56PXBVaqtf
              amount: amountInPaise,
              currency: 'INR',
              name: "laiqa wellness pvt ltd",
              description: "Test Transaction",
              // order_id: order_id,
              payment_id: payment_id,
              app_id: app_id,
              razor_order_id: razor_order_id,
              txn_message: txn_message,

              handler: async function (response) {

                const amountInPaise = getAmt * 100; // Convert amount to paise
                console.log('Amount in Paise:', amountInPaise); // Log amount in paise             ;
                setShowBookingReportPopup(true);
                const paymentData = {
                  payment_id: response.razorpay_payment_id,
                  order_id: order_id,
                  orderid: razor_order_id,
                  payment_type: 1,
                  payment_amt: amountInPaise, //getAmt,
                  payment_signature: 'test',
                  txn_message: txn_message,
                  payment_device: 1,
                  payment_txn_status: 2,
                  uid: 1,
                  razorpay_signature: response.razorpay_signature,
                };

                console.log('Payment Details:', paymentData);

                try {
                  PostAPI({ url: '/appointment_payment_success', data: paymentData }, (response) => {
                    console.log('Payment Successful Response', response);
                    if (response && response.status === 1 && response.message === 'Payment Data Inserted') {
                      //navigate('/');
                      setShowBookingReportPopup(true); // Open popup after payment success
                    }
                  });
                } catch (error) {
                  console.error('Payment request failed:', error.response || error);
                }
              },
              prefill: {
                name: 'Pandi',
                email: '',
                contact: '',
              },
              theme: {
                color: '#D27060',
              },
            };
            // alert('Checking payment');
            const razorpay = new window.Razorpay(options);
            razorpay.open();
            // if(razorpay){
            //   setShowBookingReportPopup(true);
            // }


          } else {
            alert("Couldn't pay");
          }
          // else {
          //   if (res.status === 400) {
          //     console.error('Bad Request Error:', res);
          //     alert(`Error: ${res.message || 'Invalid request'}`);
          //   } else {
          //     console.error('API Error:', res.message || 'Unexpected error');
          //     alert(`Error: ${res.message || 'Unexpected error'}`);
          //   }
          // }
        });
      }
    } else {
      console.error('Form validation failed');
    }
  };

  const languages = datadoc && datadoc[0]?.language ?  // Check if datadoc is defined and has language data
    datadoc[0]?.language?.map(language => `<span>${language}</span>`) :  // If so, map each language to a <span> element
    [];

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };
  const [showBookingReportPopup, setShowBookingReportPopup] = useState(false);

  // const openCompleteReportPopup = () => {
  //     setShowBookingReportPopup(true);
  // };

  const closeCompleteReportPopup = () => {
    setShowBookingReportPopup(false);
  };

  return (
    <div className="booking-details">
      <BookingConfirmation show={showBookingReportPopup} handleClose={closeCompleteReportPopup} />
      <div className="grid_width">
        <h5>Book Appointment</h5>
        <h2>
          Schedule a Call with <span style={{ fontWeight: "bold" }}>Specialized doctors</span>
        </h2>
      </div>
      <div className="booking-details-child">
        <div className="width25 w-25">
          <div className="bg-color-ef">
            <div className='rw'>
              <div className='gridimg'>
                <img alt='profile' src={datadoc?.doctor_image} />
              </div>
              <div className='gridcontent'>
                <h3>{datadoc?.name}</h3>
                <p>{datadoc?.parent_cat}</p>
                <span>{typeof datadoc?.language == 'string' ? datadoc?.language.split('').join(' . ') : datadoc?.language}</span>
              </div>
            </div>
            <div className='rw' style={{ marginTop: '10px' }}>
              <div className='box-bnx'>
                <img alt='profile' src={Assets.Group163988} />
                <p>Patients</p>
                <h4>2.05k</h4>
              </div>
              <div className='box-bnx'>
                <img alt='profile' src={Assets.Group163987} />
                <p>Experience</p>
                <h4>{datadoc?.years_experience}</h4>
              </div>
              <div className='box-bnx'>
                <img alt='profile' src={Assets.Group163986} />
                <p>Reviews</p>
                <h4>{datadoc?.rating}</h4>
              </div>
            </div>
            <div className='rw'>
              <div className='content'>
                <h3>About Doctor</h3>
                <p>{datadoc?.description}</p>
              </div>
            </div>
            <div className='rw'>
              <div className='box-bnx-white'>
                <div className='w_25percent'>
                  <img alt='Group' src={Assets.Group_163746} />
                </div>
                <div className='w_75percent'>
                  <h4>Consultation Fee</h4>
                  <p>(inclusive of applicable taxes)</p>
                </div>
                <div className='w_25percent'>
                  <h3>₹ {datadoc?.amount}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="width75 w-75">
          <div className="rw">
            <div className='colunm'>
              <h4>Choose date</h4>
            </div>
            <div className='colunm'>
              <h5>{currentMonthYear}
                <span onClick={toggleDatePicker}>
                  <img alt='calendar_icon' src={Assets.calendar_icon} />
                </span></h5>
              {showDatePicker && (
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={handleDateChange}
                  withPortal
                  showIcon
                  minDate={new Date()}
                />
              )}
            </div>
          </div>
          <div className="rw">
            <ul className='listDate'>
              {renderSelectedDates()}
            </ul>
          </div>
          <div className="rw">
            <p>Select a Time</p>
          </div>
          <form onSubmit={handleSubmit} name={"insert"} >
            <div className="rw">
              <ul className='slot'>
                {getslotsList && getslotsList.length > 0 ? (
                  getslotsList?.map((item, index) => (
                    <li key={index} onClick={() => getslottime(item)}
                      className={`${item == 'No slots available for this date' ? 'noslots' : ''} ${selectedSlot == item ? 'active' : ''}`}
                    //className={item == 'No slots available for this date' ? 'noslots' : ''}
                    //className={selectedSlot == item ? 'active' : ''}
                    //className={selectedSlot == item || item == 'No slots available for this date' ? 'active noslots' : ''} 
                    >
                      {item}
                    </li>
                  ))
                ) : (
                  <li className=''>{getslotsList}</li>
                )}
              </ul>
            </div>
            <div className="rw">
              <button type='submit' className='btn btn-primary'>Book Appointment <img alt='arrow_up_right' src={Assets.arrow_up_right} /> </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default BookDetails
