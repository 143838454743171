import React, { useEffect } from "react";
import { Assets } from "../Assets"
import { PostAPI, GetAPI } from "../utility/APiCall";
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function ContentFrameKyw3(props) {

    const { handleFormSubmit, category, sliderValue,
        setSliderValue, inputValue, setInputValue,
        sliderValue2, setSliderValue2, inputValue2, setInputValue2,
        handleSliderChange, handleSliderChange2, handleInputChange, handleInputChange2, height, selectedScaleItem,
        activity_selectedScaleItem, setHeight, getSleepData, setSleepData, getSleepscaleData, setSleepscaleData,
        journey_id_already, setJourneyIdAlready } = props;

    const navigate = useNavigate();

    const [isHovered, setIsHovered] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const storedCategoryId = sessionStorage.getItem('getBMIID');

    console.log('journey_id_already:', journey_id_already);

    const handleSubmit = (e) => {

        e.preventDefault(); // Prevents the default form submission behavior

        const journey_id = sessionStorage.getItem('journey_id');
        // Validate input values
        // Validate input values
        //    if (journey_id_already) {
        //     //setJourneyMessage('You have already filled this month’s category');
        //     alert('You have already filled this month’s category');
        //     navigate('/');
        //   } else {
        //         if (!inputValue || !inputValue2) {
        //         alert("Please fill in all fields"); // Show an alert message
        //         setShowLoginPopup(false); // Set showLoginPopup to true if inputs are not filled
        //         return; // Exit the function if either input is empty
        //         } else {
        //         setShowLoginPopup(true); // Set showLoginPopup to false if both inputs are filled
        //         }
        //     }

        if (!inputValue || !inputValue2) {
            alert("Please fill in all fields"); // Show an alert message
            setShowLoginPopup(false); // Set showLoginPopup to true if inputs are not filled
            return; // Exit the function if either input is empty
        } else {
            setShowLoginPopup(true); // Set showLoginPopup to false if both inputs are filled
        }

        setFormSubmitted(true); // Update the state to mark form as submitted
        props.handleFormSubmit(e); // Call the parent's form submission function

    };

    // const [isHovered, setIsHovered] = useState(false);

    const getBmiCategoryColor = () => {
        if (getSleepData < 18.5) {
            return '#30262880';
        } else if (getSleepData >= 18.5 && getSleepData < 25) {
            return '#7AB375';
        } else if (getSleepData >= 25 && getSleepData < 30) {
            return '#E9DD6C';
        } else {
            return '#D27060';
        }
    };

    const bmiCategoryColor = getBmiCategoryColor();


    const getCategoryList = () => {
        const url = `/get_all_onboarding_categories`;
        GetAPI(url, (res) => {
            const getdetails = res?.data;
            //console.log('getdetails', getdetails);
            const getSleep = res?.data.find(item => item.id === "3");
            const SleepID = getSleep.id;
            //console.log('getSleep', SleepID);
            sessionStorage.setItem('getSleep', SleepID);
            //sessionStorage.setItem('getMenstrualPain', SleepID);
            setGetSleep(SleepID);
        });
    }

    useEffect(() => {
        getCategoryList();
    }, []);


    const clickNext = () => {
        navigate('/selfprognosis/know-your-wellness/sleep-assessment');
    }

    const user_name = sessionStorage.getItem('user_name');

    const bmi_score = sessionStorage.getItem('bmi_score');
    const bmi_initialsliderscore = bmi_score ? parseFloat(bmi_score).toFixed(2) : '0.00';
    const bmi_scaleString = sessionStorage.getItem('bmi_scale');
    const bmi_scale = JSON.parse(bmi_scaleString);

    const [visible, setVisible] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const [showLoginPopup, setShowLoginPopup] = useState(false);

    const handleClose = () => {
        setIsOpen(false);
    };

    const openLoginPopup = () => {
        setShowLoginPopup(true);
    };

    const closeLoginPopup = () => {
        setShowLoginPopup(false);
    };

    const showHideClassName2 = showLoginPopup
        ? 'loginPopupBg result display-block'
        : 'loginPopupBg result display-none';

    const [getSleep, setGetSleep] = useState(null);

    return (
        <div className="contentFrameKyw">
            <div className="contentFrameKywInner">
                <div className="kyw1BgImage position-relative">
                    <div className='absoluteStage'>
                        <p className="mb-0" style={{ marginBottom: '0px' }}>Step 2</p>
                        <p>Body mass index</p>
                    </div>
                    <img src={Assets.bmiBg} alt="Body Mass Index" />
                    <div className='absolute'>
                        <h4>Why BMI is evaluated?</h4>
                        <p>BMI is evaluated to check if your weight is healthy for your height. It helps doctors and researchers see if you might have health problems related to being too heavy or too light. They use it to understand your risk for conditions like heart disease, diabetes, and high blood pressure. It's like a quick way to see if you're in a healthy weight range.</p>
                    </div>
                </div>
                <div className="vertProgressLine"></div>
                <div className="vertProgressLine2"></div>
                <div className="progressCirclesContainer">
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"><div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg1" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter2">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> 2 </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg2" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleHalf" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter3">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 3 </div>
                        </div>
                    </div>
                    <div className="progressCirclesOuter4">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 4 </div>
                        </div>
                    </div>
                    <div className="progressCirclesOuter5">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 5 </div>
                        </div>
                    </div>
                    <div className="progressCirclesOuter6">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 6 </div>
                        </div>
                    </div>
                    <div className="progressCirclesOuter7">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 7 </div>
                        </div>
                    </div>
                </div>
                <div className="kyw1MainContent">
                    {formSubmitted ? (
                        <>
                            <div className="kyw4MainContentHeading">
                                <p>Hey {user_name},</p>
                            </div>
                            <div className="kyw4MainContentSubHeading">
                                <p>Thanks for taking the BMI test</p>
                            </div>
                            <div className="bmiResultWrapper">
                                <div className="bmiResultHeading">
                                    <p>Based on your answers, your BMI is </p>
                                </div>
                                <div className="bmiResult">
                                    <div className="bmiResultFloatResponse">
                                        <input
                                            type="text"
                                            id="bmiHeightInputId"
                                            value={getSleepData}
                                            contentEditable="false"
                                            maxLength={2}
                                        />
                                    </div>
                                    <div className="bmiResultStringResponse">
                                        <div style={{ marginTop: '10px', color: bmiCategoryColor }}>
                                            {bmiCategoryColor === '#30262880' && 'Underweight'}
                                            {bmiCategoryColor === '#7AB375' && 'Normal'}
                                            {bmiCategoryColor === '#E9DD6C' && 'Pre-obesity'}
                                            {bmiCategoryColor === '#D27060' && 'Obesity'}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="">
                                        <div className="bmiSliderContainer">
                                            <input
                                                type="range"
                                                id="slider"
                                                className="bmiSlider"
                                                name="slider"
                                                min="0"
                                                max="40"
                                                value={getSleepData}
                                            // onChange={ handleSliderChange }
                                            />
                                        </div>
                                    </div>
                                    <div className="bmiLogoBr">
                                        <img alt="" src={Assets.bmiLogo} />
                                    </div>
                                </div>
                            </div>
                            <div className="bmiCategories">
                                <ul>
                                    {getSleepscaleData && getSleepscaleData?.map(item => (
                                        <li className={item.selected ? 'selected' : ''} key={item.id}>
                                            {item.description}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="kywNextLinkWrapper">
                                <Link className="backLink" to="" onClick={() => {
                                    navigate(-1);
                                }}>
                                    <img src={Assets.backArrow} alt="Back" />
                                    Back
                                </Link>
                                <button type="button" onClick={clickNext} id="kywNextButtonSmall"
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}> Next
                                    {isHovered ? <img alt="" src={Assets.arrowUpRight} /> : <img alt="" src={Assets.arrowUpRightBlack} />}
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="kyw1MainContentHeading">
                                <h1>Please enter your Height and Weight</h1>
                            </div>
                            <form
                                onSubmit={handleSubmit}
                            >
                                <div className="bmiInputWrapper">
                                    <div className="bmiInput">
                                        <input
                                            value={inputValue}
                                            onChange={handleInputChange}
                                            id="bmiHeightInputId"
                                            type="text"
                                            maxLength={3} hidden />
                                        <label htmlFor="bmiHeightInputId">
                                            <span className="input">{height}</span> Height (cm)</label>
                                    </div>
                                    <div className="kyw3MainContentItems">
                                        <div className="kywSlideContainer">
                                            <input
                                                type="range"
                                                id="sliderHeight"
                                                className="bmiSlider"
                                                name="slider"
                                                min="0"
                                                max="300"
                                                value={sliderValue}
                                                onChange={handleSliderChange}
                                            />
                                            <label htmlFor="sliderHeight"> 0 cm</label>
                                        </div>
                                    </div>
                                    <div className="bmiInput">
                                        <input
                                            value={inputValue2}
                                            onChange={handleInputChange2}
                                            id="bmiWeightInputId"
                                            type="text"
                                            maxLength={3} />
                                        <label htmlFor="bmiWeightInputId">Weight (kg)</label>
                                    </div>
                                    <div className="kyw3MainContentItems">
                                        <div className="kywSlideContainer">
                                            <input
                                                type="range"
                                                id="sliderWeight"
                                                className="bmiSlider"
                                                name="slider"
                                                min="0"
                                                max="200"
                                                value={sliderValue2}
                                                onChange={handleSliderChange2}
                                            />
                                            <label htmlFor="sliderHeight">0 kg</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="kywNextLinkWrapper">
                                    <Link onClick={() => {
                                        navigate(-1);
                                    }} className="backLink" to="">
                                        <img src={Assets.backArrow} alt="Back" />
                                        Back
                                    </Link>
                                    <button id="kywNextButtonSmall"
                                        type="submit"
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)} onClick={openLoginPopup}>
                                        Calculate BMI
                                        {isHovered ? <img alt="" src={Assets.arrowUpRight} /> : <img alt="" src={Assets.arrowUpRightBlack} />}
                                    </button>
                                </div>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ContentFrameKyw3;