import { Assets } from '../Assets';

import { useState } from 'react';
import { Link } from 'react-router-dom'

//import { useData } from '../../DataContext';
import { useData } from '../Context/AppContextProvider';
const PriceListingMenstrualWellbeing = () => {

    const { sharedData, setSharedData } = useData();

    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const [isHovered3, setIsHovered3] = useState(false);
    const [isHovered4, setIsHovered4] = useState(false);
    const [isHovered5, setIsHovered5] = useState(false);
    const [isHovered6, setIsHovered6] = useState(false);

    return (
        <div className="priceListingContent">
            {/* <div className="shopContentBgImg">
                <img src={ shopAndFeedbackBg } />
            </div> */}
            <div className="teleconsMainContentBannerBottom">
                <div className="teleconsMainContentBannerBottomWrapper">
                    <img alt='' src={Assets.menstrualWellbeingBottomBanner} />
                </div>
            </div>
            <div className="shopContentWrapper">
                <div className="priceListingHeadingContent">
                    <div className="shopContentHeading">
                        <p className="shopContentHeading1">Price Listing</p>
                        <p className="shopContentHeading2">Laiqa <b>Wellness Plans</b></p>
                    </div>
                </div>
            </div>
            <div className="wellnessPlansPriceList">
                <div className="wellnessPlansPriceListWrapper">

                    <Link to="/wellness-plans/checkout" onClick={() => setSharedData(3)} >
                        <div className="wellnessSilverPlan">
                            <div className="wellnessPlanHeading" id="silverPlanHeading">
                                <p className="planNameHeading">Silver Plan</p>
                                <div className="wellnessSilverPlanHeadingPrice">
                                    <b><p>&#8377;8,999</p></b>
                                    <strike><p> 9,999</p></strike>
                                </div>
                            </div>
                            <div className="wellnessPlanListContent">
                                <ul>
                                    <li>Duration <b>3 Months</b></li>
                                    <li><b>Monthly</b> Lifestyle & Menstrual Assessment</li>
                                    <li><b>Monthly</b> Ayurveda Assessment</li>
                                    <li><b>Fortnightly</b> Specialist Consultations (Ayurvedic Doctor / Dietician)</li>
                                    <li><b>One time</b> Mind & Body Analysis Report</li>
                                    <li><b>One time</b> Customised expert recommendations</li>
                                    <li><b>Monthly</b> Progress Tracking Report</li>
                                    <li><b>Weekly</b> Phase based Diet Plan</li>
                                    <li><b>Weekly</b> Phase based Fitness Plan</li>
                                    <li><b>During Periods</b> Phase based Fitness Plan</li>
                                    <li><b>₹900</b> Product Voucher</li>
                                    <li><b>Content Access</b></li>
                                    <li><b>Meal time & Exercise reminders</b></li>
                                    <li><b>Weekly</b> Workshops by experts</li>
                                    <li><b>Weekly</b> Newsletters</li>
                                    <li><b>Chat Support</b></li>
                                    <li><b>20%</b> Additional Product Discount</li>
                                    <li><b>10%</b> Superspecialist Teleconsultation Discount</li>
                                </ul>
                            </div>
                            <div className="wellnessPlansListContentEndButtons">
                                <button id="listContentButton1"
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                >
                                    Buy Plan
                                    {isHovered ? <img src={Assets.shoppingBagWhite} /> : <img src={Assets.shoppingBag} />}
                                </button>
                                <button id="listContentButton2"
                                    onMouseEnter={() => setIsHovered2(true)}
                                    onMouseLeave={() => setIsHovered2(false)}
                                >
                                    Schedule Call
                                    {isHovered2 ? <img src={Assets.phoneCallWhite} /> : <img src={Assets.phoneCall} />}
                                </button>
                            </div>
                        </div>
                    </Link>

                    <div className="wellnessPlansMostPopularTagWrapper">
                        <div className="wellnessPlansMostPopularTag">
                            <div className="mostpopular">
                                MOST POPULAR
                            </div>
                            <div className="offersMostPopularTag">
                                20% off
                            </div>
                        </div>
                    </div>

                    <Link to="/wellness-plans/checkout" onClick={() => setSharedData(4)}>

                        <div className="wellnessGoldPlan">
                            <div className="wellnessPlanHeading" id="goldPlanHeading">
                                <p className="planNameHeading">Gold Plan</p>
                                <div className="wellnessSilverPlanHeadingPrice">
                                    <b><p>&#8377;13,499</p></b>
                                    <strike><p> 14,999</p></strike>
                                </div>
                            </div>
                            <div className="wellnessPlanListContent">
                                <ul>
                                    <li>Duration <b>6 Months</b></li>
                                    <li><b>Monthly</b> Lifestyle & Menstrual Assessment</li>
                                    <li><b>Monthly</b> Ayurveda Assessment</li>
                                    <li><b>Fortnightly</b> Specialist Consultations (Ayurvedic Doctor / Dietician)</li>
                                    <li><b>One time</b> Mind & Body Analysis Report</li>
                                    <li><b>One time</b> Customised expert recommendations</li>
                                    <li><b>Monthly</b> Progress Tracking Report</li>
                                    <li><b>Weekly</b> Phase based Diet Plan</li>
                                    <li><b>Weekly</b> Phase based Fitness Plan</li>
                                    <li><b>During Periods</b> Phase based Fitness Plan</li>
                                    <li><b>₹1800</b> Product Voucher</li>
                                    <li><b>Content Access</b></li>
                                    <li><b>Meal time & Exercise reminders</b></li>
                                    <li><b>Weekly</b> Workshops by experts</li>
                                    <li><b>Weekly</b> Newsletters</li>
                                    <li><b>Chat Support</b></li>
                                    <li><b>25%</b> Additional Product Discount</li>
                                    <li><b>15%</b> Superspecialist Teleconsultation Discount</li>
                                </ul>
                            </div>
                            <div className="wellnessPlansListContentEndButtons">
                                <button id="listContentButton1"
                                    onMouseEnter={() => setIsHovered3(true)}
                                    onMouseLeave={() => setIsHovered3(false)}
                                >
                                    Buy Plan
                                    {isHovered3 ? <img src={Assets.shoppingBagWhite} /> : <img src={Assets.shoppingBag} />}
                                </button>
                                <button id="listContentButton2"
                                    onMouseEnter={() => setIsHovered4(true)}
                                    onMouseLeave={() => setIsHovered4(false)}
                                >
                                    Schedule Call
                                    {isHovered4 ? <img src={Assets.phoneCallWhite} /> : <img src={Assets.phoneCall} />}
                                </button>
                            </div>
                        </div>
                    </Link>

                    <Link to="/wellness-plans/checkout" onClick={() => setSharedData(5)}>

                        <div className="wellnessPlatinumPlan">
                            <div className="wellnessPlanHeading" id="platinumPlanHeading">
                                <p className="planNameHeading">Platinum Plan</p>
                                <div className="wellnessSilverPlanHeadingPrice">
                                    <b><p>&#8377;21,599</p></b>
                                    <strike><p> 23,999</p></strike>
                                </div>
                            </div>
                            <div className="wellnessPlanListContent">
                                <ul>
                                    <li>Duration <b>12 Months</b></li>
                                    <li><b>Monthly</b> Lifestyle & Menstrual Assessment</li>
                                    <li><b>Monthly</b> Ayurveda Assessment</li>
                                    <li><b>Fortnightly</b> Specialist Consultations (Ayurvedic Doctor / Dietician)</li>
                                    <li><b>One time</b> Mind & Body Analysis Report</li>
                                    <li><b>One time</b> Customised expert recommendations</li>
                                    <li><b>Monthly</b> Progress Tracking Report</li>
                                    <li><b>Weekly</b> Phase based Diet Plan</li>
                                    <li><b>Weekly</b> Phase based Fitness Plan</li>
                                    <li><b>During Periods</b> Phase based Fitness Plan</li>
                                    <li><b>₹3600</b> Product Voucher</li>
                                    <li><b>₹1500</b> Teleconsultation Voucher</li>
                                    <li><b>Content Access</b></li>
                                    <li><b>Meal time & Exercise reminders</b></li>
                                    <li><b>Weekly</b> Workshops by experts</li>
                                    <li><b>Weekly</b> Newsletters</li>
                                    <li><b>Chat Support</b></li>
                                    <li><b>30%</b> Additional Product Discount</li>
                                    <li><b>20%</b> Superspecialist Teleconsultation Discount</li>
                                </ul>
                            </div>
                            <div className="wellnessPlansListContentEndButtons">
                                <button id="listContentButton1"
                                    onMouseEnter={() => setIsHovered5(true)}
                                    onMouseLeave={() => setIsHovered5(false)}>
                                    Buy Plan
                                    {isHovered5 ? <img src={Assets.shoppingBagWhite} /> : <img src={Assets.shoppingBag} />}
                                </button>
                                <button id="listContentButton2"
                                    onMouseEnter={() => setIsHovered6(true)}
                                    onMouseLeave={() => setIsHovered6(false)}>
                                    Schedule Call
                                    {isHovered6 ? <img src={Assets.phoneCallWhite} /> : <img src={Assets.phoneCall} />}
                                </button>
                            </div>
                        </div>
                    </Link>

                </div>
            </div>

            <div className="wellnessPlansBenefit">
                <div className="wellnessPlansBenefitWrapper">
                    <p><b>Benefits</b> Of The Plan</p>
                    <img src={Assets.benefitsOfThePlan} alt="Benefits of the plan" />
                </div>
            </div>

        </div>
    );
}

export default PriceListingMenstrualWellbeing;