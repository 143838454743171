// import { Assets } from '../Assets';
// import { useState } from 'react';
// import { Link } from 'react-router-dom';

import React, { useEffect, useState, useMemo } from 'react';
import { Assets } from '../Assets';
import { Link } from 'react-router-dom';
import { GetAPI } from '../utility/APiCall';
import { useNavigate } from 'react-router-dom';

const ConsultationContentTeleconsultationPage = () => {

  // const [isHovered2, setIsHovered2] = useState(false);

  // const imageItems = [
  //     { id: 1, src: Assets.nutritionistWhite, hoverSrc: Assets.nutritionist, alt: 'Nutritionist' },
  //     { id: 2, src: Assets.gynaecologistWhite, hoverSrc: Assets.gynaecologist, alt: 'Gynaecologist' },
  //     { id: 3, src: Assets.psychologistWhite, hoverSrc: Assets.psychologist, alt: 'Psychologist' },
  // ];


  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  //const { itemsPerPage, setItemsPerPage } = props;
  const [activePage, setActivePage] = useState(3)
  const [columnSorter, setColumnSorter] = useState(null);
  //const [itemsPerPage, setItemsPerPage] = useState('')
  const [docInfo, setUsers] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedGender, setSelectedGender] = useState([]);
  const [selecteSpeciality, setSelectedSpeciality] = useState([]);
  const [originalUsersData, setOriginalUsersData] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [records, setRecords] = useState([])
  const [visible, setVisible] = useState({ form: false, alert: false });
  const [visibleview, setVisibleview] = useState(false);
  const [pagination, setPagination] = useState({
    activePage: 1,
    columnSorter: null,
    itemsPerPage: 10,
    totalListCount: docInfo.length,
    search: '',
  });
  console.log('pagination', pagination);
  const [error, setError] = useState(false);
  const [flag, setFlag] = useState(false)

  const [isHovered2, setIsHovered2] = useState(false);

  const imageItems = [
    { id: 1, src: Assets.nutritionistWhite, hoverSrc: Assets.nutritionist, alt: 'Nutritionist' },
    { id: 2, src: Assets.gynaecologistWhite, hoverSrc: Assets.gynaecologist, alt: 'Gynaecologist' },
    { id: 3, src: Assets.psychologistWhite, hoverSrc: Assets.psychologist, alt: 'Psychologist' },
  ];

  const table_list = (callback) => {
    let params = new URLSearchParams();
    pagination?.columnSorter &&
      pagination?.columnSorter.column &&
      params.append(
        'sort',
        `${pagination?.columnSorter.column}%${pagination?.columnSorter.state}`
      );
    pagination?.search && params.append('search', `${pagination?.search}`);
    const url = `/teleconsultation/list_doctors?page=${pagination?.activePage}&limit=${pagination?.itemsPerPage
      }&${params?.toString()}`;
    GetAPI(url, (res) => {
      if (res?.status == 1) {

        // Create a temporary set to store unique parent_cat values
        const uniqueParentCats = new Set();

        // Filter out duplicate items based on parent_cat
        const filteredData = res?.data.filter(item => {
          // Check if the parent_cat value is already in the set
          // If it's not, add it to the set and return true to keep the item
          // If it's already in the set, return false to filter out the item
          if (!uniqueParentCats.has(item.parent_cat)) {
            uniqueParentCats.add(item.parent_cat);
            return true;
          }
          return false;
        });

        // Log the filtered data
        console.log('filteredData>>>', filteredData);

        // Set the filtered data to your state
        setUsers(filteredData);

        //setUsers(res?.data);
        const originalData = res?.data;
        setOriginalUsersData(originalData);
        const totalListCount = res?.total;
        console.log('totalListCount', totalListCount);
        setPagination({
          ...pagination,
          totalListCount: res?.total,
        });
        // Call the callback function to indicate completion
        if (callback) {
          callback();
        }
      }
    });
  };

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      // Call table_list with a callback function
      table_list(() => {
        setIsFetching(false);
      });
    }
  }, [flag, pagination.activePage, pagination.columnSorter, pagination.itemsPerPage, pagination.search]);

  const clickGetId = (parent_cat) => {
    //console.log('GetId',id);
    sessionStorage.setItem('getCatId', parent_cat);
    navigate('/teleconsultation/select-specialist');
  }

  const ImageItem = ({ item }) => {
    const [isHovered, setHovered] = useState(false);
    return (
      // <li className="consultationSpecialistListItem" onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      //     <p className="book">Book</p>
      //     <p className="specialistType">
      //         { item.alt }
      //     </p>
      //     { isHovered &&
      //         <div className="specialistBubbleWrapper">
      //             <img alt='' className="specialistBubbleInner" src={ Assets.specialistBubbleInner } />
      //             <img alt='' className="specialistBubbleOuter" src={ Assets.specialistBubbleOuter } />
      //         </div>
      //     }
      //     <div className="consultationSpecialistImageWrapper">
      //         <img src={ isHovered ? item.hoverSrc : item.src } alt={ item.alt } />
      //     </div>
      // </li>
      <li className="consultationSpecialistListItem" onClick={() => clickGetId(item.parent_cat)} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
        <p className="book">Book</p>
        <p className="specialistType">
          {item?.parent_cat}
        </p>
        {isHovered &&
          <div className="specialistBubbleWrapper">
            <img alt='' className="specialistBubbleInner" src={Assets?.specialistBubbleInner} />
            <img alt='' className="specialistBubbleOuter" src={Assets?.specialistBubbleOuter} />
          </div>
        }
        <div className="consultationSpecialistImageWrapper">
          {/* <img src={isHovered ? Assets?.nutritionist : Assets?.nutritionistWhite} alt={item?.parent_cat} /> */}
          <img
            src={item?.parent_cat == 'Dietician' ?
              (isHovered ? Assets?.psychologist : Assets?.psychologistWhite) :
              item?.parent_cat == 'Gynacologist' ?
                (isHovered ? Assets?.gynaecologist : Assets?.gynaecologistWhite) :
                item?.parent_cat == 'Dietician' ?
                  (isHovered ? Assets?.psychologist : Assets?.psychologistWhite) :
                  item?.parent_cat == 'Ayurveda' ?
                    (isHovered ? Assets?.psychologist : Assets?.psychologistWhite) :
                    (item?.parent_cat == 'GP' ?
                      (isHovered ? Assets?.nutritionist : Assets?.nutritionistWhite) :
                      Assets?.nutritionist)}
            alt={item?.parent_cat}
          />
        </div>
      </li>
    );
  }

  return (
    <div className="consultationContent" style={{ margin: '0px' }}>
      <div className="consultationContentWrapper">
        <div className="consultationContentBgImg" style={{
          backgroundImage: `url(${Assets.consultationContentBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "100%",
          display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
        }}>
          {/* <img alt='' src={Assets.consultationContentBg} id="consultationContentBgImg1" />
                    <img alt='' src={Assets.consultationContentBgResp} id="consultationContentBgImg2" /> */}
          <div className="consultationTextContent">
            <div className="consultationContentHeading">
              <p className="consultationHeading1">Do You Have Any Health Concerns To Discuss?</p>
              <p className="consultationHeading2">Book a Consultation</p>
              <p className="consultationHeading3">with our experts</p>
              <Link to="/teleconsultation/select-specialist" style={{ textDecoration: "none" }}>
                <button id="viewAllSpecialistsHomePage">
                  View all
                  <img alt='' src={Assets.arrowUpRight} />
                </button>
              </Link>
            </div>
          </div>
          <div className="consultationSpecialistContent">
            <ul className="consultationSpecialistWrapper">
              {docInfo.slice(0, 3)?.map(item => (
                <ImageItem key={item.id} item={item} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConsultationContentTeleconsultationPage;