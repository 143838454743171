import React from "react";
import { Assets } from "../Assets"
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Cookies from "js-cookie";

const ContentFrameKyw5 = (props) => {

    const navigate = useNavigate();

    const {
        questionId = [], answers, errors, setErrors, handleRadioChange, inputValue, handleCheckboxChange,
        getSleepData, setSleepData, message, sliderValue, setMessage, handleFormSubmit, getSleepscaleData,
        setSleepscaleData, isChecked, setIsChecked, currentStep, nextStep, formSubmitted, setFormSubmitted } = props;

    const [isHovered, setIsHovered] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevents the default form submission behavior

        // If all questions are answered, proceed with form submission
        setShowLoginPopup(true); // Set showLoginPopup to true
        setFormSubmitted(true); // Update the state to mark form as submitted
        props.handleFormSubmit(e); // Call the parent's form submission function
        //alert('check');
        //navigate('/selfprognosis/know-your-wellness/sleep-assessment-result');
    };

    const clickNext = (e) => {
        e.preventDefault(); // Prevents the default form submission behavior
        navigate('/selfprognosis/know-your-wellness/wellness-report');
        //navigate('/selfprognosis/know-your-wellness/sleep-assessment-result');
    }

    const [visible, setVisible] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const [showLoginPopup, setShowLoginPopup] = useState(false);

    const handleClose = () => {
        setIsOpen(false);
    };

    const openLoginPopup = () => {
        //setShowLoginPopup(true);
        //alert('Check');
        setFormSubmitted(true); // Update the state to mark form as submitted
    };

    const closeLoginPopup = () => {
        setShowLoginPopup(false);
    };

    //const showHideClassName2 = show ? 'loginPopupBg display-block' : 'loginPopupBg display-none';
    const showHideClassName2 = showLoginPopup
        ? 'loginPopupBg result display-block'
        : 'loginPopupBg result display-none';

    //const user_name = sessionStorage.getItem("user_name");

    const user_name = Cookies.get('user_name');

    const getBmiCategoryColor = () => {
        if (getSleepData == 0) {
            return '#30262880';
        } else if (getSleepData >= 1 && getSleepData < 5) {
            return '#7AB375';
        } else if (getSleepData >= 5 && getSleepData < 8) {
            return '#E9DD6C';
        } else {
            return '#D27060';
        }
    };

    const activity_selectedScaleItem = getBmiCategoryColor();

    const menstrulpain_score = sessionStorage.getItem('menstrulpain_score');
    // const menstrulpain_scale = sessionStorage.getItem('menstrulpain_scale');
    const renderQuestions = () => {
        const steps = {
            1: ["36"],
            2: ["37", "38", "39"],
        };

        if (!steps[currentStep]) {
            console.error(`No steps defined for currentStep: ${currentStep}`);
            return null;
        }

        const questionsToRender = questionId.filter(item => steps[currentStep].includes(item.question_id));

        return questionsToRender.map((item, index) => (
            <div key={index} className="kyw1MainContentHeading">
                <h1 style={{ marginTop: '0px' }}>{item.question}</h1>
                {item.options.map((option, j) => (
                    <div key={j} className="flex items-center mr-4 mb-4 radio">
                        {item.question_id === "37" ? (
                            <React.Fragment>
                                <input
                                    id={`${item.question_id}_${option.id}`}
                                    value={option.id}
                                    type="checkbox"
                                    onChange={() => handleCheckboxChange(item.question_id, option.id)}
                                    checked={inputValue[item.question_id]?.some((ans) => ans.answer === option.id)}
                                    className="hidden"
                                />
                                <label htmlFor={`${item.question_id}_${option.id}`} className="flex items-center cursor-pointer">
                                    <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                                    {option.option}
                                </label>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <input
                                    id={`${option.id}_${item.question_id}`}
                                    value={option.id}
                                    type="radio"
                                    onChange={() => handleRadioChange(item.question_id, option.id)}
                                    checked={inputValue[item.question_id] === option.id}
                                    name={`radio${index}`}
                                    className="hidden"
                                />
                                <label htmlFor={`${option.id}_${item.question_id}`} className="flex items-center cursor-pointer">
                                    <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                                    {option.option}
                                </label>
                            </React.Fragment>
                        )}
                    </div>
                ))}
            </div>
        ));
    };

    return (
        <div className="contentFrameKyw">
            <div className="contentFrameKywInner">
                <div className="kyw1BgImage position-relative">
                    <div className='absoluteStage'>
                        <p className="mb-0" style={{ marginBottom: '0px' }}>Step 6</p>
                        <p>Menstrual Pain Assessment</p>
                    </div>
                    <img src={Assets.painActivityBg} alt="Body Mass Index" />
                    <div className='absolute'>
                        <h4>Why MPA is evaluated?</h4>
                        <p>Menstrual Pain Assessment is conducted to diagnose and manage menstrual pain, providing insights into underlying conditions and guiding personalized treatment plans for improved reproductive health and quality of life.</p>
                    </div>
                </div>
                <div className="vertProgressLine"></div>
                <div className="vertProgressLine2"></div>
                <div className="vertProgressLine3"></div>
                <div className="vertProgressLine4"></div>
                <div className="vertProgressLine5"></div>
                <div className="vertProgressLine6"></div>
                <div className="progressCirclesContainer">
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg1" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg2" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg3" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg4" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg5" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> 6 </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg6" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleHalf" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 7 </div>
                        </div>
                    </div>
                </div>
                <div className="kyw5MainContent">
                    {formSubmitted ? (
                        <>
                            {/* <div className="kyw1MainContent"> */}
                            <div className="kyw4MainContentHeading">
                                <p>Hey {user_name},</p>
                            </div>
                            <div className="kyw4MainContentSubHeading">
                                <p>Thanks for taking the Menstrual Pain Assessment test.</p>
                            </div>
                            <div className="bmiResultWrapper">
                                <div className="bmiResultHeading">
                                    <p>Based on your answers, your MPA is</p>
                                </div>
                                <div className="bmiResult">
                                    <div className="activityResultFloatResponse">
                                        <input
                                            type="text"
                                            id="bmiHeightInputId"
                                            value={getSleepData}
                                            maxLength={10}
                                        />
                                    </div>
                                    <div className="bmiResultStringResponse">
                                        <div style={{ marginTop: '10px', color: activity_selectedScaleItem }}>
                                            {activity_selectedScaleItem === '#7AB375' && 'No Pain'}
                                            {activity_selectedScaleItem === '#FCF803' && 'Mild'}
                                            {activity_selectedScaleItem === '#FC9403' && 'Moderate'}
                                            {activity_selectedScaleItem === '#D27060' && 'Severe'}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="mpaSliderContainer">
                                        <input
                                            type="range"
                                            id="slider"
                                            className="bmiSlider"
                                            name="slider"
                                            min="0"
                                            max="12"
                                            value={getSleepData}
                                        // onChange={ handleSliderChange }
                                        />
                                    </div>
                                </div>

                                <div className="bmiLogoBr">
                                    <img alt="" src={Assets.mwaLogo} />
                                </div>
                            </div>
                            <div className="bmiCategories">
                                <ul>
                                    {getSleepscaleData && getSleepscaleData?.map(item => (
                                        <li className={item.selected ? 'selected' : ''} key={item.id}>
                                            {item.description}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="kywNextLinkWrapper">
                                <Link className="backLink" to="" onClick={() => {
                                    navigate(-1);
                                }}>
                                    <img src={Assets.backArrow} alt="Back" />
                                    Back
                                </Link>
                                <button type="button" onClick={clickNext} id="kywNextButtonSmall"
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                >
                                    Next
                                    {isHovered ? <img alt="" src={Assets.arrowUpRight} /> : <img alt="" src={Assets.arrowUpRightBlack} />}
                                </button>
                            </div>
                            {/* </div> */}
                        </>
                    ) : (
                        <>
                            <form onSubmit={handleSubmit}>
                                {renderQuestions()}
                                {errors && (
                                    <div className="errors">
                                        {Object.entries(errors).map(([key, error]) => (
                                            <p key={key} style={{ color: 'red' }}>{error}</p>
                                        ))}
                                    </div>
                                )}
                                <div className="kywNextLinkWrapper">
                                    <Link className="backLink" to="">
                                        <img src={Assets.backArrow} alt="Back" />
                                        Back
                                    </Link>
                                    {currentStep < 2 && (
                                        <button
                                            type="button"
                                            onClick={nextStep}
                                            id="kywNextButtonSmall"
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}
                                        >
                                            Next
                                            <img alt="" src={Assets.arrowUpRight} />
                                        </button>
                                    )}
                                    {currentStep === 2 && (
                                        <button type="submit" id="kywNextButtonSmall"
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}
                                        //onClick={openLoginPopup}
                                        > Next
                                            {isHovered ? <img alt="" src={Assets.arrowUpRight} /> : <img alt="" src={Assets.arrowUpRightBlack} />}
                                        </button>
                                    )}
                                </div>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ContentFrameKyw5;