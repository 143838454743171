import { Assets } from "../Assets"
import { useState } from 'react';

import { Link } from "react-router-dom";

const UnderstandHowDirectPlans = () => {

    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className="understandHowDirectPlans">
            <div className="understandHowDirectPlansTextContent">
                <div className="understandHowDirectPlansInner">
                    <div className="uhdpHeading1">
                        Understand How
                    </div>
                    <div className="uhdpHeading2">
                        How are you doing on the <b>Menstrual and Wellness scale overall</b>
                    </div>
                    <div className="uhdpArticle">
                        <p>Wellness scale. Take your assessment today and know where you stand. Our LQ and MWQ frameworks are research-based, backed by science and Ayurveda to give you holistic inputs on your wellbeing. Get personalised recommendations based on your LQ and MWQ score. Take your wellness assessment now.</p>
                    </div>
                    <Link to="/selfprognosis/know-your-wellness">
                        <button id="takeAssessmentButton"
                            className={`${isHovered ? 'bookNowTeleconsButtonHovered' : ''}`}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            Take Assessment
                            {isHovered ? <img src={Assets.arrowUpRight} /> : <img src={Assets.arrowUpRightBlack} />}
                        </button>
                    </Link>
                </div>
                <div className="understandHowDirectPlansImage">
                    <img src={Assets.uhdpImage} alt="LQ & MWQ" />
                </div>
            </div>
        </div>
    )
}

export default UnderstandHowDirectPlans;