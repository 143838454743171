import { Assets } from '../Assets'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import PopupScheduleCall from '../ScheduleCallPopup/PopupScheduleCall'


const MainContentHomePage = () => {

    const navigate = useNavigate();

    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);

    const [showScheduleCallPopup, setShowScheduleCallPopup] = useState(false);

    const openScheduleCallPopup = () => {
        setShowScheduleCallPopup(true);
    };

    const closeScheduleCallPopup = () => {
        setShowScheduleCallPopup(false);
    };

    const pageBlog = () => {
        navigate('/selfprognosis/know-your-wellness/start-journey');
    }

    return (
        <div className="laiqaMainContentBg">
            <PopupScheduleCall show={showScheduleCallPopup} handleClose={closeScheduleCallPopup} openLoginOTPPopup={openScheduleCallPopup} />
            {/* <div className="laiqaMainContentWrapper">
                <div className="laiqaMainContentMidLayer">
                    <img src={ Assets.laiqaMainContentBgImg } id="landingPageBannerImg1" />
                    <img src={ Assets.laiqaMainContentBgImgResp } id="landingPageBannerImg2" />
                </div>
                <div className="laiqaMainContentButtons">
                    <button id="midLayerButton"
                        className={ `${isHovered ? 'bookNowTeleconsButtonHovered' : ''}` }
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        >
                        Let's Start
                        {isHovered ? <img src={ Assets.arrowUpRight } /> : <img src={ Assets.arrowUpRightBlack } /> }
                    </button>
                    <div className="scheduleCallMainContent">
                        <div className='scheduleCallButtonWrapper'>
                            <button id="scheduleCallButtonHomePage"
                                className={ `${isHovered2 ? 'bookNowTeleconsButtonHovered' : ''}` }
                                onMouseEnter={() => setIsHovered2(true)}
                                onMouseLeave={() => setIsHovered2(false)}
                                onClick={ openScheduleCallPopup }
                                >
                                {isHovered2 ? <img src={ Assets.phoneCallingWhite } /> : <img src={ Assets.phoneCalling } /> }
                            </button>
                        </div>
                        <div className="scheduleCallContent">
                            <p className="scheduleCallp1">Schedule Call</p>
                            <p className="scheduleCallp2">(+91) 987 654 3210</p>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='bannerdiv'>
                <img alt='Home Baneer' className='homebanneo' src={Assets.coming_soon_Banner} id="" />
                <div className='expldib'>
                    <button className='explBanner btn btn-primary'>
                        <Link style={{ color: '#fff', textDecoration: 'none' }} to={'/selfprognosis/know-your-wellness'}>Take the wellness Quiz</Link></button>
                    <button className='explBanner btn btn-primary'>
                        <Link target='_blank' style={{ color: '#fff', textDecoration: 'none' }} to={'https://subscribepage.io/laiqawellness'}>Join the Waitlist</Link></button>
                </div>
            </div>
        </div>
    );
}

export default MainContentHomePage;