import { Assets } from '../Assets';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useState } from 'react';
import { Link } from 'react-router-dom'

const SelectedPlansWEllnessPlansCheckoutLogin = () => {

    const [value, setValue] = useState()

    return (
        <div className="selectedPlanWrapper">
            <div className="plansAndLoginWrapper">

                <div className="selectedPlansPriceList">
                    <div className="checkoutPriceListWrapper">
                        <Link to="/wellness-plans/checkout">
                            <div className="checkoutSilverPlan">
                                <div className="checkoutPlanHeading" id="silverPlanHeading">
                                    <p className="planNameHeading">Silver Plan</p>
                                    <div className="wellnessSilverPlanHeadingPrice">
                                        <b><p>&#8377;3999</p></b>
                                        <p> /month</p>
                                    </div>
                                </div>
                                <div className="checkoutPlanListContent">
                                    <ul>
                                        <li>Monthly Lifestyle and Menstrual Assessment</li>
                                        <li>Weekly Diet & Fitness Plans</li>
                                        <li>Daily Lifestyle Tips</li>
                                        <li>Unlimited Chat Support</li>
                                        <li>Fortnightly Consultation with Ayurvedic Doctor / Dietician</li>
                                        <li>Weekly Expert Workshops</li>
                                        <li>Free Product Voucher Worth of ₹900</li>
                                        <li>User Progress Report at the end of plan</li>
                                    </ul>
                                </div>
                            </div>
                        </Link>

                        <Link to="/wellness-plans/checkout">
                            <div className="checkoutGoldPlan">
                                <div className="checkoutPlanHeading" id="goldPlanHeading">
                                    <p className="planNameHeading">Gold Plan</p>
                                    <div className="wellnessSilverPlanHeadingPrice">
                                        <b><p>&#8377;7499</p></b>
                                        <p> /month</p>
                                    </div>
                                </div>
                                <div className="checkoutPlanListContent">
                                    <ul>
                                        <li>Monthly Lifestyle and Menstrual Assessment</li>
                                        <li>Weekly Diet & Fitness Plans</li>
                                        <li>Daily Lifestyle Tips</li>
                                        <li>Unlimited Chat Support</li>
                                        <li>Fortnightly Consultation with Ayurvedic Doctor / Dietician</li>
                                        <li>Weekly Expert Workshops</li>
                                        <li>Free Product Voucher Worth of ₹1800</li>
                                        <li>User Progress Report at the end of plan</li>
                                    </ul>
                                </div>

                            </div>
                        </Link>

                        <Link to="/wellness-plans/checkout">
                            <div className="checkoutPlatinumPlan">
                                <div className="checkoutPlanHeading" id="platinumPlanHeading">
                                    <p className="planNameHeading">Platinum Plan</p>
                                    <div className="wellnessSilverPlanHeadingPrice">
                                        <b><p>&#8377;12499</p></b>
                                        <p> /month</p>
                                    </div>
                                </div>
                                <div className="checkoutPlanListContent">
                                    <ul>
                                        <li>Monthly Lifestyle and Menstrual Assessment</li>
                                        <li>Weekly Diet & Fitness Plans</li>
                                        <li>Daily Lifestyle Tips</li>
                                        <li>Unlimited Chat Support</li>
                                        <li>Fortnightly Consultation with Ayurvedic Doctor / Dietician</li>
                                        <li>Weekly Expert Workshops</li>
                                        <li>Free Product Voucher Worth of ₹3600</li>
                                        <li>User Progress Report at the end of plan</li>
                                    </ul>
                                </div>

                            </div>
                        </Link>

                    </div>
                </div>

                <div className="checkoutLogin">
                    <div className="checkoutLoginWrapper">
                        <div className="checkoutLoginHeading2">
                            Login
                        </div>
                        <div className="checkoutLoginContent">
                            <div className="checkoutLoginPhoneNumberHeading">
                                Enter your mobile number
                            </div>
                            <div className="checkoutLoginPhoneNumberHeading2">
                                You will be receiving an OTP
                            </div>
                            <div className="loginFormWrapper">
                                <form action="">
                                    <PhoneInput
                                        defaultCountry="IN"
                                        international="false"
                                        name="phoneNumberInput"
                                        type="tel"
                                        placeholder="Enter phone number"
                                        country="IN"
                                        countries={["IN"]}
                                        value={value}
                                        onChange={setValue} />
                                </form>
                                <Link id="couponSubmitLink" to="/wellness-plans/checkout/login/otp">
                                    <button id="couponSubmit">
                                        Request OTP
                                    </button>
                                </Link>
                            </div>
                            <div className="termsAndConditionsText">
                                By continuing, you agree to the <br /> <b>terms & conditions</b> and <b>privacy policy</b> of Laiqa
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="complianceContentCheckout">
                <div className="complianceContentWrapper">
                    <div className="complianceContentHeading">
                        <div className="complianceContentHeading1">
                            Safe and Secure
                        </div>
                        <div className="complianceContentHeading2">
                            We are compliant to <b>Industry standards.</b>
                        </div>
                    </div>
                    <div className="complianceContentImage">
                        <img src={Assets.complianceContentBanner} alt="Industry Standards" />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SelectedPlansWEllnessPlansCheckoutLogin;