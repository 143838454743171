import { Assets } from '../Assets';

import { useState } from 'react'
import { Link } from 'react-router-dom'


const MainContentHomePage = () => {

    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);

    return (
        <div className="laiqaMainContentBg">
            <div className="laiqaMainContentWrapper">
                <div className="laiqaMainContentMidLayer">
                    <img src={Assets.mainContentWorkshop} id="landingPageBannerImg1" />
                    <img src={Assets.laiqaMainCOntentBgImgResp} id="landingPageBannerImg2" />
                </div>
            </div>
            <div className="breadcrumbWorkshop">
                <div className="breadcrumbWrapper">
                    <div className="wsBreadcrumb">
                        <ul>
                            <li><Link to="/">Home </Link></li>
                            <li><Link to="/"> • Service • </Link></li>
                            <li id="selectedBreadCrumb"><Link to="/wellness-plans"> Pre-plan Workshop</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainContentHomePage;