import React, { useState, useEffect } from 'react';
import { Assets } from "../Assets"
import { Link, Navigate } from 'react-router-dom'
import { PostAPI, GetAPI } from '../utility/APiCall';
import CustomPaginationNew from './CustomPaginationNew';
import { useNavigate } from 'react-router-dom';
const BlogContentBlogPage = () => {

  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  //const { itemsPerPage, setItemsPerPage } = props;
  const [activePage, setActivePage] = useState(3)
  const [columnSorter, setColumnSorter] = useState(null)
  //const [itemsPerPage, setItemsPerPage] = useState('')
  const [users, setUsers] = useState([])
  const [originalUsersData, setOriginalUsersData] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [records, setRecords] = useState([])
  const [visible, setVisible] = useState({ form: false, alert: false });
  const [visibleview, setVisibleview] = useState(false);
  const [pagination, setPagination] = useState({
    activePage: 1,
    columnSorter: null,
    itemsPerPage: 10,
    totalListCount: users.length,
    search: '',
  });
  console.log('pagination', pagination);
  const [error, setError] = useState(false);
  const [flag, setFlag] = useState(false)


  const [resultCat, setresultCat] = useState([]);
  const [resultCatSub, setresultCatSub] = useState([]);

  // list of category
  const [categoryOptions, setCategoryOptions] = useState([]);

  const [debouncedSearch, setDebouncedSearch] = useState(pagination.search);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleCategorySelect = (category) => {
    console.log('category', category);
    setSelectedCategory(category);
    setPagination({ ...pagination, activePage: 1 });
    setFlag(!flag);
  };

  const table_list = (callback) => {
    let params = new URLSearchParams();
    pagination?.columnSorter &&
      pagination?.columnSorter.column &&
      params.append(
        'sort',
        `${pagination?.columnSorter.column}%${pagination?.columnSorter.state}`
      );
    pagination?.search && params.append('search', `${pagination?.search}`);
    selectedCategory && params.append('category', selectedCategory);
    const url = `/list_blog?page=${pagination?.activePage}&limit=${pagination?.itemsPerPage
      }&${params?.toString()}`;
    GetAPI(url, (res) => {
      if (res?.status == 1) {
        setUsers(res?.data);
        const originalData = res?.data;
        setOriginalUsersData(originalData);
        const totalListCount = res?.total;
        console.log('totalListCount', totalListCount);
        setPagination({
          ...pagination,
          totalListCount: res?.total,
        });
        // Call the callback function to indicate completion
        if (callback) {
          callback();
        }
      }
    });
  };

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      // Call table_list with a callback function
      table_list(() => {
        setIsFetching(false);
      });
    }
  }, [flag, pagination.activePage, pagination.columnSorter, pagination.itemsPerPage, pagination.search]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setDebouncedSearch(pagination.search);
    }, 230);
    return () => clearTimeout(debounceTimeout);
  }, [pagination.search]);

  //console.log('usersusersBlog', users);

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US'); // Change 'en-US' to your preferred locale
  }

  //pagination props
  const CustomPaginationprops = {
    pagination: pagination,
    setPagination: setPagination,
    onPageChange: (activePage) => setPagination({ ...pagination, activePage }),
    //onPageChange: (activePage) => setPagination({ ...pagination, activePage, activePageChanged: true }),
  };

  console.log('CustomPaginationprops -->', CustomPaginationprops);

  const get_category_list = () => {
    var url_cat_list = `/list_blog_modules?module=category`;
    GetAPI(url_cat_list, (res) => {
      const result = res?.data;
      setresultCat(result);
      console.log('result--->', result);
      // Assuming result is an array of objects with parent_id and parent_cat properties
      const uniqueParentIds = [...new Set(result.map(item => item.parent_id))];
      const mappedOptions = uniqueParentIds.map((parent_id) => {
        // Find the corresponding parent_cat based on parent_id
        const item = result.find((item) => item.parent_id === parent_id);
        // Modify as needed
        return {
          parent_id: item.parent_id,
          label: item.parent_cat, // Modify as needed
          value: item.parent_id,  // Modify as needed
        };
      });
      setCategoryOptions(mappedOptions);
      console.log('mappedOptions', mappedOptions);
    })
  }

  useEffect(() => {
    get_category_list();
  }, [])

  const ImageItem = ({ item }) => {
    const [isHovered, setHovered] = useState(false);
    const maxLength = 100; // Maximum length for truncated content

    // Function to truncate content if it exceeds maxLength
    const truncateContent = (content, maxLength) => {
      maxLength = maxLength || 100;
      //return content.length > maxLength ? content.substring(0, maxLength) + '...' : content;
      // Truncate content
      let truncatedContent = content.length > maxLength ? content.substring(0, maxLength) + '...' : content;
      // Remove empty <p> tags at the end
      //truncatedContent = truncatedContent.replace(/<\/p>$/, ''); 
      truncatedContent = truncatedContent.replace(/<\/?p><br><\/?p>|<\/?p><img[^>]*><\/?p>|<\/?p><span><img[^>]*><\/?p>|<\/?p><span style="color: rgb\(0, 0, 0\);"><img[^>]*><\/?p>/g, '');
      // Return the modified content
      return truncatedContent;
    };

    function removeEmptyTags(html) {
      // Remove <p><br></p> pattern
      //html = html.replace(/<\/?p><br><\/?p>|<\/?p><img[^>]*><\/?p>/g, '');
      html = html.replace(/<\/?p><br><\/?p>|<\/?p><img[^>]*><\/?p>|<\/?p><span><img[^>]*><\/?p>|<\/?p><span style="color: rgb\(0, 0, 0\);"><img[^>]*><\/?p>/g, '');
      return html;
    }

    const navigateToDetails = (id) => {
      // window.location.href = `/blog/details`;
      navigate(`/blog/details`);
      sessionStorage.setItem('blog_id', id);
    };



    return (
      <>
        <li onClick={() => navigateToDetails(item.id)} style={{ cursor: "pointer" }}>
          {item.thumbnail ? (
            <img alt={item.title} src={item.thumbnail} />
          ) : (
            <img alt="Default" src={Assets.vectorDefault} />
          )}
          <div className="blogContentItems2">
            <p className="blogContentItemsDate">{formatDate(item.created_at)}</p>
            <p className="blogContentItemsHeading2">{item.title}</p>
            <p
              className="blogContentItemsContent2"
              dangerouslySetInnerHTML={{
                __html: truncateContent(item.blog_content),
              }}
            ></p>
            {/* <Link
          to={`/details/${item.id}`}
        > */}
            <button
              id="blogContentReadMoreButton"
              onClick={() => navigateToDetails(item.id)}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              Read More
              <img
                alt=""
                src={isHovered ? Assets.arrowHoverSrc : Assets.arrowSrc}
                style={{ border: "none", margin: "0" }}
              />
            </button>
            {/* </Link> */}
          </div>
        </li>
      </>
    );
  }
  const RecentItem = ({ blog }) => {
    const navigateToDetails = (id) => {
      // window.location.href = `/blog/details`;
      navigate(`/blog/details`);
      sessionStorage.setItem('blog_id', id);
    };
    return (
      <li className='grid-list' onClick={() => navigateToDetails(blog.id)} style={{ cursor: "pointer" }}>
        {blog.thumbnail ? (
          <img className='img25per' src={blog.thumbnail} alt="Blogs" />
        ) : (
          <img alt="Default" src={Assets.vectorDefault} />
        )}
        <div className="recentPostListItemContent grid75per">
          <p className="blogContentItemsHeading2" style={{ padding: "5px 0 0 10px", margin: "0" }}>{blog.title}</p>
          <p className="blogContentItemsDate" style={{ margin: "10px 10px" }}>{blog.created_at}</p>
        </div>
      </li>
    );
  }
  return (
    <div className="blogContentBlogPage">
      <div className="blogContentBlogPageInner">
        <div className="blogContentBlogPageListItems">
          <ul className="blogContentItems2">
            {users.map(item => (
              <ImageItem key={item.id} item={item} />
            ))}
          </ul>
          <p>{pagination?.totalListCount && (
            <CustomPaginationNew
              CustomPaginationprops={CustomPaginationprops}
            />
          )}</p>
        </div>
        <div className="blogContentBlogPageOtherItems">
          <div className="blogContentOtherPageItemsWrapper selectableListItem">
            <h2>Search</h2>
            <form action="">
              <input type="text"
                autocomplete="off"
                onChange={(e) =>
                  setPagination({ ...pagination, search: e.target.value })
                }
                placeholder="Search" />
              <Link id="applyCouponLink">Search</Link>
            </form>
          </div>

          <div className="blogContentOtherPageItemsWrapper selectableListItem">
            <h2>Categories</h2>
            <ul>
              {categoryOptions?.map(item => (
                <li onClick={() => handleCategorySelect(item.label)} key={item.id}>{item.label}</li>
              ))}
            </ul>
          </div>
          <div className="blogContentOtherPageItemsWrapper">
            <h2>Recent Posts</h2>
            <ul className="recentPostListItems">
              {users?.slice(0, 3)?.map(blog => (
                <RecentItem key={blog.id} blog={blog} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogContentBlogPage;