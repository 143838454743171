import React,{useState, useEffect} from "react";
import { Assets } from "./Assets";
import { PostAPI } from "./utility/APiCall";
import NavbarKyw from "./Home/NavbarHomePage"
import MainContentKyw from "./Kyw1/MainContentKyw1";
import ContentFrameKyw3 from "./Kyw3/ContentFrameKyw3";
import FooterKyw from "./Kyw1/FooterKyw";
// import NavMenu from "./navbar/NavMenu";
import { useNavigate } from "react-router-dom";
import { GetAPI } from "./utility/APiCall";
import NavbarHomePage from "./Home/NavbarHomePage";
// import useCookie from "../cookies/Cookie";
// import Cookies from 'js-cookie';
import useCookie from "./cookies/Cookie";
import Cookies from 'js-cookie';
//const KnowYourWellness3 = () => {
function KnowYourWellness3(props) {
    const {getUID, setGetUID, userName, setUserName, userId, setUserId} = props;
    useEffect(() => {
        // const getUID = sessionStorage.getItem('getUID');
         const getUID = Cookies.get('getUID');
         console.log('getUID:', getUID);
       }, []);
   // const {scrollToTop} = props;
    const navigate = useNavigate();

    const getCatId = sessionStorage.getItem('getBMIID');
    
    console.log('getCatId', getCatId);

    const [category, setCategory] = useState({
        category_id:getCatId,
    });

    const [sliderValue, setSliderValue] = useState(0);
    const [inputValue, setInputValue] = useState(0);
    const [sliderValue2, setSliderValue2] = useState(0);
    const [inputValue2, setInputValue2] = useState(0);
    const [height, setHeight] = useState(0);

    console.log('height', inputValue);
    console.log('weight', inputValue2);

    const handleSliderChange = (event) => {
        const newValue = parseInt(event.target.value, 10);
        if (!isNaN(newValue)) {
            setSliderValue(newValue);
            setHeight(newValue);
            //setInputValue(newValue);
            setInputValue(convertCmToFeet(newValue));
        }
    };

    const handleSliderChange2 = (event) => {
        const newValue = parseInt(event.target.value, 10);
        if (!isNaN(newValue)) {
            setSliderValue2(newValue);
            setInputValue2(newValue);
        }
    };

    const handleInputChange = (event) => {
        const newValue = parseInt(event.target.value, 10);
        if (!isNaN(newValue)) {
            setSliderValue(newValue);
            //setInputValue(newValue);
            setInputValue(convertCmToFeet(newValue));
        } else {
            setSliderValue(0);
            setInputValue('');
        }
    };

    const handleInputChange2 = (event) => {
        const newValue = parseInt(event.target.value, 10);
        if (!isNaN(newValue)) {
            setSliderValue2(newValue);
            setInputValue2(newValue);
        } else {
            setSliderValue2(0);
            setInputValue2('');
        }
    };

    sessionStorage.setItem('qesbmi1', inputValue);
    sessionStorage.setItem('qesbmi2',inputValue2);

    // const convertCmToFeet = (cm) => {
    //     const feet = cm * 0.0328084;
    //     return feet.toFixed(2); // Round to 2 decimal places
    //   };

    //   const convertCmToFeet = (cm) => {
    //     const inches = cm * 0.393701; // Convert centimeters to inches
    //     const feet = Math.floor(inches / 12); // Get the number of whole feet
    //     const remainingInches = inches % 12; // Get the remaining inches
    //     return `${feet} ${remainingInches.toFixed(2)}`; // Return the result
    // };

    const convertCmToFeet = (cm) => {
        const inches = cm * 0.393701; // Convert centimeters to inches
        const feet = Math.floor(inches / 12); // Get the number of whole feet
        return feet; // Return the number of feet
    };

    const getBMIList = (e) => {
         const categoryData = new FormData();
         categoryData.append('category_id', getCatId);
 
        // navigate('/self-prognosis/know-your-wellness/bmi-result');
 
         PostAPI({ url: '/get_onboarding_questionnaires', data: categoryData }, (res) => {
            // alert('check')
             console.log('Questions', res?.data);
         });
     }

     const bmiHeightWeight  = [
        parseInt(sessionStorage.getItem('qesbmi1'), 10) || 0,
        parseInt(sessionStorage.getItem('qesbmi2'), 10) || 0,
    ];

    console.log('bmiHeightWeight', bmiHeightWeight);

    const qesbmi1 =  sessionStorage.getItem('qesbmi1');
    const qesbmi2 =  sessionStorage.getItem('qesbmi2');

    useEffect(()=>{
        getBMIList();
       // console.log('bmiHeightWeight',bmiHeightWeight);
    },[])

    useEffect(() => {
        //const getUID = sessionStorage.getItem('getUID');
       // console.log('getUID:', getUID);
      }, []);

    const [data, setData] = useState({
        user_id: '',
        category_id: getCatId,
        answer:[],
        journey_id: '0',
        plan_data_id: '0',
        month: '0',
      });

      const heightAnswer = {
        question_id: "57",
        answer: qesbmi1,
      };
      
    const weighteAnswer = {
        question_id: "58",
        answer: qesbmi2,
    };

    const answer = [heightAnswer, weighteAnswer];

    ///get_user_plan_details/7
    const [getPlan, setGetPlan] = useState();
    const [journeyId, setJourneyId] =useState();
    const [planDataId, setplanDataId] =useState();
    const [month, setMonth] = useState();
    console.log('getPlangetPlan', getPlan);
    const [journey_id_already, setJourneyIdAlready ] = useState();
    console.log('journey_id_alreadyParent',journey_id_already);

    const getPlandetails = () => {
        //const getUID = sessionStorage.getItem('getUID')
        const getUID = Cookies.get('getUID');
        const userId = getUID;
        const url = `/get_user_plan_details/${userId}`;

        GetAPI(url, (res)=>{
                    const plan_data_id = res?.data?.plan_data_id;
                    if (plan_data_id == null || plan_data_id == 0){
                    setplanDataId(0);
                    } else {
                    setplanDataId(plan_data_id);
                    }

                    const pre_journey_id = res?.data?.pre_journey_id;
                    setJourneyIdAlready(pre_journey_id);
                    if (pre_journey_id == null || pre_journey_id == 0){
                    setJourneyId(0);
                    } else {
                    setJourneyId(pre_journey_id);
                    }

                    const current_month = res?.data?.plan_data_id;
                    if (current_month == null || current_month == 0){
                    setMonth(0);
                    } else {
                    setMonth(current_month);
                    }
                const result = res?.data;
                setGetPlan(result);

        });

    }

    useEffect(()=>{
        getPlandetails();
    }, [])

    const [getSleepData, setSleepData] = useState();
    const [message, setMessage ] = useState()
    const [getSleepscaleData, setSleepscaleData] = useState([]);
    

    const handleFormSubmit = () =>{
        const getCatId = sessionStorage.getItem('getBMIID');
       // const getUID = sessionStorage.getItem('getUID')
        const getUID = Cookies.get('getUID');
         const userId = getUID;
        const questionData = new FormData();
        questionData.append('user_id', userId);
        questionData.append('category_id', getCatId);
        questionData.append('answer', JSON.stringify(answer)); // Convert to JSON string
        questionData.append('journey_id', journeyId);
        questionData.append('plan_data_id', planDataId);
        questionData.append('month', month);
        
        console.log('FormData content:', questionData);
    
        PostAPI({ url: '/add_onboarding_questionnaires_data', data: questionData }, (res) => {
        console.log('Questions 57', res?.data);
        if(res?.status == 1 ){

          const bmi_scale = res?.scale;
          const bmi_score = res?.score;
          setSleepData(bmi_score);
          setSleepscaleData(bmi_scale);
          console.log('scale', bmi_scale);
          const journey_id = res?.journey_id;
          
          sessionStorage.setItem('journey_id', journey_id);
          sessionStorage.setItem('bmi_score', bmi_score);
          sessionStorage.setItem('bmi_scale', JSON.stringify(bmi_scale)); 
          console.log('journey_id:', journey_id);
        }

        }).catch((error) => {
        console.error('API Error:', error);
        });
    }

    useEffect(() => {
        // Your effect code here
    }, [userName, userId]);
    

    return(
        <div>
            <div className="kywBgImages">
                <div className="kywBgImagesInner">
                    <img alt="" id="kywBgLeft" src={ Assets.kywBg1 } />
                    <img alt="" id="kywBgRight" src={ Assets.kywBg2 } />
                </div>
            </div>
            {/* <NavbarKyw scrollToTop={scrollToTop} /> */}
            <NavbarHomePage getUID={getUID} setGetUID={setGetUID} userName={userName} setUserName={setUserName} userId={userId} setUserId={setUserId} />
            <MainContentKyw />
            <ContentFrameKyw3 
            sliderValue={sliderValue} 
            setSliderValue={setSliderValue} 
            inputValue={inputValue}
            setInputValue={setInputValue}
            sliderValue2={sliderValue2} 
            setSliderValue2={setSliderValue2} 
            inputValue2={inputValue2}
            setInputValue2={setInputValue2}
            handleSliderChange={handleSliderChange}
            handleSliderChange2={handleSliderChange2}
            handleInputChange={handleInputChange}
            handleInputChange2={handleInputChange2}
            handleFormSubmit={handleFormSubmit} 
            category={category} 
            setCategory={setCategory}
            height={height} 
            setHeight={setHeight}
            getSleepData={getSleepData} 
            setSleepData={setSleepData}
            getSleepscaleData={getSleepscaleData} 
            setSleepscaleData={setSleepscaleData}
            journey_id_already={journey_id_already} 
            setJourneyIdAlready={setJourneyIdAlready}
             />
            <FooterKyw />
        </div>
    )
}

export default KnowYourWellness3;