import React from 'react';
//import { AppContextProvider as OriginalAppContextProvider } from './path/to/AppContextProvider'; // Adjust the import as necessary
import { AppContextProvider as OriginalAppContextProvider } from './AppContextProvider';
import useCookie from '../cookies/Cookie';
import Cookies from 'js-cookie';
import { PostAPI } from '../utility/APiCall';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
const AppContextProviderWithRouter = ({ children }) => {

  const navigate = useNavigate();

  const handleLogOut = () => {
    const getUID = Cookies.get('getUID');
    const url = '/web/user_web_logout';
    const formData = new FormData();
    formData.append('user_id', getUID);
    const params = { url, data: formData };
    PostAPI(params, (res) => {
      if (res.status === 1) {
        toast.success(res?.message);
        sessionStorage.clear();
        Cookies.remove('user_name');
        Cookies.remove('getUID');
        Cookies.remove('new_user');
        setTimeout(() => {
          navigate('/');
          window.location.reload();
        }, 2000);
      } else {
        toast.error(res?.message);
      }
    });
  };

  return (
    <OriginalAppContextProvider handleLogOut={handleLogOut}>
      {children}
    </OriginalAppContextProvider>
  );
};

export default AppContextProviderWithRouter;