import React, { useEffect, useState, useRef } from "react";
import { Assets } from "../Assets"
import { Link } from 'react-router-dom';
import PopupLogin from '../LoginPopup/PopupLogin'
import PopupLoginOTP from '../LoginPopup/PopupLoginOTP'
import PopupSignup from "../SignupPopup/PopupSignup";
import SuccessPopup from "../SignupPopup/SuccessPopup";
import { PostAPI } from "../utility/APiCall";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import useCookie from "../cookies/Cookie";
import Cookies from 'js-cookie';
import { useName } from "../Context/AppContextProvider";
import { useData } from "../Context/AppContextProvider";

const NavbarHomePage = () => {

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showSignUpPopup, setShowSignUpPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showLoginOTPPopup, setShowOTPPopup] = useState(false);
  const [isOTPReceived, setIsOTPReceived] = useState(false);
  const [isOTPEntered, setIsOTPEntered] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
    setShowSuccessPopup(false);
  };

  const openLoginPopup = () => {
    setShowLoginPopup(true);
    closeLoginOTPPopup();
    setShowSignUpPopup(false);
  };

  const openLoginOTPCheckPopup = () => {
    setShowLoginPopup(true);
  };

  const openSuccessPopup = () => {

  }

  const closeSuccessPopup = () => {
    setShowSuccessPopup(false);
  }

  const closeLoginPopup = () => {
    setShowLoginPopup(false);
  };

  const openSignUpPopup = () => {
    setShowSignUpPopup(true);
    setShowLoginPopup(false);
  };

  const closeSignUpPopup = () => {
    setShowSignUpPopup(false);
    setShowSignUpPopup(true);
  };

  const openLoginOTPPopup = () => {
    setShowOTPPopup(true);
    closeLoginPopup();
  };

  const closeLoginOTPPopup = () => {
    setShowOTPPopup(false);
  };


  const [isMobileConfirmed, setIsMobileConfirmed] = useState(false);
  const [data, setData] = useState({
    phone: '',
  });

  const [getUID, setGetUID] = useState();
  const { userName, userId, setUserName, setUserId } = useName();
  const { handleLogOut } = useData();
  // sessionStorage.setItem('getUID',getUID);
  // const [userName, setUserName] = useCookie('user_name', '');
  // const [userId, setUserId] = useCookie('getUID', '');
  const [NewuserId, setNewUserId] = useCookie('new_user', '');
  const [userOTP, setUserOTP] = useCookie('user_otp', '');

  console.log('NewuserId', NewuserId);

  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [isFormVisible, setFormVisible] = useState(false);
  const [isOtpComplete, setIsOtpComplete] = useState(false);
  const otpInputs = Array.from({ length: 6 }, (_, index) => index);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const inputRefs = otpInputs.map(() => useRef(null));

  useEffect(() => {
    if (isFormVisible) {
      inputRefs[0].current.focus();
    }
  }, [isFormVisible]);

  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value !== '' && index < otpInputs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
    const isComplete = newOtp.every((char) => char !== '');
    setIsOtpComplete(isComplete);
  };

  const handleBackspace = (index) => {
    if (otp[index] === '') {
      if (index > 0) {
        inputRefs[index - 1].current.focus();
      }
    }
    const newOtp = [...otp];
    newOtp[index] = '';
    setOtp(newOtp);

    const isComplete = newOtp.every((char) => char !== '');
    setIsOtpComplete(isComplete);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    const phone = data?.phone;

    // Regular expression to match exactly 10 digits
    const phoneRegex = /^\d{10}$/;

    // Check if the phone number is valid
    if (!phoneRegex.test(phone)) {
      toast.error('Please enter a valid 10-digit mobile number.');
      return; // Prevent the form submission if validation fails
    }


    if (form.checkValidity()) {
      if (!isMobileConfirmed) {
        // If mobile is not confirmed yet, call "confirm_mobile" API
        const confirmMobileData = new FormData();
        //confirmMobileData.append('user_id', data?.user_id);
        confirmMobileData.append('phone', data?.phone);
        console.log('confirmMobileData:', confirmMobileData);
        sessionStorage.setItem('phone', data?.phone);
        PostAPI({ url: '/web/trigger_sign_up_otp', data: confirmMobileData }, (confirmMobileResponse) => {
          //console.log('confirmMobileResponse:', confirmMobileResponse);
          //toast.success(confirmMobileResponse.message);
          //alert('success');
          if (confirmMobileResponse.status == 1) {
            openLoginOTPPopup(true);
            const getuser_id = confirmMobileResponse?.data?.user_id;
            const new_user = confirmMobileResponse?.data?.new_user;
            const user_otp = confirmMobileResponse?.data?.otp;
            sessionStorage.setItem('getUID', getuser_id);
            setUserId(getuser_id);
            setGetUID(getuser_id);
            setNewUserId(new_user);
            setUserOTP(user_otp);
            // Set isOTPReceived to true when OTP is sent
            setIsOTPReceived(true);
            toast.success('OTP sent to your mobile number');
          } else {
            // Handle failure
            if (confirmMobileResponse.status == 0) {
              toast.error(confirmMobileResponse.message);
              alert(confirmMobileResponse.message);
              //toast.success('User Already Logged In. Please Log Out First');
              console.log('failure:', confirmMobileResponse.message);
            }

            // alert('');
            // console.log('failure:', confirmMobileResponse.message);
          }
        });
      }
    }
    //resetForm();
  };
  useEffect(() => {
    // const getUID = sessionStorage.getItem('getUID');
    // console.log('getUID:', getUID);
  }, []);

  const handleFormSubmitotp = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      // If mobile is confirmed, call "verify_confirm_mobile" API

      const verifyData = new FormData();
      verifyData.append('user_id', getUID);
      verifyData.append('phone', data?.phone);
      verifyData.append('otp', otp.join(''));

      PostAPI({ url: '/web/verify_web_login', data: verifyData }, (verifyResponse) => {
        console.log('verifyResponse:', verifyResponse);
        if (verifyResponse.status === 1) {
          if (NewuserId == 0) {
            toast.success(verifyResponse.message);
            const user_name = verifyResponse?.data?.user_name;
            const user_id = verifyResponse?.data?.id;
            sessionStorage.setItem('user_name', user_name);
            sessionStorage.setItem('getUID', user_id);
            setUserName(user_name);
            setUserId(user_id);
            toast.success(verifyResponse?.message);
            closeLoginOTPPopup();
            // user_otp
            //Cookies.remove('user_otp');
          } else if (NewuserId == 1) {
            // openLoginOTPPopup(false);
            setShowSignUpPopup(true);
            closeLoginOTPPopup();
            //Cookies.remove('user_otp'); 
            // user_otp
          } else {
            alert('check backend');
          }
        } else {
          toast.error(verifyResponse?.message);
        }
      });

      setIsOTPEntered(true);  // Set isOTPEntered to true when OTP is entered
      // setIsOTPEntered(false); // Reset isOTPEntered to false after OTP verification
    }
    //resetForm();
    // else if(){
    //   setShowSignUpPopup(true);
    // } 

  };
  const phone = sessionStorage.getItem('phone');
  const [dataSignup, setDtatSignUp] = useState({
    user_name: '',
    user_phone: phone,
    user_email: '',
    user_dob: '',
  });

  const handleFormSubmitSignup = async (event) => {
    event.preventDefault();
    const form = event?.currentTarget;
    if (form.checkValidity()) {
      //If mobile is confirmed, call "verify_confirm_mobile" API

      const verifyData = new FormData();
      //verifyData.append('user_id', getUID);
      //const phone = sessionStorage.getItem('phone');
      verifyData.append('user_id', getUID);
      verifyData.append('user_name', dataSignup?.user_name);
      //verifyData.append('user_phone', phone);
      verifyData.append('user_phone', data?.phone);
      verifyData.append('user_email', dataSignup?.user_email);
      verifyData.append('user_dob', dataSignup?.user_dob);
      PostAPI({ url: '/web/new_user_sign_up', data: verifyData }, (verifyResponse) => {
        //console.log('signupverifyResponse:', verifyResponse);
        if (verifyResponse.status == 1) {
          toast.success(verifyResponse?.message);
          setShowSignUpPopup(false);
          setShowSuccessPopup(true);
          setUserName(dataSignup?.user_name);
        } else {
          toast.error(verifyResponse?.message);
        }
      });
    }
  };


  // function handleLogOut() {
  // //  alert('check')
  //     const getUID = Cookies.get('getUID');
  //     const url = '/web/user_web_logout';
  //     const formData = new FormData();
  //     formData.append('user_id', getUID);
  //     //formData.append('accessToken', values?.accessToken);
  //     const params = { url, data: formData };
  //     //sessionStorage.clear();
  //     PostAPI(params, (res) => {
  //     // Assuming 'token' is the authentication token received from the server
  //       if (res.status == 1) {
  //         toast.success(res?.message);
  //         //sessionStorage.setItem('sessionToken', getToken);
  //         sessionStorage.clear();
  //         // Clear the cookies
  //         Cookies.remove('user_name');
  //         Cookies.remove('getUID');
  //         Cookies.remove('new_user'); 
  //         setTimeout(() => {
  //           navigate('/');
  //           // Reload the page
  //           window.location.reload();
  //         }, 2000);
  //       } else {
  //         toast.error(res?.message);
  //       }
  //     });
  //     //sessionStorage.setItem("sessionToken", getToken);
  // }
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  window.onscroll = () => { scrollFunction() };

  function scrollFunction() {
    const navbar = document.getElementById("laiqaNavbar");
    const logo = document.getElementById("id_laiqaLogo");

    if (!navbar || !logo) {
      console.error("Elements with IDs 'laiqaNavbar' or 'id_laiqaLogo' not found");
      return;
    }

    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      navbar.style.padding = "0 0";
      navbar.style.borderRadius = "0 0 10px 10px";
      navbar.style.backgroundColor = "#ffffff";
      logo.style.transform = "scale(0.7)";
      logo.style.transition = "transform 0.2s";
    } else {
      navbar.style.padding = "36px 0";
      navbar.style.borderRadius = "0 0 40px 40px";
      navbar.style.backgroundColor = "#ffffffcc";
      logo.style.transform = "scale(1)";
      logo.style.transition = "transform 0.2s";
    }
  }

  useEffect(() => {
    // Your effect code here
  }, [userName, userId]);

  return (
    <div className="NavbarHomePageContainer">
      <SuccessPopup
        show={showSuccessPopup} handleClose={closeSuccessPopup} openSuccessPopup={openSuccessPopup}
      />
      <PopupSignup
        show={showSignUpPopup} handleClose={closeSignUpPopup} openLoginPopup={openLoginPopup}
        handleFormSubmitSignup={handleFormSubmitSignup}
        dataSignup={dataSignup}
        setDtatSignUp={setDtatSignUp}
      />

      {showLoginPopup && (
        <PopupLogin
          handleFormSubmit={handleFormSubmit}
          getUID={getUID}
          setGetUID={setGetUID}
          // manuallySetOTP={manuallySetOTP}
          data={data}
          setData={setData}
          isMobileConfirmed={isMobileConfirmed}
          setIsMobileConfirmed={setIsMobileConfirmed}
          show={showLoginPopup} handleClose={closeLoginPopup}
          openLoginOTPPopup={openLoginOTPPopup}
          openSignUpPopup={openSignUpPopup}
          openLoginOTPCheckPopup={openLoginOTPCheckPopup}
        />
      )}
      {showLoginOTPPopup && (
        <PopupLoginOTP
          handleFormSubmitotp={handleFormSubmitotp}
          getUID={getUID}
          setGetUID={setGetUID}
          data={data}
          setData={setData}
          isFormVisible={isFormVisible}
          setFormVisible={setFormVisible}
          otp={otp}
          setOtp={setOtp}
          isOtpComplete={isOtpComplete}
          setIsOtpComplete={setIsOtpComplete}
          inputRefs={inputRefs}
          otpInputs={otpInputs}
          handleBackspace={handleBackspace}
          handleChange={handleChange}
          show={showLoginOTPPopup}
          handleClose={closeLoginOTPPopup}
        />
      )}
      <nav id="laiqaNavbar">
        <div className="laiqaNavbarLogo">
          <Link onClick={scrollToTop} to="/">
            <img id="id_laiqaLogo" src={Assets.laiqaLogo} alt="My Laiqa" />
          </Link>
        </div>
        <div className="laiqaNavbarLinks">
          <Link onClick={scrollToTop} to="/wellness-plans">Wellness Plans</Link>
          <Link onClick={scrollToTop} to="/workshops">Workshops</Link>
          <Link onClick={scrollToTop} to="/store">Store</Link>
          <Link onClick={scrollToTop} to="/blog">Blog</Link>
          {/* <a href="/blog">Blog</a> */}
          <div className="dropdown">
            <p className="dropbtn">Additional Services<img src={Assets.arrowRight} /></p>
            <div className="dropdown-content">
              <Link onClick={scrollToTop} to="/teleconsultation">Teleconsultation
                {/* <img src={ listPointerPink } alt="" /> */}
              </Link>
              <Link onClick={scrollToTop} to="/selfprognosis">Self Prognosis
                {/* <img src={ listPointerPink } alt="" /> */}
              </Link>
              <Link to="/">Ask Laiqa</Link>
            </div>
          </div>
        </div>
        <div className="laiqaNavbarShoppingCardButton">
          <img src={Assets.shoppingCartLogo} alt="My Cart" />
        </div>
        <div className="laiqaNavbarButton">
          {userId && userName ? (
            <>
              {/* <div className="" style={{ marginLeft: '10px' }}>
                Hi, {userName}! <span style={{ color: '#d27060', cursor: 'pointer' }} onClick={handleLogOut}>Logout</span>
                </div> */}
              <div className="dropdown" style={{ marginLeft: '10px' }}>
                <span className="dropdown-toggle">Hi, {userName}!</span>
                <div className="dropdown-content">
                  <a><span style={{ color: '#d27060', cursor: 'pointer' }} onClick={handleLogOut}>Logout</span></a>
                </div>
              </div>
            </>
          ) : (
            <div className="laiqaNavbarButton">
              <button id="navbarLoginButton" onClick={openLoginPopup}>
                Login
              </button>
            </div>
          )}
        </div>
        <Link to="/corporate/">
          <div className="laiqaNavbarButton">
            <button id="navbarCorporatesButton">Corporates</button>
          </div>
        </Link>
        <button className="navbar-toggle" onClick={handleToggle}>
          ☰
        </button>
      </nav>
      <div className={`navbar-links ${isOpen ? 'open' : ''}`}>
        <div className="menuHeader">
          <div >
            <img id="id_laiqaLogoMenu" src={Assets.laiqaLogoPink} alt="My Laiqa" />
          </div>
          <button className="close-btn" onClick={handleClose}>
            &times;
          </button>
        </div>
        <Link to="/wellness-plans">Wellness Plans</Link>
        <Link to="/workshops">Workshops</Link>
        <Link to="https://www.mylaiqa.com">Store</Link>
        <Link to="/blog">Blog</Link>
        <Link to="/teleconsultation">Teleconsultation</Link>
        <Link to="/selfprognosis">Self Prognosis</Link>
      </div>
    </div>
  );
}

export default NavbarHomePage;