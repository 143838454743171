import { Assets } from "../Assets"

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const ContentFrameKyw14 = (props) => {

    const navigate = useNavigate();

    const { getStress, setGetStress, getStress2, setGetStress2 } = props;

    const [isHovered, setIsHovered] = useState(false);
    const [sliderValue, setSliderValue] = useState(7);
    const [inputValue, setInputValue] = useState(7);

    const [selectedOption, setSelectedOption] = useState(null);
    const [activeButton, setActiveButton] = useState('clickNext');

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        // Change the active button based on the selected option
        if (option === 'Working') {
            setActiveButton('clickNext');
        } else if (option === 'Non-working') {
            setActiveButton('clickNext2');
        }
    };

    const handleSliderChange = (event) => {
        const newValue = parseInt(event.target.value, 10);
        setSliderValue(newValue);
        setInputValue(newValue);
    };

    const clickNext = () => {
        console.log('getBMIIdgetStress', getStress);
        navigate('/selfprognosis/know-your-wellness/perceived-stress');
    }

    const clickNext2 = () => {
        console.log('getBMIIdgetStress2', getStress2);
        navigate('/selfprognosis/know-your-wellness/occupational-stress');
    }

    return (
        <div className="contentFrameKyw">
            <div className="contentFrameKywInner">
                <div className="kyw1BgImage position-relative">
                    <div className='absoluteStage'>
                        <p className="mb-0" style={{ marginBottom: '0px' }}>Step 4</p>
                        <p>Stress</p>
                    </div>
                    <img src={Assets.kyw14Bg} alt="Body Mass Index" />
                    <div className='absolute'>
                        <p>Before we start with your stress evaluation, help us with the below required detail.</p>
                    </div>
                </div>
                <div className="vertProgressLine"></div>
                <div className="vertProgressLine2"></div>
                <div className="vertProgressLine3"></div>
                <div className="vertProgressLine4"></div>
                {/* <div className="vertProgressLine5"></div>
                <div className="vertProgressLine6"></div> */}
                <div className="progressCirclesContainer">
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg1" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg2" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg3" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> 4 </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg4" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleOneThird" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 5 </div>
                        </div>
                    </div>
                    {/* <svg id="progressCircleSvg5" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg> */}
                    <div className="progressCirclesOuter1">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 6 </div>
                        </div>
                    </div>
                    {/* <svg id="progressCircleSvg6" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleOneSixth" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg> */}
                    <div className="progressCirclesOuter7">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 7 </div>
                        </div>
                    </div>
                </div>
                <div className="kyw5MainContent">
                    <div className="kyw1MainContentHeading">
                        <h1>Choose one below</h1>
                    </div>
                    <div className="stressWorkOptions" style={{ marginBottom: '20px' }}>
                        <div className={selectedOption === 'Working' ? 'selectedStressWorkOption stressWorkOptionWrapper' : 'stressWorkOptionWrapper'} onClick={() => handleOptionClick('Working')}>
                            <div className="bmiResultHeading">
                                <p>Working</p>
                            </div>
                            <div className="bmiLogoBr">
                                <img alt="" src={Assets.occupationalStressLogo} />
                            </div>
                        </div>
                        <div className={selectedOption === 'Non-working' ? 'selectedStressWorkOption stressWorkOptionWrapper' : 'stressWorkOptionWrapper'} onClick={() => handleOptionClick('Non-working')}>
                            <div className="bmiResultHeading">
                                <p>Non-working</p>
                            </div>
                            <div className="bmiLogoBr">
                                <img alt="" src={Assets.perceivedStressLogo} />
                            </div>
                        </div>
                    </div>
                    <div className="kywNextLinkWrapper">
                        <Link className="backLink" to="" onClick={() => {
                            navigate(-1);
                        }}>
                            <img src={Assets.backArrow} alt="Back" />
                            Back
                        </Link>
                        {/* <Link to="/self-prognosis/know-your-wellness/perceived-stress"> */}
                        {selectedOption === 'Working' && (
                            <button
                                type="button"
                                onClick={clickNext}
                                id="kywNextButtonSmall"
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                Next
                                {isHovered ? <img src={Assets.arrowUpRight} /> : <img src={Assets.arrowUpRightBlack} />}
                            </button>
                        )}

                        {selectedOption === 'Non-working' && (
                            <button
                                type="button"
                                onClick={clickNext2}
                                id="kywNextButtonSmall"
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                Next
                                {isHovered ? <img src={Assets.arrowUpRight} /> : <img src={Assets.arrowUpRightBlack} />}
                            </button>
                        )}
                        {/* </Link> */}
                    </div>

                </div>
            </div>

        </div>
    )
}

export default ContentFrameKyw14;