import { Assets } from '../Assets';

import { Link } from 'react-router-dom';

const MainContentKyw = () => {

    return (
        <div className="laiqaMainContentBg">
            <div className="laiqaMainContentWrapper">
                <div className="laiqaMainContentMidLayer">
                    <img alt='' src={Assets.kywBg} id="wellnessPlansBanner1" />
                    {/* <img src={ laiqaMainContentMidLayerResp } id="wellnessPlansBanner2" /> */}
                    <div className="laiqaMainContentHeading">
                        <h1>Know Your Wellness</h1>
                    </div>
                </div>
            </div>
            <div className="breadcrumbCheckout">
                <div className="breadcrumbWrapper">
                    <div className="kyw1Breadcrumb">
                        <ul>
                            <li><Link to="/selfprognosis">Self Prognosis • </Link></li>
                            <li id="selectedBreadCrumb"><Link to="/selfprognosis/know-your-wellness"> Know Your Wellness</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainContentKyw;