
import React,{useState, useEffect} from "react";
import { Assets } from "./Assets";
import NavbarKyw from "./Home/NavbarHomePage"
import MainContentKyw from "./Kyw1/MainContentKyw1";
import ContentFrameKyw15 from "./Kyw15/ContentFrameKyw15";
import FooterKyw from "./Kyw1/FooterKyw";
import { PostAPI, GetAPI } from "./utility/APiCall";
import { useNavigate } from "react-router-dom";
// import NavMenu from "./navbar/NavMenu";

const KnowYourWellness15 = () => {

    const navigate = useNavigate();

    const [questionId, setQuestionId] = useState([]);

    const getCatId = sessionStorage.getItem('getStress2');

    console.log('getCatId', getCatId);

    const [category, setCategory] = useState({
        category_id:getCatId,
    });

    const get_questionnaires = (e) => {
    const categoryData = new FormData();
    const getCatId = sessionStorage.getItem('getStress2');
    categoryData.append('category_id', getCatId);
    //categoryData.append('category', "Sleep");

    PostAPI({ url: '/get_onboarding_questionnaires', data: categoryData }, (res) => {
        console.log('Questions', res?.data);
        const question_id = res?.data;
        console.log('question_id',question_id);
        setQuestionId(question_id);
    });
}

useEffect(()=>{
    get_questionnaires();
},[]);

//get_user_plan_details/7

const [getPlan, setGetPlan] = useState();
const [journeyId, setJourneyId] =useState();
const [planDataId, setplanDataId] =useState();
const [month, setMonth] = useState();
console.log('getPlangetPlan', getPlan);

const getPlandetails = () => {

    const getUID = sessionStorage.getItem('getUID')
    const userId = getUID;
    const url = `/get_user_plan_details/${userId}`;

    GetAPI(url, (res)=>{
                const plan_data_id = res?.data?.plan_data_id;
                if (plan_data_id == null || plan_data_id == 0){
                setplanDataId(0);
                } else {
                setplanDataId(plan_data_id);
                }

                const pre_journey_id = res?.data?.pre_journey_id;
                if (pre_journey_id == null || pre_journey_id == 0){
                setJourneyId(0);
                } else {
                setJourneyId(pre_journey_id);
                }

                const current_month = res?.data?.plan_data_id;
                if (current_month == null || current_month == 0){
                setMonth(0);
                } else {
                setMonth(current_month);
                }
            const result = res?.data;
            setGetPlan(result);
    });
}

useEffect(()=>{
    getPlandetails();
}, [])


const [sliderValue, setSliderValues] = useState({});

const [inputValue, setInputValue] = useState(0);
const [isChecked, setIsChecked] = useState(false);
const [sleep_scale, setSleep_scale] = useState([]);
const [errors, setErrors] = useState({});
const [formSubmitted, setFormSubmitted] = useState(false);

const [showLoginPopup, setShowLoginPopup] = useState(false);

const openLoginPopup = () => {
    setShowLoginPopup(true);
    //closeLoginOTPPopup();
};

const closeLoginPopup = () => {
    setShowLoginPopup(false);
};

// const handleRadioChange = (questionId, optionId) => {
//   setInputValue(optionId);
// };


const journey_id = sessionStorage.getItem('journey_id');

const [data, setData] = useState({
    user_id: '',
    category_id: getCatId,
    answer:[],
    //answers: {},
    journey_id: journey_id,
    plan_data_id: '0',
    month: '0',
  });


const handleRadioChange = (questionId, optionId) => {
    setInputValue(prevData => ({
        ...prevData,
        [questionId]: optionId
    }));

    setData(prevData => ({
        ...prevData,
        answer: [
            ...prevData.answer.filter(ans => ans.question_id !== questionId),
            { question_id: questionId, option_id: optionId }
        ]
    }));

    // Remove error for the current question if answered
    setErrors(prevErrors => {
        const newErrors = { ...prevErrors };
        delete newErrors[questionId];
        return newErrors;
    });
    // Check if any option is selected

    console.log("Updated inputValue:", inputValue); // Debugging line
  };


  //console.log('answer',answer);
  const [getSleepData, setSleepData] = useState();
  const [message, setMessage ] = useState()
  const [getSleepscaleData, setSleepscaleData] = useState([]);

  const handleFormSubmit = (e) => {
    e.preventDefault();

      const answers = Object.entries(inputValue).map(([questionId, optionId]) => ({
        question_id: questionId,
        answer: optionId,
    }));

    console.log("Form answers:", answers); // Debugging line

    const getUID = sessionStorage.getItem('getUID')
    const userId = getUID;
    
    const questionData = new FormData();
    questionData.append('user_id', userId);
    questionData.append('category_id', getCatId);
    questionData.append('answer', JSON.stringify(answers));
    questionData.append('journey_id', journeyId);
    questionData.append('plan_data_id', planDataId);
    questionData.append('month', month);

    PostAPI({ url: '/add_onboarding_questionnaires_data', data: questionData }, (res) => {
        console.log('Questions 55', res?.data);
        if(res?.status == 1 ){
            const perceivedStress_scale = res?.scale;
            console.log('perceivedStress_', perceivedStress_scale);
            setSleepData(res?.data);
            const sleep_scale = res?.scale;
            console.log('scale', sleep_scale);
            setData(prevData => ({
                ...prevData,
                answer: res?.data
            }));
            setSleepscaleData(sleep_scale);
          //  const journey_id = res?.journey_id;
            const sleep_score = res?.score;
            setSleepData(sleep_score);
          //  const journey_id = res?.journey_id;
            const perceivedStress_score = res?.score;
           // sessionStorage.setItem('journey_id', journey_id);
            sessionStorage.setItem('perceivedStress_score', perceivedStress_score);
            sessionStorage.setItem('perceivedStress_scale', JSON.stringify(perceivedStress_scale));
            setSleep_scale(perceivedStress_score);
            setFormSubmitted(true); // Update the state to mark form as submitted
          }
      }).catch((error) => {
        console.error('API Error:', error);
      });
}


    return(
        <div>
            <div className="kywBgImages">
                <div className="kywBgImagesInner">
                    <img alt="" id="kywBgLeft" src={ Assets.kywBg1 } />
                    <img alt="" id="kywBgRight" src={ Assets.kywBg2 } />
                </div>
            </div>
            <NavbarKyw />
            <MainContentKyw />
            <ContentFrameKyw15 
              //answers={data.answers}
              data={data} 
              setData={setData} 
              handleFormSubmit={handleFormSubmit} 
              questionId={questionId} 
              setQuestionId={setQuestionId}
              inputValue={inputValue} 
              setInputValue={setInputValue}
              handleRadioChange={handleRadioChange}
              setSleepData={setSleepData}
              getSleepData={getSleepData}
              message={message}
              setMessage={setMessage}
              getSleepscaleData={getSleepscaleData} 
              setSleepscaleData={setSleepscaleData}
              isChecked={isChecked}
              setIsChecked={setIsChecked}
              errors={errors} 
              setErrors={setErrors}
              formSubmitted={formSubmitted}
              setFormSubmitted={setFormSubmitted}
             />
            <FooterKyw />
        </div>
    )
}

export default KnowYourWellness15;