import { Assets } from "../Assets"
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const ContentFrameKyw15 = (props) => {


    const navigate = useNavigate();
    const { data, setData, errors, setErrors, questionId = [], handleRadioChange, inputValue,
        getSleepData, setSleepData, message, setMessage, sliderValue, handleFormSubmit, getSleepscaleData,
        setSleepscaleData, isChecked, setIsChecked, formSubmitted, setFormSubmitted } = props;

    const [isHovered, setIsHovered] = useState(false);
    const [showLoginPopup, setShowLoginPopup] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevents the default form submission behavior

        // If all questions are answered, proceed with form submission
        setShowLoginPopup(true); // Set showLoginPopup to true
        setFormSubmitted(true); // Update the state to mark form as submitted
        props.handleFormSubmit(e); // Call the parent's form submission function
        //alert('check');
        //navigate('/selfprognosis/know-your-wellness/sleep-assessment-result');
    };

    const clickNext = (e) => {
        e.preventDefault(); // Prevents the default form submission behavior
        navigate('/selfprognosis/know-your-wellness/occupational-stress');
        //navigate('/selfprognosis/know-your-wellness/sleep-assessment-result');
    }

    const [visible, setVisible] = useState(false)
    const [isOpen, setIsOpen] = useState(false);


    const handleClose = () => {
        setIsOpen(false);
    };

    const openLoginPopup = () => {
        //setShowLoginPopup(true);
        //alert('Check');
        setFormSubmitted(true); // Update the state to mark form as submitted
    };

    const closeLoginPopup = () => {
        setShowLoginPopup(false);
    };

    //const showHideClassName2 = show ? 'loginPopupBg display-block' : 'loginPopupBg display-none';
    const showHideClassName2 = showLoginPopup
        ? 'loginPopupBg result display-block'
        : 'loginPopupBg result display-none';

    const user_name = sessionStorage.getItem("user_name");

    const getBmiCategoryColor = () => {
        if (getSleepData < 14) {
            return '#7AB375';
        }
        else if (getSleepData >= 14 && getSleepData < 27) {
            return '#E9DD6C';
        }
        else {
            return '#D27060';
        }
    };

    const bmiCategoryColor = getBmiCategoryColor();

    const [sliderValues, setSliderValues] = useState(Array(questionId.length).fill(null));

    const handleSliderChange = (questionIndex, optionId) => {
        const newSliderValues = [...sliderValues];
        newSliderValues[questionIndex] = optionId;
        setSliderValues(newSliderValues);
    };

    const [currentStep, setCurrentStep] = useState(1);
    const nextStep = () => {
        const steps = {
            1: ["9", "10", "11"],
            2: ["12", "13", "14"],
            3: ["15", "16"],
            4: ["17", "18"]
        };

        const unansweredQuestions = questionId
            .filter(item => steps[currentStep].includes(item.question_id))
            .filter(item => !data.answer.some(ans => ans.question_id === item.question_id));

        if (unansweredQuestions.length > 0) {
            const newErrors = {};
            unansweredQuestions.forEach(item => {
                newErrors[item.question_id] = "Please choose at least one.";
            });
            props.setErrors(newErrors);
            return;
        }

        props.setErrors({});
        setCurrentStep(prev => prev + 1);
    };

    const renderQuestions = () => {
        if (!questionId || !Array.isArray(questionId)) {
            return <div>No questions available</div>;
        }

        const steps = {
            1: ["9", "10", "11"],
            2: ["12", "13", "14"],
            3: ["15", "16"],
            4: ["17", "18"]
        };

        const questionsToRender = questionId.filter(item => steps[currentStep].includes(item.question_id));

        return questionsToRender.map((item, index) => {
            const filteredOptions = item.options.map(option => {
                const optionText = option.option.toLowerCase();
                if (["never", "almost never", "sometimes", "often", "usually"].includes(optionText)) {
                    return { ...option, option: optionText.charAt(0).toUpperCase() + optionText.slice(1) };
                }
                return option;
            });

            return (
                <div key={index} className="kyw1MainContentHeading">
                    <h1 style={{ marginTop: '0px' }}>{item.question}</h1>
                    {filteredOptions.map((option, j) => (
                        <div key={j} className="flex items-center mr-4 mb-4 radio">
                            <input
                                id={`${item.question_id}_${option.id}`}
                                value={option.id}
                                type="radio"
                                onChange={() => handleRadioChange(item.question_id, option.id)}
                                checked={inputValue[item.question_id] === option.id}
                                name={`radio${item.question_id}`}
                                className="hidden"
                            />
                            <label htmlFor={`${item.question_id}_${option.id}`} className="flex items-center cursor-pointer">
                                <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                                {option.option}
                            </label>
                        </div>
                    ))}
                    {errors[item.question_id] && <div className="error">{errors[item.question_id]}</div>}
                </div>
            );
        });
    };

    return (
        <div className="contentFrameKyw">
            <div className="contentFrameKywInner">
                <div className="kyw1BgImage position-relative">
                    <div className='absoluteStage'>
                        <p className="mb-0" style={{ marginBottom: '0px' }}>Step 4</p>
                        <p>Stress</p>
                    </div>
                    <img src={Assets.kyw14Bg3} alt="Stress" />
                    <div className='absolute'>
                        {/* <h4>What is Lifestyle Quotient?</h4> */}
                        <p>During periods do you have the urge to fight or flee? Do you feel agitated or fear losing control?</p>
                    </div>
                </div>
                <div className="vertProgressLine"></div>
                <div className="vertProgressLine2"></div>
                <div className="vertProgressLine3"></div>
                <div className="vertProgressLine4"></div>
                <div className="progressCirclesContainer">
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg1" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg2" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg3" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> 4 </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg4" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleTwoForth" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 5 </div>
                        </div>
                    </div>
                    <div className="progressCirclesOuter1">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 6 </div>
                        </div>
                    </div>
                    <div className="progressCirclesOuter7">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 7 </div>
                        </div>
                    </div>
                </div>
                <div className="kyw6MainContent kyw1MainContent">
                    {formSubmitted ? (
                        <>
                            <div className="kyw4MainContentHeading">
                                <p>Hey {user_name},</p>
                            </div>
                            <div className="kyw4MainContentSubHeading">
                                <p>Thanks for taking the Perceived Stress test.</p>
                            </div>
                            <div className="bmiResultWrapper">
                                <div className="bmiResultHeading">
                                    <p>Your preceived stress score is</p>
                                </div>
                                <div className="bmiResult">
                                    <div className="bmiResultFloatResponse">
                                        <input
                                            type="text"
                                            id="bmiHeightInputId"
                                            value={getSleepData}
                                            maxLength={4}
                                        // onChange={(event) => {
                                        //     const newValue = parseInt(event.target.value, 10);
                                        //     if (!isNaN(newValue)) {
                                        //         setSliderValue(newValue);
                                        //         setInputValue(newValue);
                                        //     }
                                        // }}
                                        />
                                    </div>
                                    <div className="bmiResultStringResponse">
                                        <div style={{ marginTop: '10px', color: bmiCategoryColor }}>
                                            {bmiCategoryColor === '#7AB375' && 'Low'}
                                            {bmiCategoryColor === '#E9DD6C' && 'Moderate'}
                                            {bmiCategoryColor === '#D27060' && 'High'}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="percStressSliderContainer">
                                        <input
                                            type="range"
                                            id="slider"
                                            className="bmiSlider"
                                            name="slider"
                                            min="0"
                                            max="40"
                                            value={getSleepData}
                                        // onChange={ handleSliderChange }
                                        />
                                    </div>
                                </div>

                                <div className="bmiLogoBr">
                                    <img alt="" src={Assets.stressLogo} />
                                </div>
                            </div>

                            <div className="bmiCategories">
                                <ul>
                                    {getSleepscaleData && getSleepscaleData.map(item => (
                                        <li className={item.selected ? 'selected' : ''} key={item.id}>
                                            {item.description}
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div className="kywNextLinkWrapper">
                                <Link className="backLink" to="">
                                    <img src={Assets.backArrow} alt="Back" />
                                    Back
                                </Link>
                                <button type="button" onClick={clickNext} id="kywNextButtonSmall"
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                >
                                    Next
                                    {isHovered ? <img alt="" src={Assets.arrowUpRight} /> : <img alt="" src={Assets.arrowUpRightBlack} />}
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <form onSubmit={handleSubmit}>
                                {renderQuestions()}
                                <div className="kywNextLinkWrapper">
                                    <Link className="backLink" to="" onClick={() => {
                                        navigate(-1);
                                    }}>
                                        <img src={Assets.backArrow} alt="Back" />
                                        Back
                                    </Link>
                                    {currentStep < 4 && (
                                        <button
                                            type="button"
                                            onClick={nextStep}
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}
                                            id="kywNextButtonSmall">
                                            Next
                                            {isHovered ? (
                                                <img alt="" src={Assets.arrowUpRight} />
                                            ) : (
                                                <img alt="" src={Assets.arrowUpRightBlack} />
                                            )}
                                        </button>
                                    )}
                                    {currentStep === 4 && (
                                        <button type="submit" id="kywNextButtonSmall"
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}
                                        //onClick={openLoginPopup}
                                        >
                                            Next
                                            {isHovered ? <img alt="" src={Assets.arrowUpRight} /> : <img alt="" src={Assets.arrowUpRightBlack} />}
                                        </button>
                                    )}
                                </div>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ContentFrameKyw15;