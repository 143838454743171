import React, { useState, useEffect } from "react";
import { Assets } from "./Assets";
import NavbarKyw from "./Home/NavbarHomePage"
import MainContentKyw from "./Kyw1/MainContentKyw1";
import ContentFrameKyw2 from "./Kyw2/ContentFrameKyw2";
import FooterKyw from "./Kyw1/FooterKyw";
import { GetAPI } from "./utility/APiCall";
import { useNavigate } from "react-router-dom";
import useCookie from "./cookies/Cookie";
import Cookies from 'js-cookie';
import NavbarHomePage from "./Home/NavbarHomePage";
// import NavMenu from "./navbar/NavMenu";

const KnowYourWellness1 = (props) => {
    //const {scrollToTop} = props;
    const { getUID, setGetUID, userName, setUserName, userId, setUserId } = props;
    const navigate = useNavigate();
    //const userName = sessionStorage.getItem('user_name');
    const [category, setCategory] = useState([]);

    const [getBMIID, setGetBMIID] = useState(null);

    console.log('getBMIIDgetBMIID', getBMIID);
    useEffect(() => {
        // const getUID = sessionStorage.getItem('getUID');
        const getUID = Cookies.get('getUID');
        console.log('getUID:', getUID);
    }, []);


    const getCategoryList = () => {
        const url = `/get_all_onboarding_categories`;
        GetAPI(url, (res) => {
            const getdetails = res?.data;
            console.log('getdetails', getdetails);
            const getBMIID = res?.data.find(item => item.id === "11");
            const bmiID = getBMIID.id;
            console.log('getBMIID', bmiID);
            sessionStorage.setItem('getBMIID', bmiID);
            setGetBMIID(bmiID);

        });
    }

    useEffect(() => {
        getCategoryList();
    }, []);

    const getBMIId = sessionStorage.getItem('getBMIID');

    console.log('category_oo', category);

    const clickNext = () => {
        console.log('getBMIIdgetBMIId', getBMIId);
        navigate('/selfprognosis/know-your-wellness/bmi');
    }


    useEffect(() => {
        // Your effect code here
    }, [userName, userId]);

    return (
        <div>
            <div className="kywBgImages">
                <div className="kywBgImagesInner">
                    <img alt="" id="kywBgLeft" src={Assets.kywBg1} />
                    <img alt="" id="kywBgRight" src={Assets.kywBg2} />
                </div>
            </div>
            {/* <NavbarKyw userName={userName} scrollToTop={scrollToTop} /> */}
            <NavbarHomePage getUID={getUID} setGetUID={setGetUID} userName={userName} setUserName={setUserName} userId={userId} setUserId={setUserId} />
            <MainContentKyw />
            <ContentFrameKyw2 clickNext={clickNext} category={category} setCategory={setCategory} />
            <FooterKyw />
        </div>
    )
}

export default KnowYourWellness1;