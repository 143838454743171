import React, { useEffect } from "react";
import { Assets } from "../Assets"

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { GetAPI } from "../utility/APiCall";

//const ContentFrameKyw8 = () => {

function ContentFrameKyw8(props) {

    const navigate = useNavigate();

    const { questionId } = props;

    const [isHovered, setIsHovered] = useState(false);

    const [sliderValue, setSliderValue] = useState(0);
    const [inputValue, setInputValue] = useState(0);

    const [sliderValue2, setSliderValue2] = useState(0);
    const [inputValue2, setInputValue2] = useState(0);

    const [sliderValue3, setSliderValue3] = useState(0);
    const [inputValue3, setInputValue3] = useState(0);

    const [sliderValue4, setSliderValue4] = useState(0);
    const [inputValue4, setInputValue4] = useState(0);

    const handleSliderChange = (event) => {
        const newValue = parseInt(event.target.value, 10);
        if (!isNaN(newValue)) {
            setSliderValue(newValue);
            setInputValue(newValue);
        }
    };

    const handleSliderChange2 = (event) => {
        const newValue = parseInt(event.target.value, 10);
        if (!isNaN(newValue)) {
            setSliderValue2(newValue);
            setInputValue2(newValue);
        }
    };

    const handleSliderChange3 = (event) => {
        const newValue = parseInt(event.target.value, 10);
        if (!isNaN(newValue)) {
            setSliderValue3(newValue);
            setInputValue3(newValue);
        }
    };

    const handleSliderChange4 = (event) => {
        const newValue = parseInt(event.target.value, 10);
        if (!isNaN(newValue)) {
            setSliderValue4(newValue);
            setInputValue4(newValue);
        }
    };

    const handleInputChange = (event) => {
        const newValue = parseInt(event.target.value, 10);
        if (!isNaN(newValue)) {
            setSliderValue(newValue);
            setInputValue(newValue);
        } else {
            setSliderValue(0);
            setInputValue('');
        }
    };

    const handleInputChange2 = (event) => {
        const newValue = parseInt(event.target.value, 10);
        if (!isNaN(newValue)) {
            setSliderValue2(newValue);
            setInputValue2(newValue);
        } else {
            setSliderValue2(0);
            setInputValue2('');
        }
    };

    const handleInputChange3 = (event) => {
        const newValue = parseInt(event.target.value, 10);
        if (!isNaN(newValue)) {
            setSliderValue3(newValue);
            setInputValue3(newValue);
        } else {
            setSliderValue3(0);
            setInputValue3('');
        }
    };

    const handleInputChange4 = (event) => {
        const newValue = parseInt(event.target.value, 10);
        if (!isNaN(newValue)) {
            setSliderValue4(newValue);
            setInputValue4(newValue);
        } else {
            setSliderValue4(0);
            setInputValue4('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevents the default form submission behavior
        props.handleFormSubmit(e); // Call the parent's form submission function
        navigate('/selfprognosis/know-your-wellness/activity-assessment-2');
        sessionStorage.setItem('question1', inputValue);
        sessionStorage.setItem('question2', inputValue2);
        sessionStorage.setItem('question3', inputValue3);
        sessionStorage.setItem('question4', inputValue4);
    };

    const getCategoryList = () => {
        const url = `/get_all_onboarding_categories`;
        GetAPI(url, (res) => {
            const getdetails = res?.data;
            console.log('getdetails', getdetails);
            const getBMIID = res?.data.find(item => item.id === "4");
            const bmiID = getBMIID.id;
            console.log('getBMIID', bmiID);
            sessionStorage.setItem('getActivityID', bmiID);
            // setGetBMIID(bmiID);

        });
    }

    useEffect(() => {
        getCategoryList();
    }, []);

    return (
        <div className="contentFrameKyw">
            <div className="contentFrameKywInner">
                <div className="kyw1BgImage position-relative">
                    <div className='absoluteStage'>
                        <p className="mb-0" style={{ marginBottom: '0px' }}>Step 5</p>
                        <p>Physical Activity Assessment</p>
                    </div>
                    <img src={Assets.kyw8bg} alt="Activity" />
                    <div className='absolute'>
                        <h4>How active are you?</h4>
                        <p>
                            Do you know an active lifestyle does not stop just at energizing but helps you have a smoother period and less cramping?
                        </p>
                        <p>
                            Answer these questions and we so will understand your lifestyle
                        </p>
                    </div>
                </div>
                <div className="vertProgressLine"></div>
                <div className="vertProgressLine2"></div>
                <div className="vertProgressLine3"></div>
                <div className="vertProgressLine4"></div>
                <div className="vertProgressLine5"></div>
                {/* <div className="vertProgressLine6"></div>
                <div className="vertProgressLine7"></div> */}

                <div className="progressCirclesContainer">

                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg1" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>


                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div>  </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg2" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>

                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div>  </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg3" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>

                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div>  </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg4" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>

                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> 5 </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg5" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleOneThird" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>

                    <div className="progressCirclesOuter1">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 6 </div>
                        </div>
                    </div>
                    {/* <svg id="progressCircleSvg6" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg> */}

                    <div className="progressCirclesOuter1">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 7 </div>
                        </div>
                    </div>
                    {/* <svg id="progressCircleSvg7" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleHalf" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg> */}
                </div>
                <div className="kyw1MainContent">
                    <div className="kyw8MainContentHeading">
                        <h5 style={{ lineHeight: '1.5' }}>
                            {/* questionId?.question} */}
                            For each of the 'Activity types' mentioned below, please answer how many days a week you involve in any of these?</h5>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="activityInputWrapper">
                            <div className="kywSliderUpperContent">
                                <div className="kywActivityQuestionHeading">
                                    <p><b>Active</b> (running or heavy lifting)</p>
                                </div>
                                <div className="activityInput">
                                    <input
                                        value={inputValue}
                                        onChange={handleInputChange}
                                        id="activityActiveInputId"
                                        type="text"
                                        maxLength={3} />
                                    <label htmlFor="activityActiveInputId">Days</label>
                                </div>
                            </div>
                            <div className="kyw3MainContentItems">
                                <div className="activitySlideContainer">
                                    <input
                                        type="range"
                                        id="sliderHeight"
                                        className="bmiSlider"
                                        name="slider"
                                        min="0"
                                        max="7"
                                        value={sliderValue}
                                        onChange={handleSliderChange}
                                    />
                                    <div className="activitySliderLabelContent">
                                        <label htmlFor="sliderHeight" style={{ marginLeft: "6px" }}>0</label>
                                        <label htmlFor="sliderHeight" style={{ margin: "0px" }}>1</label>
                                        <label htmlFor="sliderHeight" style={{ margin: "0px" }}>2</label>
                                        <label htmlFor="sliderHeight" style={{ margin: "0px" }}>3</label>
                                        <label htmlFor="sliderHeight" style={{ margin: "0px" }}>4</label>
                                        <label htmlFor="sliderHeight" style={{ margin: "0px" }}>5</label>
                                        <label htmlFor="sliderHeight" style={{ margin: "0px" }}>6</label>
                                        <label htmlFor="sliderHeight" style={{ marginRight: "6px" }}>7</label>
                                    </div>
                                </div>
                            </div>
                            <div className="kywSliderUpperContent">
                                <div className="kywActivityQuestionHeading">
                                    <p><b>Moderately</b> (light jogging or bicycling)</p>
                                </div>
                                <div className="activityInput">
                                    <input
                                        value={inputValue2}
                                        onChange={handleInputChange2}
                                        id="activityModerateInputId"
                                        type="text"
                                        maxLength={3} />
                                    <label htmlFor="activityModerateInputId">Days</label>
                                </div>
                            </div>
                            <div className="kyw3MainContentItems">
                                <div className="activitySlideContainer">
                                    <input
                                        type="range"
                                        id="sliderHeight"
                                        className="bmiSlider"
                                        name="slider"
                                        min="0"
                                        max="7"
                                        value={sliderValue2}
                                        onChange={handleSliderChange2}
                                    />
                                    <div className="activitySliderLabelContent">
                                        <label htmlFor="sliderHeight" style={{ marginLeft: "6px" }}>0</label>
                                        <label htmlFor="sliderHeight" style={{ margin: "0px" }}>1</label>
                                        <label htmlFor="sliderHeight" style={{ margin: "0px" }}>2</label>
                                        <label htmlFor="sliderHeight" style={{ margin: "0px" }}>3</label>
                                        <label htmlFor="sliderHeight" style={{ margin: "0px" }}>4</label>
                                        <label htmlFor="sliderHeight" style={{ margin: "0px" }}>5</label>
                                        <label htmlFor="sliderHeight" style={{ margin: "0px" }}>6</label>
                                        <label htmlFor="sliderHeight" style={{ marginRight: "6px" }}>7</label>
                                    </div>
                                </div>
                            </div>
                            <div className="kywSliderUpperContent">
                                <div className="kywActivityQuestionHeading">
                                    <p><b>Low</b> (walking for instance)</p>
                                </div>
                                <div className="activityInput">
                                    <input
                                        value={inputValue3}
                                        onChange={handleInputChange3}
                                        id="activityLowInputId"
                                        type="text"
                                        maxLength={3} />
                                    <label htmlFor="activityLowInputId">Days</label>
                                </div>
                            </div>
                            <div className="kyw3MainContentItems">
                                <div className="activitySlideContainer">
                                    <input
                                        type="range"
                                        id="sliderHeight"
                                        className="bmiSlider"
                                        name="slider"
                                        min="0"
                                        max="7"
                                        value={sliderValue3}
                                        onChange={handleSliderChange3}
                                    />
                                    <div className="activitySliderLabelContent">
                                        <label htmlFor="sliderHeight" style={{ marginLeft: "6px" }}>0</label>
                                        <label htmlFor="sliderHeight" style={{ margin: "0px" }}>1</label>
                                        <label htmlFor="sliderHeight" style={{ margin: "0px" }}>2</label>
                                        <label htmlFor="sliderHeight" style={{ margin: "0px" }}>3</label>
                                        <label htmlFor="sliderHeight" style={{ margin: "0px" }}>4</label>
                                        <label htmlFor="sliderHeight" style={{ margin: "0px" }}>5</label>
                                        <label htmlFor="sliderHeight" style={{ margin: "0px" }}>6</label>
                                        <label htmlFor="sliderHeight" style={{ marginRight: "6px" }}>7</label>
                                    </div>
                                </div>
                            </div>
                            <div className="kywSliderUpperContent">
                                <div className="kywActivityQuestionHeading">
                                    <p><b>Sedentary</b> (desk work, watching TV, etc.)</p>
                                </div>
                                <div className="activityInput">
                                    <input
                                        value={inputValue4}
                                        onChange={handleInputChange4}
                                        id="activitySedentaryInputId"
                                        type="text"
                                        maxLength={3} />
                                    <label htmlFor="activitySedentaryInputId">Days</label>
                                </div>
                            </div>
                            <div className="kyw3MainContentItems">
                                <div className="activitySlideContainer">
                                    <input
                                        type="range"
                                        id="sliderHeight"
                                        className="bmiSlider"
                                        name="slider"
                                        min="0"
                                        max="7"
                                        value={sliderValue4}
                                        onChange={handleSliderChange4}
                                    />
                                    <div className="activitySliderLabelContent">
                                        <label htmlFor="sliderHeight" style={{ marginLeft: "6px" }}>0</label>
                                        <label htmlFor="sliderHeight" style={{ margin: "0px" }}>1</label>
                                        <label htmlFor="sliderHeight" style={{ margin: "0px" }}>2</label>
                                        <label htmlFor="sliderHeight" style={{ margin: "0px" }}>3</label>
                                        <label htmlFor="sliderHeight" style={{ margin: "0px" }}>4</label>
                                        <label htmlFor="sliderHeight" style={{ margin: "0px" }}>5</label>
                                        <label htmlFor="sliderHeight" style={{ margin: "0px" }}>6</label>
                                        <label htmlFor="sliderHeight" style={{ marginRight: "6px" }}>7</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kywNextLinkWrapper">
                            <Link className="backLink" to="" onClick={() => {
                                navigate(-1);
                            }}>
                                <img src={Assets.backArrow} alt="Back" />
                                Back
                            </Link>
                            <button type="submit" id="kywNextButtonSmall"
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                Next
                                {isHovered ? <img alt="" src={Assets.arrowUpRight} /> : <img alt="" src={Assets.arrowUpRightBlack} />}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    )
}

export default ContentFrameKyw8;