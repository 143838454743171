import { Assets } from '../Assets';

import { Link } from 'react-router-dom'
//import { useData } from '../../DataContext';
import { useData } from '../Context/AppContextProvider';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PopupLogin from '../LoginPopup/PopupLogin'
import PopupLoginOTP from '../LoginPopup/PopupLoginOTP'
import PopupSignup from "../SignupPopup/PopupSignup";
import SuccessPopup from "../SignupPopup/SuccessPopup";
//import { PostAPI } from "../utility/APiCall";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PostAPI, GetAPI, PatchAPI, DeleteAPI } from '../utility/APiCall'
import useCookie from "../cookies/Cookie";
import Cookies from 'js-cookie';
import { useName } from '../Context/AppContextProvider';

//import {PostAPI, GetAPI, PatchAPI, DeleteAPI} from '../utility/APiCall'

const SelectedPlansWEllnessPlansCheckout = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [showSignUpPopup, setShowSignUpPopup] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showLoginOTPPopup, setShowOTPPopup] = useState(false);
    const [isOTPReceived, setIsOTPReceived] = useState(false);
    const [isOTPEntered, setIsOTPEntered] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleClose = () => {
        setIsOpen(false);
        setShowSuccessPopup(false);
    };

    const openLoginPopup = () => {
        setShowLoginPopup(true);
        closeLoginOTPPopup();
        setShowSignUpPopup(false);
    };

    const openSuccessPopup = () => {

    }

    const closeSuccessPopup = () => {
        setShowSuccessPopup(false);
    }

    const closeLoginPopup = () => {
        setShowLoginPopup(false);
    };

    const openSignUpPopup = () => {
        setShowSignUpPopup(true);
        setShowLoginPopup(false);
    };

    const closeSignUpPopup = () => {
        setShowSignUpPopup(false);
        setShowSignUpPopup(true);
    };

    const openLoginOTPPopup = () => {
        setShowOTPPopup(true);
        closeLoginPopup();
    };

    const closeLoginOTPPopup = () => {
        setShowOTPPopup(false);
    };

    const [isMobileConfirmed, setIsMobileConfirmed] = useState(false);
    const [data, setData] = useState({
        phone: '',
    });

    const [getUID, setGetUID] = useState();
    // console.log('getUID',getUID);

    // sessionStorage.setItem('getUID',getUID);
    // const [userName, setUserName] = useCookie('user_name', '');
    // const [userId, setUserId] = useCookie('getUID', '');
    const { userName, userId, setUserName, setUserId } = useName();
    const [NewuserId, setNewUserId] = useCookie('new_user', '');

    console.log('NewuserId', NewuserId);

    useEffect(() => {
        // Add your condition here (e.g., check if userId exists)
        if (userId) {
            console.log('After login userId', userId);
        }
    }, [userId]);

    useEffect(() => {
        // Add your condition here (e.g., check if userId exists)
        if (userName) {
            console.log('After login userName', userName);
        }
    }, [userName]);

    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [isFormVisible, setFormVisible] = useState(false);
    const [isOtpComplete, setIsOtpComplete] = useState(false);
    const otpInputs = Array.from({ length: 6 }, (_, index) => index);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const inputRefs = otpInputs.map(() => useRef(null));

    useEffect(() => {
        if (isFormVisible) {
            inputRefs[0].current.focus();
        }
    }, [isFormVisible]);

    const handleChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        if (value !== '' && index < otpInputs.length - 1) {
            inputRefs[index + 1].current.focus();
        }
        const isComplete = newOtp.every((char) => char !== '');
        setIsOtpComplete(isComplete);
    };

    const handleBackspace = (index) => {
        if (otp[index] === '') {
            if (index > 0) {
                inputRefs[index - 1].current.focus();
            }
        }
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);

        const isComplete = newOtp.every((char) => char !== '');
        setIsOtpComplete(isComplete);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity()) {
            if (!isMobileConfirmed) {
                // If mobile is not confirmed yet, call "confirm_mobile" API
                const confirmMobileData = new FormData();
                //confirmMobileData.append('user_id', data?.user_id);
                confirmMobileData.append('phone', data?.phone);
                console.log('confirmMobileData:', confirmMobileData);
                sessionStorage.setItem('phone', data?.phone);
                PostAPI({ url: '/web/trigger_sign_up_otp', data: confirmMobileData }, (confirmMobileResponse) => {
                    //console.log('confirmMobileResponse:', confirmMobileResponse);
                    //toast.success(confirmMobileResponse.message);
                    //alert('success');
                    if (confirmMobileResponse.status == 1) {
                        openLoginOTPPopup(true);
                        const getuser_id = confirmMobileResponse?.data?.user_id;
                        const new_user = confirmMobileResponse?.data?.new_user;
                        sessionStorage.setItem('getUID', getuser_id);
                        setUserId(getuser_id);
                        setGetUID(getuser_id);
                        setNewUserId(new_user);
                        // Set isOTPReceived to true when OTP is sent
                        setIsOTPReceived(true);
                        toast.success('OTP sent to your mobile number');
                    } else {
                        // Handle failure
                        if (confirmMobileResponse.status == 0) {
                            toast.error(confirmMobileResponse.message);
                            alert(confirmMobileResponse.message);
                            //toast.success('User Already Logged In. Please Log Out First');
                            console.log('failure:', confirmMobileResponse.message);
                        }

                        // alert('');
                        // console.log('failure:', confirmMobileResponse.message);
                    }
                });
            }
        }
        //resetForm();
    };
    useEffect(() => {
        // const getUID = sessionStorage.getItem('getUID');
        // console.log('getUID:', getUID);
    }, []);

    const handleFormSubmitotp = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity()) {
            // If mobile is confirmed, call "verify_confirm_mobile" API

            const verifyData = new FormData();
            verifyData.append('user_id', getUID);
            verifyData.append('phone', data?.phone);
            verifyData.append('otp', otp.join(''));

            PostAPI({ url: '/web/verify_web_login', data: verifyData }, (verifyResponse) => {
                console.log('verifyResponse:', verifyResponse);
                if (verifyResponse.status === 1) {
                    if (NewuserId == 0) {
                        toast.success(verifyResponse.message);
                        const user_name = verifyResponse?.data?.user_name;
                        const user_id = verifyResponse?.data?.id;
                        sessionStorage.setItem('user_name', user_name);
                        sessionStorage.setItem('getUID', user_id);
                        setUserName(user_name);
                        setUserId(user_id);
                        toast.success(verifyResponse?.message);
                        closeLoginOTPPopup();
                    } else if (NewuserId == 1) {
                        // openLoginOTPPopup(false);
                        setShowSignUpPopup(true);
                        closeLoginOTPPopup();
                    } else {
                        alert('check backend');
                    }
                } else {
                    toast.error(verifyResponse?.message);
                }
            });

            setIsOTPEntered(true);
            // Set isOTPEntered to true when OTP is entered
        }
        //resetForm();
        // else if(){
        //   setShowSignUpPopup(true);
        // } 

    };
    const phone = sessionStorage.getItem('phone');
    const [dataSignup, setDtatSignUp] = useState({
        user_name: '',
        user_phone: phone,
        user_email: '',
        user_dob: '',
    });

    const handleFormSubmitSignup = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity()) {
            // If mobile is confirmed, call "verify_confirm_mobile" API
            const verifyData = new FormData();
            // verifyData.append('user_id', getUID);
            //const phone = sessionStorage.getItem('phone');
            verifyData.append('user_id', getUID);
            verifyData.append('user_name', dataSignup?.user_name);
            //verifyData.append('user_phone', phone);
            verifyData.append('user_phone', data?.phone);
            verifyData.append('user_email', dataSignup?.user_email);
            verifyData.append('user_dob', dataSignup?.user_dob);

            PostAPI({ url: '/web/new_user_sign_up', data: verifyData }, (verifyResponse) => {
                console.log('signupverifyResponse:', verifyResponse);
                if (verifyResponse.status == 1) {
                    toast.success(verifyResponse.message);
                    setShowSignUpPopup(false);
                    setShowSuccessPopup(true);
                    setUserName(dataSignup?.user_name);
                } else {
                    toast.error(verifyResponse?.message);
                }
            });
        }
        //resetForm();
    };


    //  const userName = sessionStorage.getItem('user_name');
    //  const getuser = sessionStorage.getItem('getUID');

    //const navigate = useNavigate();

    //  const getToken = sessionStorage.getItem("sessionToken");
    //   const UserId = sessionStorage.getItem('getUID');

    //   const [values, setValues] = useState({
    //     user_id:getUID,
    //     accessToken:getToken,
    //   });

    function handleLogOut() {
        //  alert('check')
        //const getUID = sessionStorage.getItem('getUID');
        //const getUID = sessionStorage.getItem('getUID');
        const getUID = Cookies.get('getUID');
        const url = '/web/user_web_logout';
        const formData = new FormData();
        formData.append('user_id', getUID);
        //formData.append('accessToken', values?.accessToken);
        const params = { url, data: formData };
        //sessionStorage.clear();
        PostAPI(params, (res) => {
            // Assuming 'token' is the authentication token received from the server
            //console.log('token', getToken);
            //  toast.success(res?.message);
            //sessionStorage.clear();
            if (res.status == 1) {
                toast.success(res?.message);
                //sessionStorage.setItem('sessionToken', getToken);
                sessionStorage.clear();
                // Clear the cookies
                Cookies.remove('user_name');
                Cookies.remove('getUID');
                Cookies.remove('new_user');
                setTimeout(() => {
                    navigate('/');
                    // Reload the page
                    window.location.reload();
                }, 2000);
            } else {
                toast.error(res?.message);
            }
        });
        //sessionStorage.setItem("sessionToken", getToken);

    }
    const scrollToTop = () => {
        // setLoading(true);
        // setTimeout(() => {
        // setLoading(false);
        // }, 2000);

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    window.onscroll = () => { scrollFunction() };

    // function scrollFunction () {
    //     if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
    //         document.getElementById("laiqaNavbar").style.padding = "0 0";
    //         document.getElementById("laiqaNavbar").style.borderRadius = "0 0 10px 10px";
    //         document.getElementById("laiqaNavbar").style.backgroundColor = "#ffffff";
    //         document.getElementById("id_laiqaLogo").style.transform = "scale(0.7)";
    //         document.getElementById("id_laiqaLogo").style.transition = "transform 0.2s";
    //     } else {
    //         document.getElementById("laiqaNavbar").style.padding = "36px 0";
    //         document.getElementById("laiqaNavbar").style.borderRadius = "0 0 40px 40px";
    //         document.getElementById("laiqaNavbar").style.backgroundColor = "#ffffffcc";
    //         document.getElementById("id_laiqaLogo").style.transform = "scale(1)";
    //         document.getElementById("id_laiqaLogo").style.transition = "transform 0.2s";
    //     }
    // }

    function scrollFunction() {
        const navbar = document.getElementById("laiqaNavbar");
        const logo = document.getElementById("id_laiqaLogo");

        if (!navbar || !logo) {
            console.error("Elements with IDs 'laiqaNavbar' or 'id_laiqaLogo' not found");
            return;
        }

        if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
            navbar.style.padding = "0 0";
            navbar.style.borderRadius = "0 0 10px 10px";
            navbar.style.backgroundColor = "#ffffff";
            logo.style.transform = "scale(0.7)";
            logo.style.transition = "transform 0.2s";
        } else {
            navbar.style.padding = "36px 0";
            navbar.style.borderRadius = "0 0 40px 40px";
            navbar.style.backgroundColor = "#ffffffcc";
            logo.style.transform = "scale(1)";
            logo.style.transition = "transform 0.2s";
        }
    }

    const { sharedData } = useData();

    const convertNumberToString = (number) => {
        switch (number) {
            case 3:
                return 'Silver';
            case 4:
                return 'Gold';
            case 5:
                return 'Platinum';
            default:
                alert("Please select a wellness plan")
                return 'No';
        }
    };

    const mapStringToPrice = (stringValue) => {
        switch (stringValue) {
            case 'Silver':
                return 8999;
            case 'Gold':
                return 13499;
            case 'Platinum':
                return 21599;
            case 'No':
                return 0;
        }
    };

    const stringVersion = convertNumberToString(sharedData);
    const price = mapStringToPrice(stringVersion);
    const [razorpayResponse, setRazorpayResponse] = useState(null);
    const [orderId, setOrderId] = useState(null);
    const [paymentId, setPaymentId] = useState(null);
    const getUserID = sessionStorage.getItem('getUID')

    const apiRequestData = {
        user_id: getUserID,
        wellness_plan_id: sharedData,
        price: price,
        coupon: 'no',
        total: price,
    };

    console.log("Request Data", apiRequestData);

    const handleCheckout = () => {
        if (!getUserID) {
            console.log('User is not logged in');
            // User login popup if user not logged in already
            // alert("Please log in before buying a plan")
            setShowLoginPopup(true);
            closeLoginOTPPopup();
            setShowSignUpPopup(false);
        } else {
            PostAPI({ url: '/purchase_wellness_plan', data: apiRequestData }, (response) => {
                console.log('Callback Response:', response);
                if (!response) {
                    console.error('Error: No response received from the server.');
                    return;
                }

                if (response.error) {
                    console.error('Error while creating Razorpay order:', response.error);
                    return;
                }

                setRazorpayResponse(response);
            });
        }
    };

    console.log("razorpayResponse", razorpayResponse);

    useEffect(() => {
        if (razorpayResponse) {
            const options = {
                key: 'rzp_test_dV1CkVjvbk70lo',
                amount: parseInt(price * 100),
                currency: 'INR',
                order_id: razorpayResponse.razorpay_order_id,
                name: 'Laiqa Wellness',
                description: 'Wellness Plan',
                prefill: {
                    name: '',
                    email: '',
                    contact: '',
                },
                theme: {
                    color: '#D27060',
                },
                handler: function (response) {
                    setOrderId(razorpayResponse.data.razorpay_order_id);
                    setPaymentId(response.razorpay_payment_id)
                    console.log("orderId After Payment", razorpayResponse);
                    sendPaymentIdToBackend(paymentId, orderId);
                },
            };

            const razorpay = new window.Razorpay(options);
            razorpay.open();
        }
    }, [razorpayResponse]);

    const navigate = useNavigate();

    const sendPaymentIdToBackend = (paymentId, orderId) => {
        const paymentConfirmData = {
            razorpay_order_id: orderId,
            razorpay_payment_id: paymentId,
            status: 'paid',
            start_date: '',
        };

        console.log("Order ID", orderId);
        PostAPI({ url: '/wellness_payment', data: paymentConfirmData }, (response) => {
            console.log('Payment Successful Response', response);

            if (response && response.status === 1 && response.message === 'Payment Data Inserted') {
                navigate('/wellness-plans/checkout/login/success');
            }
        });
    };

    useEffect(() => {
        if (orderId && paymentId) {
            sendPaymentIdToBackend(paymentId, orderId);
        }
    }, [orderId, paymentId, navigate]);

    return (
        <div className="selectedPlanWrapper">
            <SuccessPopup
                show={showSuccessPopup} handleClose={closeSuccessPopup} openSuccessPopup={openSuccessPopup}
            />
            <PopupSignup
                show={showSignUpPopup} handleClose={closeSignUpPopup} openLoginPopup={openLoginPopup}
                handleFormSubmitSignup={handleFormSubmitSignup}
                dataSignup={dataSignup}
                setDtatSignUp={setDtatSignUp}
            />
            <PopupLogin
                handleFormSubmit={handleFormSubmit}
                getUID={getUID}
                setGetUID={setGetUID}
                // manuallySetOTP={manuallySetOTP}
                data={data}
                setData={setData}
                isMobileConfirmed={isMobileConfirmed}
                setIsMobileConfirmed={setIsMobileConfirmed}
                show={showLoginPopup} handleClose={closeLoginPopup}
                openLoginOTPPopup={openLoginOTPPopup}
                openSignUpPopup={openSignUpPopup}
            />
            <PopupLoginOTP
                handleFormSubmitotp={handleFormSubmitotp}
                getUID={getUID}
                setGetUID={setGetUID}
                data={data}
                setData={setData}
                isFormVisible={isFormVisible}
                setFormVisible={setFormVisible}
                otp={otp}
                setOtp={setOtp}
                isOtpComplete={isOtpComplete}
                setIsOtpComplete={setIsOtpComplete}
                inputRefs={inputRefs}
                otpInputs={otpInputs}
                handleBackspace={handleBackspace}
                handleChange={handleChange}
                show={showLoginOTPPopup} handleClose={closeLoginOTPPopup} />
            <div className="plansAndLoginWrapper">
                <div className="selectedPlansPriceList">
                    <div className="checkoutPriceListWrapper">
                        <div className="wellnessPlansSelectedTag" style={{ display: sharedData === 3 ? "block" : "none" }}>
                            Selected
                        </div>
                        <Link to="/wellness-plans/checkout">
                            <div className={sharedData == 3 ? "checkoutSilverPlan selectedPlanCheckout" : "checkoutSilverPlan"}>
                                <div className="checkoutPlanHeading" id="silverPlanHeading">
                                    <p className="planNameHeading">Silver Plan</p>
                                    <div className="wellnessSilverPlanHeadingPrice">
                                        <b><p>&#8377;8,999</p></b>
                                        <strike><p> 9,999</p></strike>
                                    </div>
                                </div>
                                <div className="checkoutPlanListContent">
                                    <ul>
                                        <li>Monthly Lifestyle and Menstrual Assessment</li>
                                        <li>Weekly Diet & Fitness Plans</li>
                                        <li>Daily Lifestyle Tips</li>
                                        <li>Unlimited Chat Support</li>
                                        <li>Fortnightly Consultation with Ayurvedic Doctor / Dietician</li>
                                        <li>Weekly Expert Workshops</li>
                                        <li>Free Product Voucher Worth of ₹900</li>
                                        <li>User Progress Report at the end of plan</li>
                                    </ul>
                                </div>
                            </div>
                        </Link>

                        <div className="wellnessPlansSelectedTag" style={{ display: sharedData === 4 ? "block" : "none" }}>
                            Selected
                        </div>
                        <Link to="/wellness-plans/checkout">
                            <div className={sharedData == 4 ? "checkoutGoldPlan selectedPlanCheckout" : "checkoutGoldPlan"}>
                                <div className="checkoutPlanHeading" id="goldPlanHeading">
                                    <p className="planNameHeading">Gold Plan</p>
                                    <div className="wellnessSilverPlanHeadingPrice">
                                        <b><p>&#8377;13,499</p></b>
                                        <strike><p> 14,999</p></strike>
                                    </div>
                                </div>
                                <div className="checkoutPlanListContent">
                                    <ul>
                                        <li>Monthly Lifestyle and Menstrual Assessment</li>
                                        <li>Weekly Diet & Fitness Plans</li>
                                        <li>Daily Lifestyle Tips</li>
                                        <li>Unlimited Chat Support</li>
                                        <li>Fortnightly Consultation with Ayurvedic Doctor / Dietician</li>
                                        <li>Weekly Expert Workshops</li>
                                        <li>Free Product Voucher Worth of ₹1800</li>
                                        <li>User Progress Report at the end of plan</li>
                                    </ul>
                                </div>

                            </div>
                        </Link>

                        <div className="wellnessPlansSelectedTag" style={{ display: sharedData === 5 ? "block" : "none" }}>
                            Selected
                        </div>
                        <Link to="/wellness-plans/checkout">
                            <div className={sharedData == 5 ? "checkoutPlatinumPlan selectedPlanCheckout" : "checkoutPlatinumPlan"}>
                                <div className="checkoutPlanHeading" id="platinumPlanHeading">
                                    <p className="planNameHeading">Platinum Plan</p>
                                    <div className="wellnessSilverPlanHeadingPrice">
                                        <b><p>&#8377;21,599</p></b>
                                        <strike><p> 23,999</p></strike>
                                    </div>
                                </div>
                                <div className="checkoutPlanListContent">
                                    <ul>
                                        <li>Monthly Lifestyle and Menstrual Assessment</li>
                                        <li>Weekly Diet & Fitness Plans</li>
                                        <li>Daily Lifestyle Tips</li>
                                        <li>Unlimited Chat Support</li>
                                        <li>Fortnightly Consultation with Ayurvedic Doctor / Dietician</li>
                                        <li>Weekly Expert Workshops</li>
                                        <li>Free Product Voucher Worth of ₹3600</li>
                                        <li>User Progress Report at the end of plan</li>
                                    </ul>
                                </div>

                            </div>
                        </Link>

                    </div>
                </div>

                <div className="checkoutLogin">
                    <div className="checkoutLoginWrapper">
                        <div className="checkoutLoginHeading">
                            Checkout
                        </div>
                        <div className="checkoutLoginHeading2">
                            <b>{stringVersion}</b> Plan Selected
                        </div>
                        <div className="checkoutLoginContent">
                            <div className="checkoutLoginContentHeading">
                                Cart Total
                            </div>
                            <div className="checkoutLoginPricingDetails">
                                <div className="subtotalContent">
                                    <div className="subtotal">
                                        Subtotal
                                    </div>
                                    <div className="subtotalPrice">
                                        <b>&#8377;{price}</b>
                                    </div>
                                </div>
                                <div className="otherChargesContent">
                                    <div className="subtotal">
                                        Other Charges
                                    </div>
                                    <div className="subtotalPrice">
                                        &#8377;0.00
                                    </div>
                                </div>
                                <div className="totalContent">
                                    <div className="subtotal">
                                        Total:
                                    </div>
                                    <div className="totalPrice">
                                        &#8377;{price}
                                    </div>
                                </div>
                            </div>
                            <div className="couponFormWrapper">
                                {/* <form action="">
                                    <input type="text" placeholder="Enter code"/>
                                    <Link id="applyCouponLink">Apply coupon</Link>
                                </form> */}
                                <button id="couponSubmit" onClick={handleCheckout}>
                                    Proceed to checkout
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="complianceContentCheckout">
                <div className="complianceContentWrapper">
                    <div className="complianceContentHeading">
                        <div className="complianceContentHeading1">
                            Safe and Secure
                        </div>
                        <div className="complianceContentHeading2">
                            We are compliant to <b>Industry standards.</b>
                        </div>
                    </div>
                    <div className="complianceContentImage">
                        <img src={Assets.complianceContentBanner} alt="Industry Standards" />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SelectedPlansWEllnessPlansCheckout;