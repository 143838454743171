import React, { useEffect } from "react";
import { Assets } from "../Assets"
import { GetAPI } from "../utility/APiCall";
import { useState } from 'react';
import { Link } from 'react-router-dom';

import WellnessReportScore from "./WellnessReportScore";
import CompleteReportPopup from "./CompleteReportPopup";

const ContentFrameKyw24 = (props) => {

    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const [showCompleteReportPopup, setShowCompleteReportPopup] = useState(false);

    const openCompleteReportPopup = () => {
        setShowCompleteReportPopup(true);
    };

    const closeCompleteReportPopup = () => {
        setShowCompleteReportPopup(false);
    };

    // const [getScore, setGetScore] = useState([]);
    const [getScore, setGetScore] = useState();
    console.log('getScore:>>>', getScore);
    const [message, setMessage] = useState({});
    const [score, setScore] = useState(null);
    const [remark, setRemark] = useState()
    const [reports, setReports] = useState([]);

    const [reportData, setReportData] = useState(null);

    console.log('getScore', getScore);
    console.log('score', score);
    console.log()

    const getCategoryListScore = () => {

        const user_id = sessionStorage.getItem('getUID');
        const journey_id = sessionStorage.getItem('journey_id');
        const url = `/web/web_pre_plan_lq_report?user_id=${user_id}&journey_id=${journey_id}`;
        GetAPI(url, (res) => {
            console.log('LQ: ' + res?.data?.lq_score);
            const lq_score = res?.data?.lq_score;
            setScore(lq_score);
            const remark = res?.data?.remarks;
            setRemark(remark);
            console.log('remark ' + remark);
            const getData = res?.data;
            console.log('getData', getData);
            const reports = res?.data?.reports || [];
            setReports(reports);
            setReportData(res?.data);
        });

    }

    useEffect(() => {
        getCategoryListScore();
    }, []);

    function getColorForInference(inference) {
        switch (inference) {
            case 'Underweight':
                return '#30262880';
            case 'Normal weight':
                return '#7AB375';
            case 'Pre-obesity':
                return '#E9DD6C';
            case 'Obesity':
                return '#D27060';
            case 'Poor Sleep':
                return '#d27060';
            case 'Good Sleep':
                return '#7ab375';
            case 'Moderate Stress':
                return '#e9dd6c';
            case 'High Stress':
                return '#d27060';
            case 'Low Stress':
                return '#7ab375';
            case 'Pontentially High':
                return '#d27060';
            case 'Severe Stress':
                return '#fc9403';
            case 'Moderate':
                return '#fcf803';
            case 'Fairly Low':
                return '#ccff00';
            case 'Relatively Calm':
                return '#7ab375';
            case 'Low (< 600 MET-minutes\/week)':
                return '#7ab375';
            case 'Moderate (>=600 to <1500 MET-minutes\/week)':
                return '#fcf803';
            case 'High (>=1500 MET-minutes\/week)':
                return '#d27060';
            case 'No pain':
                return '#7ab375';
            case 'mild pain':
                return '#fcf803';
            case 'moderate pain':
                return '#fc9403';
            case 'severe pain':
                return '#d27060';
            default:
                return 'black'; // Default color
        }
    }

    return (
        <div className="contentFrameKyw">
            <CompleteReportPopup show={showCompleteReportPopup} handleClose={closeCompleteReportPopup} />
            <div className="contentFrameKywInner">
                <div className="kyw1BgImage position-relative">
                    <div className='absoluteStage'>
                        <p className="mb-0" style={{ marginBottom: '0px' }}>Step 7</p>
                        <p>Wellness Reports</p>
                    </div>
                    <img src={Assets.wellnessReportBg} alt="Body Mass Index" />
                    <div className='absolute'>
                        <h4>What is Lifestyle Quotient?</h4>
                        <p>Lifestyle Quotient" (LQ) is like a score that shows how healthy and balanced your daily habits and choices are. It considers things like what you eat, how active you are, and how you manage stress. By understanding your lifestyle quotient, you can make changes to live a healthier and more satisfying life</p>
                    </div>
                </div>
                <div className="vertProgressLine"></div>
                <div className="vertProgressLine2"></div>
                <div className="vertProgressLine3"></div>
                <div className="vertProgressLine4"></div>
                <div className="vertProgressLine5"></div>
                <div className="vertProgressLine6"></div>
                <div className="vertProgressLine7"></div>
                <div className="progressCirclesContainer">
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg1" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg2" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg3" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg4" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg5" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg6" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> <div className="progressCircleCompletedd"></div> </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg7" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>
                </div>
                <div className="kyw1MainContent">
                    <div className="kyw4MainContentHeading">
                        <p>Wellness Report</p>
                    </div>
                    <div className="lqResults">
                        <div className="lqResultScoreWrapper" style={{ marginRight: "20px" }}>
                            <p>Your lifestyle quotient</p>

                            <p id="lqResultScoreDouble">
                                {score}
                            </p>
                        </div>
                        <div className="lqResultScoreWrapper">
                            <p>Remark</p>
                            <p style={{ fontSize: '18px' }} id="lqResultScoreString">
                                {remark}
                            </p>
                        </div>
                    </div>
                    <div className="wellnessReportContent">
                        {Array.isArray(reports) && reports.length > 0 ? (
                            <>
                                {reports
                                    .filter(item => item.parameter !== '' && item.id !== '') // Exclude items with empty parameter and empty id
                                    .map(item => {
                                        let componentToRender;

                                        switch (item.inference) {
                                            case 'Poor':
                                                componentToRender = (
                                                    <div className="poorComponentWellnessReport">
                                                        {/* Add your content for 'Poor' */}
                                                        <img alt='' src={Assets.greenHeart} />
                                                        <p>Poor</p>
                                                    </div>
                                                );
                                                break;

                                            default:
                                                // Handle any other cases or use a default component
                                                componentToRender = (
                                                    <div className="defaultComponentWellnessReport">
                                                        <p>

                                                            {item.inference === "No pain" && (<span style={{ color: item.inference === "No pain" ? 'green' : 'black' }}>No pain</span>)}
                                                            {item.inference === "Relatively Calm" && (<span style={{ color: item.inference === "Relatively Calm" ? 'green' : 'black' }}>Relatively Calm</span>)}
                                                            {item.inference === "High (>=1500 MET-minutes\/week)" && (<span style={{ color: item.inference === "High (>=1500 MET-minutes\/week)" ? 'red' : 'black' }}>High</span>)}
                                                            {item.inference === "Pontentially High" && (<span style={{ color: item.inference === "Pontentially High" ? 'red' : 'black' }}>Pontentially High</span>)}
                                                            {item.inference === "Low (< 600 MET-minutes\/week)" && (<span style={{ color: item.inference === "Low (< 600 MET-minutes\/week)" ? 'red' : 'black' }}>Severe</span>)}
                                                            {item.inference === "mild pain" && (<span style={{ color: item.inference === "mild pain" ? 'red' : 'black' }}>Mild pain</span>)}
                                                            {item.inference === "Good Sleep" && (<span style={{ color: item.inference === "Good Sleep" ? 'green' : 'black' }}>Good Sleep</span>)}
                                                            {item.inference === "Normal weight" && (<span style={{ color: item.inference === "Normal weight" ? 'green' : 'black' }}>Normal weight</span>)}
                                                            {item.inference === "Low < 600 MET-minutes\/week" && (<span style={{ color: item.inference === "Low < 600 MET-minutes/week" ? 'red' : 'black' }}>Low</span>)}
                                                            {item.inference === "Underweight" && (<span style={{ color: item.inference === "Underweight" ? 'red' : 'black' }}>Underweight</span>)}
                                                            {item.inference === "Low Stress" && (<span style={{ color: item.inference === "Low Stress" ? 'red' : 'black' }}>Low Stress</span>)}
                                                            {item.inference === "Poor Sleep" && (<span style={{ color: item.inference === "Poor Sleep" ? 'red' : 'black' }}>Poor Sleep</span>)}
                                                            {item.inference === "High Stress" && (<span style={{ color: item.inference === "High Stress" ? '#F6B04733' : 'black' }}>High Stress</span>)}
                                                            {item.inference === "Pontentially Dangerous" && (<span style={{ color: item.inference === "Pontentially Dangerous" ? 'red' : 'black' }}>Pontentially Dangerous</span>)}
                                                            {item.inference === "severe pain" && (<span style={{ color: item.inference === "severe pain" ? 'rgb(8 99 0 / 74%)' : 'black' }}>Severe pain</span>)}
                                                            {item.inference === "without pain" && (<span style={{ color: item.inference === "without pain" ? 'rgb(8 99 0 / 74%)' : 'black' }}>Without pain</span>)}
                                                            {item.inference === "Moderate" && (<span style={{ color: item.inference === "Moderate" ? 'rgb(8 99 0 / 74%)' : 'black' }}>Moderate</span>)}
                                                            {item.inference === "Moderate Stress" && (<span style={{ color: item.inference === "Moderate Stress" ? 'rgb(8 99 0 / 74%)' : 'black' }}>Moderate Stress</span>)}
                                                            {item.inference === "moderate pain" && (<span style={{ color: item.inference === "moderate pain" ? 'rgb(8 99 0 / 74%)' : 'black' }}>Moderate pain</span>)}
                                                            {item.inference === "Severe Stress" && (<span style={{ color: item.inference === "Severe Stress" ? 'rgb(8 99 0 / 74%)' : 'black' }}>Severe Stress</span>)}

                                                        </p>
                                                    </div>
                                                );
                                        }
                                    })}

                                <>
                                    {/* <div className="wellnessReportScore">
        <div key={item.id} className="wellnessReportCategory">
        <p>{item?.parameter}</p>
        </div>
        <div className="wellnessReportCategoryAnswer">
        {componentToRender}
        </div>
        </div> */}
                                    {reportData.reports
                                        .filter(item => item.id !== "" && item.id !== "0") // Filter out items with id equal to "0"
                                        .map(item => (
                                            <div key={item.id}>
                                                <div className="wellnessReportScore">
                                                    <div className="wellnessReportCategory">
                                                        <p>{item.parameter}</p>
                                                    </div>
                                                    <div className="wellnessReportCategoryAnswer" style={{ color: getColorForInference(item.inference) }}>
                                                        {item.inference}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </>


                            </>
                        ) : (
                            <p>No data available or invalid data structure.</p>
                        )}
                        {/* <WellnessReportScore category="BMI" answer="Poor" />
                        <WellnessReportScore category="Menstrual Pain" answer="Fairly Low" />
                        <WellnessReportScore category="Physical Activity" answer="Moderate" />
                        <WellnessReportScore category="Sleep" answer="Normal" />
                        <WellnessReportScore category="Stress" answer="No Pain" /> */}
                    </div>

                    <div className="kywNextLinkWrapper" style={{ marginTop: '15px' }}>
                        <Link to="/wellness-plans/menstrual-wellbeing-op1">
                            <button id="kywNextButtonSmall"
                                onMouseEnter={() => setIsHovered2(true)}
                                onMouseLeave={() => setIsHovered2(false)}>
                                View Wellness Plan
                                {isHovered2 ? <img src={Assets.arrowUpRight} /> : <img src={Assets.arrowUpRightBlack} />}
                            </button>
                        </Link>
                        <button id="kywNextButtonSmall"
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            onClick={openCompleteReportPopup} >
                            View Your Complete Report
                            {isHovered ? <img alt="" src={Assets.arrowUpRight} /> : <img alt="" src={Assets.arrowUpRightBlack} />}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentFrameKyw24;