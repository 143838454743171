import { Assets } from "../Assets"

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const ContentFrameKyw12 = () => {

    const navigate = useNavigate();

    const [isHovered, setIsHovered] = useState(false);
    const [sliderValue, setSliderValue] = useState(1);
    const [sliderValue2, setSliderValue2] = useState(1);
    const [sliderValue3, setSliderValue3] = useState(1);

    const handleSliderChange = (event) => {
        const newValue = parseInt(event.target.value, 10);
        setSliderValue(newValue);
    };

    const handleSliderChange2 = (event) => {
        const newValue = parseInt(event.target.value, 10);
        setSliderValue2(newValue);
    };

    const handleSliderChange3 = (event) => {
        const newValue = parseInt(event.target.value, 10);
        setSliderValue3(newValue);
    };

    return (
        <div className="contentFrameKyw">
            <div className="contentFrameKywInner">
                <div className="kyw1BgImage position-relative">
                    <div className='absoluteStage'>
                        <p className="mb-0" style={{ marginBottom: '0px' }}>Step 5</p>
                        <p>Physical Activity Assessment</p>
                    </div>
                    <img src={Assets.kyw11Bg} alt="Physical Activity Assessment" />
                </div>
                <div className="vertProgressLine"></div>
                <div className="vertProgressLine2"></div>
                <div className="vertProgressLine3"></div>
                <div className="vertProgressLine4"></div>
                <div className="vertProgressLine5"></div>

                <div className="progressCirclesContainer">

                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> 1 </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg1" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>


                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> 2 </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg2" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>

                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> 3 </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg3" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>

                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> 4 </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg4" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>

                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> 5 </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg5" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleTwoThird" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>

                    <div className="progressCirclesOuter6">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 6 </div>
                        </div>
                    </div>

                    <div className="progressCirclesOuter7">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 7 </div>
                        </div>
                    </div>
                </div>



                <div className="kyw6MainContent">
                    <div className="kyw1MainContentHeading">
                        <h1>If your sleep is interrupted, how frequently do you take more than 30 minutes to fall back asleep?</h1>
                    </div>
                    <div className="kyw3MainContentItems">
                        <div className={`kywSlideContainer option-${sliderValue}`}>
                            <input
                                type="range"
                                className="painSlider"
                                name="slider"
                                min="0"
                                max="2"
                                value={sliderValue}
                                onChange={handleSliderChange}
                            />
                            <div className="sliderLabelContent">
                                <label htmlFor="sliderHeight">Not Much</label>
                                <label htmlFor="sliderHeight" style={{ marginLeft: "-25px" }}>Moderately</label>
                                <label htmlFor="sliderHeight">Fully</label>
                            </div>
                        </div>
                    </div>


                    <div className="kyw1MainContentHeading">
                        <h1>On an average, do you sleep between 6 to 8 hours per day?</h1>
                    </div>
                    <div className="kyw3MainContentItems">
                        <div className={`kywSlideContainer option-${sliderValue2}`}>
                            <input
                                type="range"
                                className="painSlider"
                                name="slider"
                                min="0"
                                max="2"
                                value={sliderValue2}
                                onChange={handleSliderChange2}
                            />
                            <div className="sliderLabelContent">
                                <label htmlFor="sliderHeight">Not Much</label>
                                <label htmlFor="sliderHeight" style={{ marginLeft: "-10px" }}>Moderately</label>
                                <label htmlFor="sliderHeight">Usually</label>
                            </div>
                        </div>
                    </div>



                    <div className="kywNextLinkWrapper">
                        <Link className="backLink" to="" onClick={() => {
                            navigate(-1);
                        }}>
                            <img src={Assets.backArrow} alt="Back" />
                            Back
                        </Link>
                        <Link to="/selfprognosis/know-your-wellness/sleep-assessment-result">
                            <button id="kywNextButtonSmall"
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                Next
                                {isHovered ? <img src={Assets.arrowUpRight} /> : <img src={Assets.arrowUpRightBlack} />}
                            </button>
                        </Link>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ContentFrameKyw12;