import { Assets } from '../Assets';

import 'react-phone-number-input/style.css'
//import { useData } from '../../DataContext';
import { useData } from '../Context/AppContextProvider';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'

import ConfettiExplosion from 'react-confetti-explosion';

const SelectedPlansWEllnessPlansCheckoutLoginSuccess = () => {

    const { sharedData } = useData();

    const convertNumberToString = (number) => {
        switch (number) {
            case 3:
                return 'Silver';
            case 4:
                return 'Gold';
            case 5:
                return 'Platinum';
            default:
                alert("Please select a wellness plan")
                return 'No';
        }
    };

    const [isExploding, setIsExploding] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setIsExploding(false);
        }, 3000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <div className="selectedPlanWrapper">
            <div className="plansAndLoginWrapper">
                <div className="selectedPlansPriceList">
                    <div className="checkoutPriceListWrapper">
                        <Link to="/wellness-plans/checkout">
                            <div className="checkoutSilverPlan">
                                <div className="checkoutPlanHeading" id="silverPlanHeading">
                                    <p className="planNameHeading">Silver Plan</p>
                                    <div className="wellnessSilverPlanHeadingPrice">
                                        <b><p>&#8377;8,999</p></b>
                                        <strike><p> 9,999</p></strike>
                                    </div>
                                </div>
                                <div className="checkoutPlanListContent">
                                    <ul>
                                        <li>Monthly Lifestyle and Menstrual Assessment</li>
                                        <li>Weekly Diet & Fitness Plans</li>
                                        <li>Daily Lifestyle Tips</li>
                                        <li>Unlimited Chat Support</li>
                                        <li>Fortnightly Consultation with Ayurvedic Doctor / Dietician</li>
                                        <li>Weekly Expert Workshops</li>
                                        <li>Free Product Voucher Worth of ₹900</li>
                                        <li>User Progress Report at the end of plan</li>
                                    </ul>
                                </div>
                            </div>
                        </Link>

                        <Link to="/wellness-plans/checkout">
                            <div className="checkoutGoldPlan">
                                <div className="checkoutPlanHeading" id="goldPlanHeading">
                                    <p className="planNameHeading">Gold Plan</p>
                                    <div className="wellnessSilverPlanHeadingPrice">
                                        <b><p>&#8377;13,499</p></b>
                                        <strike><p> 14,999</p></strike>
                                    </div>
                                </div>
                                <div className="checkoutPlanListContent">
                                    <ul>
                                        <li>Monthly Lifestyle and Menstrual Assessment</li>
                                        <li>Weekly Diet & Fitness Plans</li>
                                        <li>Daily Lifestyle Tips</li>
                                        <li>Unlimited Chat Support</li>
                                        <li>Fortnightly Consultation with Ayurvedic Doctor / Dietician</li>
                                        <li>Weekly Expert Workshops</li>
                                        <li>Free Product Voucher Worth of ₹1800</li>
                                        <li>User Progress Report at the end of plan</li>
                                    </ul>
                                </div>

                            </div>
                        </Link>

                        <Link to="/wellness-plans/checkout">
                            <div className="checkoutPlatinumPlan">
                                <div className="checkoutPlanHeading" id="platinumPlanHeading">
                                    <p className="planNameHeading">Platinum Plan</p>
                                    <div className="wellnessSilverPlanHeadingPrice">
                                        <b><p>&#8377;21,599</p></b>
                                        <strike><p> 23,999</p></strike>
                                    </div>
                                </div>
                                <div className="checkoutPlanListContent">
                                    <ul>
                                        <li>Monthly Lifestyle and Menstrual Assessment</li>
                                        <li>Weekly Diet & Fitness Plans</li>
                                        <li>Daily Lifestyle Tips</li>
                                        <li>Unlimited Chat Support</li>
                                        <li>Fortnightly Consultation with Ayurvedic Doctor / Dietician</li>
                                        <li>Weekly Expert Workshops</li>
                                        <li>Free Product Voucher Worth of ₹3600</li>
                                        <li>User Progress Report at the end of plan</li>
                                    </ul>
                                </div>

                            </div>
                        </Link>

                    </div>
                </div>

                <div className="checkoutLogin">
                    <div className="checkoutLoginWrapper">
                        {isExploding && (
                            <ConfettiExplosion
                                force={0.3}
                                colors={['#D27060', '#D270601A', '#D2706064']}
                            />
                        )}
                        <div className="checkoutLoginHeading">
                            Checkout
                        </div>
                        <div className="checkoutLoginHeading2">
                            <b>{convertNumberToString(sharedData)}</b> Plan Selected
                        </div>
                        <div className="checkoutLoginSuccessContent">
                            <div className="successPartyImg">
                                <img src={Assets.successPartyImg} alt="Success" />
                            </div>
                            <div className="successCheckoutHeading">
                                Your Subscription Plan Is Successful!
                            </div>
                            <div className="successCheckoutArticle">
                                You will receive your booking details on your registered email shortly. You will receive a call from your dedicated counsellor to take it forward<br /><br />Use the registered mobile number +91 XXXXXXXXX to login in to the app
                            </div>
                            <div className="successCheckoutEndImage">
                                <img src={Assets.successCheckoutEndImage} alt="Download our app" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="complianceContentCheckout">
                <div className="complianceContentWrapper">
                    <div className="complianceContentHeading">
                        <div className="complianceContentHeading1">
                            Safe and Secure
                        </div>
                        <div className="complianceContentHeading2">
                            We are compliant to <b>Industry standards.</b>
                        </div>
                    </div>
                    <div className="complianceContentImage">
                        <img src={Assets.complianceContentBanner} alt="Industry Standards" />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SelectedPlansWEllnessPlansCheckoutLoginSuccess;