import React, { useEffect, useState, useRef } from "react";
import { Assets } from "../Assets"
//import { useState } from 'react';
import { Link } from 'react-router-dom';
import PopupLogin from '../LoginPopup/PopupLogin'
import PopupLoginOTP from '../LoginPopup/PopupLoginOTP'
import PopupSignup from "../SignupPopup/PopupSignup";
import SuccessPopup from "../SignupPopup/SuccessPopup";
import { PostAPI } from "../utility/APiCall";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import useCookie from "../cookies/Cookie";
import Cookies from 'js-cookie';
import { useName } from "../Context/AppContextProvider";

const ContentFrameKyw1 = () => {

  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);
  //const userName = sessionStorage.getItem('user_name');

  const [isOpen, setIsOpen] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showSignUpPopup, setShowSignUpPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showLoginOTPPopup, setShowOTPPopup] = useState(false);
  const [isOTPReceived, setIsOTPReceived] = useState(false);
  const [isOTPEntered, setIsOTPEntered] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
    setShowSuccessPopup(false);
  };

  const openLoginPopup = () => {
    setShowLoginPopup(true);
    closeLoginOTPPopup();
    setShowSignUpPopup(false);
  };

  const openSuccessPopup = () => {

  }

  const closeSuccessPopup = () => {
    setShowSuccessPopup(false);
  }

  const closeLoginPopup = () => {
    setShowLoginPopup(false);
  };

  const openSignUpPopup = () => {
    setShowSignUpPopup(true);
    setShowLoginPopup(false);
  };

  const closeSignUpPopup = () => {
    setShowSignUpPopup(false);
    setShowSignUpPopup(true);
  };

  const openLoginOTPPopup = () => {
    setShowOTPPopup(true);
    closeLoginPopup();
  };

  const closeLoginOTPPopup = () => {
    setShowOTPPopup(false);
  };

  window.onscroll = () => { scrollFunction() };

  // function scrollFunction () {
  //     if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
  //         document.getElementById("laiqaNavbar").style.padding = "0 0";
  //         document.getElementById("laiqaNavbar").style.borderRadius = "0 0 10px 10px";
  //         document.getElementById("laiqaNavbar").style.backgroundColor = "#ffffff";
  //         document.getElementById("id_laiqaLogo").style.transform = "scale(0.7)";
  //         document.getElementById("id_laiqaLogo").style.transition = "transform 0.2s";
  //     } else {
  //         document.getElementById("laiqaNavbar").style.padding = "36px 0";
  //         document.getElementById("laiqaNavbar").style.borderRadius = "0 0 40px 40px";
  //         document.getElementById("laiqaNavbar").style.backgroundColor = "#ffffffcc";
  //         document.getElementById("id_laiqaLogo").style.transform = "scale(1)";
  //         document.getElementById("id_laiqaLogo").style.transition = "transform 0.2s";
  //     }
  // }

  function scrollFunction() {
    const navbar = document.getElementById("laiqaNavbar");
    const logo = document.getElementById("id_laiqaLogo");

    if (!navbar || !logo) {
      console.error("Elements with IDs 'laiqaNavbar' or 'id_laiqaLogo' not found");
      return;
    }

    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      navbar.style.padding = "0 0";
      navbar.style.borderRadius = "0 0 10px 10px";
      navbar.style.backgroundColor = "#ffffff";
      logo.style.transform = "scale(0.7)";
      logo.style.transition = "transform 0.2s";
    } else {
      navbar.style.padding = "36px 0";
      navbar.style.borderRadius = "0 0 40px 40px";
      navbar.style.backgroundColor = "#ffffffcc";
      logo.style.transform = "scale(1)";
      logo.style.transition = "transform 0.2s";
    }
  }

  const [isMobileConfirmed, setIsMobileConfirmed] = useState(false);
  const [data, setData] = useState({
    phone: '',
  });

  // const [userName, setUserName] = useCookie('user_name', '');
  // const [userId, setUserId] = useCookie('getUID', '');
  // const [NewuserId, setNewUserId] = useCookie('new_user', '');

  const [getUID, setGetUID] = useState();
  const { userName, userId, setUserName, setUserId, getJID, isJourneyFilled } = useName();
  const [journeyMessage, setJourneyMessage] = useState('');
  // const [userName, setUserName] = useCookie('user_name', '');
  // const [userId, setUserId] = useCookie('getUID', '');
  // const { userName, userId, setUserName, setUserId } = useName();
  const [NewuserId, setNewUserId] = useCookie('new_user', '');
  // console.log('getUID',getUID);

  // sessionStorage.setItem('getUID',getUID);

  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [isFormVisible, setFormVisible] = useState(false);
  const [isOtpComplete, setIsOtpComplete] = useState(false);
  const otpInputs = Array.from({ length: 6 }, (_, index) => index);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const inputRefs = otpInputs.map(() => useRef(null));


  useEffect(() => {
    if (isFormVisible) {
      inputRefs[0].current.focus();
    }
  }, [isFormVisible]);

  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value !== '' && index < otpInputs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
    const isComplete = newOtp.every((char) => char !== '');
    setIsOtpComplete(isComplete);
  };

  const handleBackspace = (index) => {
    if (otp[index] === '') {
      if (index > 0) {
        inputRefs[index - 1].current.focus();
      }
    }
    const newOtp = [...otp];
    newOtp[index] = '';
    setOtp(newOtp);

    const isComplete = newOtp.every((char) => char !== '');
    setIsOtpComplete(isComplete);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      if (!isMobileConfirmed) {
        // If mobile is not confirmed yet, call "confirm_mobile" API
        const confirmMobileData = new FormData();
        //confirmMobileData.append('user_id', data?.user_id);
        confirmMobileData.append('phone', data?.phone);
        console.log('confirmMobileData:', confirmMobileData);
        sessionStorage.setItem('phone', data?.phone);
        PostAPI({ url: '/web/trigger_sign_up_otp', data: confirmMobileData }, (confirmMobileResponse) => {
          //console.log('confirmMobileResponse:', confirmMobileResponse);
          //toast.success(confirmMobileResponse.message);
          //alert('success');
          if (confirmMobileResponse.status == 1) {
            openLoginOTPPopup(true);
            const getuser_id = confirmMobileResponse?.data?.user_id;
            const new_user = confirmMobileResponse?.data?.new_user;
            sessionStorage.setItem('getUID', getuser_id);
            setUserId(getuser_id);
            setGetUID(getuser_id);
            setNewUserId(new_user);
            // Set isOTPReceived to true when OTP is sent
            setIsOTPReceived(true);
            toast.success('OTP sent to your mobile number');
          } else {
            // Handle failure
            if (confirmMobileResponse.status == 0) {
              toast.error(confirmMobileResponse.message);
              alert(confirmMobileResponse.message);
              //toast.success('User Already Logged In. Please Log Out First');
              console.log('failure:', confirmMobileResponse.message);
            }

            // alert('');
            // console.log('failure:', confirmMobileResponse.message);
          }
        });
      }
    }
    //resetForm();
  };
  useEffect(() => {
    // const getUID = sessionStorage.getItem('getUID');
    // console.log('getUID:', getUID);
  }, []);

  const handleFormSubmitotp = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      // If mobile is confirmed, call "verify_confirm_mobile" API

      const verifyData = new FormData();
      verifyData.append('user_id', getUID);
      verifyData.append('phone', data?.phone);
      verifyData.append('otp', otp.join(''));

      PostAPI({ url: '/web/verify_web_login', data: verifyData }, (verifyResponse) => {
        console.log('verifyResponse:', verifyResponse);
        if (verifyResponse.status === 1) {
          if (NewuserId == 0) {
            toast.success(verifyResponse.message);
            const user_name = verifyResponse?.data?.user_name;
            const user_id = verifyResponse?.data?.id;
            sessionStorage.setItem('user_name', user_name);
            sessionStorage.setItem('getUID', user_id);
            setUserName(user_name);
            setUserId(user_id);
            toast.success(verifyResponse?.message);
            closeLoginOTPPopup();
          } else if (NewuserId == 1) {
            // openLoginOTPPopup(false);
            setShowSignUpPopup(true);
            closeLoginOTPPopup();
          } else {
            alert('check backend');
          }
        } else {
          toast.error(verifyResponse?.message);
        }
      });

      setIsOTPEntered(true);
      // Set isOTPEntered to true when OTP is entered
    }
    //resetForm();
    // else if(){
    //   setShowSignUpPopup(true);
    // } 

  };
  const phone = sessionStorage.getItem('phone');
  const [dataSignup, setDtatSignUp] = useState({
    user_name: '',
    user_phone: phone,
    user_email: '',
    user_dob: '',
  });

  const handleFormSubmitSignup = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      // If mobile is confirmed, call "verify_confirm_mobile" API
      const verifyData = new FormData();
      // verifyData.append('user_id', getUID);
      //const phone = sessionStorage.getItem('phone');
      verifyData.append('user_id', getUID);
      verifyData.append('user_name', dataSignup?.user_name);
      //verifyData.append('user_phone', phone);
      verifyData.append('user_phone', data?.phone);
      verifyData.append('user_email', dataSignup?.user_email);
      verifyData.append('user_dob', dataSignup?.user_dob);

      PostAPI({ url: '/web/new_user_sign_up', data: verifyData }, (verifyResponse) => {
        console.log('signupverifyResponse:', verifyResponse);
        if (verifyResponse.status == 1) {
          toast.success(verifyResponse.message);
          setShowSignUpPopup(false);
          setShowSuccessPopup(true);
          setUserName(dataSignup?.user_name);
        } else {
          toast.error(verifyResponse?.message);
        }
      });
    }
    //resetForm();
  };


  //  const userName = sessionStorage.getItem('user_name');
  //  const getuser = sessionStorage.getItem('getUID');

  //const navigate = useNavigate();

  //  const getToken = sessionStorage.getItem("sessionToken");
  //   const UserId = sessionStorage.getItem('getUID');

  //   const [values, setValues] = useState({
  //     user_id:getUID,
  //     accessToken:getToken,
  //   });

  function handleLogOut() {
    //  alert('check')
    //const getUID = sessionStorage.getItem('getUID');
    //const getUID = sessionStorage.getItem('getUID');
    const getUID = Cookies.get('getUID');
    const url = '/web/user_web_logout';
    const formData = new FormData();
    formData.append('user_id', getUID);
    //formData.append('accessToken', values?.accessToken);
    const params = { url, data: formData };
    //sessionStorage.clear();
    PostAPI(params, (res) => {
      // Assuming 'token' is the authentication token received from the server
      //console.log('token', getToken);
      // toast.success(res?.message);
      //sessionStorage.clear();
      if (res.status == 1) {
        toast.success(res?.message);
        //sessionStorage.setItem('sessionToken', getToken);
        sessionStorage.clear();
        // Clear the cookies
        Cookies.remove('user_name');
        Cookies.remove('getUID');
        Cookies.remove('new_user');
        setTimeout(() => {
          navigate('/');
          // Reload the page
          window.location.reload();
        }, 2000);
      } else {
        toast.error(res?.message);
      }
    });
    //sessionStorage.setItem("sessionToken", getToken);

  }
  const scrollToTop = () => {
    // setLoading(true);
    // setTimeout(() => {
    // setLoading(false);
    // }, 2000);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  window.onscroll = () => { scrollFunction() };
  //   function scrollFunction () {
  //     if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
  //         document.getElementById("laiqaNavbar").style.padding = "0 0";
  //         document.getElementById("laiqaNavbar").style.borderRadius = "0 0 10px 10px";
  //         document.getElementById("laiqaNavbar").style.backgroundColor = "#ffffff";
  //         document.getElementById("id_laiqaLogo").style.transform = "scale(0.7)";
  //         document.getElementById("id_laiqaLogo").style.transition = "transform 0.2s";
  //     } else {
  //         document.getElementById("laiqaNavbar").style.padding = "36px 0";
  //         document.getElementById("laiqaNavbar").style.borderRadius = "0 0 40px 40px";
  //         document.getElementById("laiqaNavbar").style.backgroundColor = "#ffffffcc";
  //         document.getElementById("id_laiqaLogo").style.transform = "scale(1)";
  //         document.getElementById("id_laiqaLogo").style.transition = "transform 0.2s";
  //     }
  // }

  function scrollFunction() {
    const navbar = document.getElementById("laiqaNavbar");
    const logo = document.getElementById("id_laiqaLogo");

    if (!navbar || !logo) {
      console.error("Elements with IDs 'laiqaNavbar' or 'id_laiqaLogo' not found");
      return;
    }

    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      navbar.style.padding = "0 0";
      navbar.style.borderRadius = "0 0 10px 10px";
      navbar.style.backgroundColor = "#ffffff";
      logo.style.transform = "scale(0.7)";
      logo.style.transition = "transform 0.2s";
    } else {
      navbar.style.padding = "36px 0";
      navbar.style.borderRadius = "0 0 40px 40px";
      navbar.style.backgroundColor = "#ffffffcc";
      logo.style.transform = "scale(1)";
      logo.style.transition = "transform 0.2s";
    }
  }

  const journey_id = sessionStorage.getItem('journey_id');

  const handleNextButtonClick = () => {
    navigate('/selfprognosis/know-your-wellness/start-journey');
    // if (journey_id) {
    //   setJourneyMessage('You have already filled this month’s category');
    //   alert('You have already filled this month’s category');
    // } else {
    //   // Navigate to the next step
    //   // You might use a router's history.push or any navigation logic here
    //   //window.location.href = '/selfprognosis/know-your-wellness/start-journey';
    //   navigate('/selfprognosis/know-your-wellness/start-journey');
    // }
  };

  return (
    <div className="contentFrameKyw">
      <SuccessPopup
        show={showSuccessPopup} handleClose={closeSuccessPopup} openSuccessPopup={openSuccessPopup}
      />
      <PopupLogin
        handleFormSubmit={handleFormSubmit}
        getUID={getUID}
        setGetUID={setGetUID}
        // manuallySetOTP={manuallySetOTP}
        data={data}
        setData={setData}
        isMobileConfirmed={isMobileConfirmed}
        setIsMobileConfirmed={setIsMobileConfirmed}
        show={showLoginPopup} handleClose={closeLoginPopup} openLoginOTPPopup={openLoginOTPPopup} />
      <PopupLoginOTP
        handleFormSubmitotp={handleFormSubmitotp}
        getUID={getUID}
        setGetUID={setGetUID}
        data={data}
        setData={setData}
        isFormVisible={isFormVisible}
        setFormVisible={setFormVisible}
        otp={otp}
        setOtp={setOtp}
        isOtpComplete={isOtpComplete}
        setIsOtpComplete={setIsOtpComplete}
        inputRefs={inputRefs}
        otpInputs={otpInputs}
        handleBackspace={handleBackspace}
        handleChange={handleChange}
        show={showLoginOTPPopup} handleClose={closeLoginOTPPopup} />
      <div className="contentFrameKywInner">
        <div className="kyw1BgImage  position-relative">
          <div className='absoluteStage'>
            <p className="mb-0" style={{ marginBottom: '0px' }}>Step 1</p>
            <p>KYW Intro</p>
          </div>
          <img src={Assets.kyw1BgImage} alt="Start your Journey" />
          <div className='absolute'>
            <h4>What is wellness?</h4>
            <p>Wellness means feeling good and healthy in your body and mind. It's about taking care of yourself by eating well, staying active, and managing stress. When you're well, you have energy and a positive outlook on life.</p>
            <h4>How LAIQA wellness helps?</h4>
            <p>LAIQA wellness allows you address your health concerns. It provides you a customized plan to help you manage your general wellbeing and achieve ultimate physical and mental health.</p>
          </div>
        </div>
        <div className="vertProgressLine"></div>
        <div className="progressCirclesContainer">
          <div className="progressCirclesOuter1">
            <div className="currentProgressCircleInner">
              <div className="progressCirclesId"> 1 </div>
            </div>
          </div>
          <svg id="progressCircleSvg1" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
            {/* <circle className="progressCircleHalf" cx="41" cy="34.5" r="32" strokeLinecap="round" /> */}
            <circle className="progressCircleOneForth" cx="41" cy="34.5" r="32" strokeLinecap="round" />
          </svg>
          <div className="progressCirclesOuter2">
            <div className="progressCirclesInner">
              <div className="progressCirclesId"> 2 </div>
            </div>
          </div>
          <div className="progressCirclesOuter3">
            <div className="progressCirclesInner">
              <div className="progressCirclesId"> 3 </div>
            </div>
          </div>
          <div className="progressCirclesOuter4">
            <div className="progressCirclesInner">
              <div className="progressCirclesId"> 4 </div>
            </div>
          </div>
          <div className="progressCirclesOuter5">
            <div className="progressCirclesInner">
              <div className="progressCirclesId"> 5 </div>
            </div>
          </div>
          <div className="progressCirclesOuter6">
            <div className="progressCirclesInner">
              <div className="progressCirclesId"> 6 </div>
            </div>
          </div>
          <div className="progressCirclesOuter7">
            <div className="progressCirclesInner">
              <div className="progressCirclesId"> 7 </div>
            </div>
          </div>
        </div>
        <div className="kyw1MainContent">
          <div className="kyw1MainContentHeading">
            <h1>Why should you choose LAIQA?</h1>
            {journeyMessage && <p>{journeyMessage}</p>}
          </div>
          <div className="kyw1MainContentListItems">
            <img src={Assets.kywListImages} alt="Reasons to choose LAIQA" />
          </div>
          {userId ? (
            <>
              {/* <Link to="/selfprognosis/know-your-wellness/start-journey"> */}

              <button id="kywNextButton"
                onClick={handleNextButtonClick}
                className={`${isHovered ? 'bookNowTeleconsButtonHovered' : ''}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                Next
                {isHovered ? <img alt='' src={Assets.arrowUpRight} /> : <img alt='' src={Assets.arrowUpRightBlack} />}
              </button>

              {/* </Link> */}
            </>
          ) : (
            <button
              onClick={openLoginPopup}
              id="kywNextButton"
              className={`${isHovered ? 'bookNowTeleconsButtonHovered' : ''}`}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)} >
              Next
              {isHovered ? <img alt='' src={Assets.arrowUpRight} /> : <img alt='' src={Assets.arrowUpRightBlack} />}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default ContentFrameKyw1;