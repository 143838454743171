import { Assets } from '../Assets';

import { Link } from 'react-router-dom';
import { useState } from 'react';

const MainContentStorePage = () => {

    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);

    return (
        <div className="laiqaMainContentBg store">
            <div className="laiqaMainContentWrapper">
                <div className="laiqaMainContentMidLayer">
                    <img alt="" src={Assets.storeMainContentBanner} id="wellnessPlansBanner1" />
                    {/* <img alt='' src={ Assets.laiqaMainContentMidLayerResp } id="wellnessPlansBanner2" /> */}
                </div>
                <div className="laiqaMainContentButtons">
                    <Link style={{ listStyle: 'none', textDecoration: 'none' }} to="https://www.mylaiqa.com">
                        <button id="midLayerButton"
                            className={`${isHovered ? 'bookNowTeleconsButtonHovered' : ''}`}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            Shop Now
                            {isHovered ? <img src={Assets.arrowUpRight} /> : <img src={Assets.arrowUpRightBlack} />}
                        </button>
                    </Link>
                    <div className="scheduleCallMainContent">
                        <div className='scheduleCallButtonWrapper'>
                            <Link to="/teleconsultation/select-specialist" style={{ textDecoration: "none" }}>
                                <button id="scheduleCallButtonHomePage"
                                    className={`${isHovered2 ? 'bookNowTeleconsButtonHovered' : ''}`}
                                    onMouseEnter={() => setIsHovered2(true)}
                                    onMouseLeave={() => setIsHovered2(false)}
                                >
                                    {isHovered2 ? <img src={Assets.phoneCallingWhite} /> : <img src={Assets.phoneCalling} />}
                                </button>
                            </Link>
                        </div>
                        <div className="scheduleCallContent">
                            <p className="scheduleCallp1">Schedule Call</p>
                            <p className="scheduleCallp2">(+91) 987 654 3210</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="breadcrumb">
                <div className="breadcrumbWrapper">
                    <div className="storeBreadcrumb">
                        <ul>
                            <li><Link to="/store">Home </Link></li>
                            <li id="selectedBreadCrumb"><Link to="/store"> • Store</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainContentStorePage;