import React,{useState, useEffect} from "react";
import { Assets } from "./Assets";
import { useNavigate } from "react-router-dom";
import NavbarKyw from "./Home/NavbarHomePage"
import MainContentKyw from "./Kyw1/MainContentKyw1";
import ContentFrameKyw9 from "./Kyw9/ContentFrameKyw9";
import FooterKyw from "./Kyw1/FooterKyw";
// import NavMenu from "./navbar/NavMenu";
import useCookie from "./cookies/Cookie";
import Cookies from 'js-cookie';
import NavbarHomePage from "./Home/NavbarHomePage";
import { PostAPI, GetAPI } from "./utility/APiCall";

const KnowYourWellness7 = () => {
    const navigate = useNavigate();

    const [questionId, setQuestionId] = useState()

    const getCatId = sessionStorage.getItem('getActivityID');
    console.log('getActivityID', getCatId);

    const [category, setCategory] = useState({
        category_id:getCatId,
    });


    const get_questionnaires = (e) => {
         const categoryData = new FormData();
         const getCatId = sessionStorage.getItem('getActivityID');
         categoryData.append('category_id', getCatId);
 
        // navigate('/self-prognosis/know-your-wellness/bmi-result');
 
         PostAPI({ url: '/get_onboarding_questionnaires', data: categoryData }, (res) => {
            // alert('check')
             console.log('Questions', res?.data);
             const question_id = res?.data;
             console.log('question_id',question_id);
             setQuestionId(question_id);
         });
     }

useEffect(()=>{
    get_questionnaires();
},[])

const [getPlan, setGetPlan] = useState();
//const [journeyId, setJourneyId] =useState();
const [planDataId, setplanDataId] =useState();
const [month, setMonth] = useState();

console.log('getPlangetPlan', getPlan);

    const getPlandetails = () => {

        const getUID = sessionStorage.getItem('getUID')
        const userId = getUID;
        const url = `/get_user_plan_details/${userId}`;

        GetAPI(url, (res)=>{

                    const plan_data_id = res?.data?.plan_data_id;
                    if (plan_data_id == null || plan_data_id == 0){
                    setplanDataId(0);
                    } else {
                    setplanDataId(plan_data_id);
                    }

                    // const pre_journey_id = res?.data?.pre_journey_id;
                    // if (pre_journey_id == null || pre_journey_id == 0){
                    // setJourneyId(0);
                    // } else {
                    // setJourneyId(pre_journey_id);
                    // }

                    const current_month = res?.data?.plan_data_id;
                    if (current_month == null || current_month == 0){
                    setMonth(0);
                    } else {
                    setMonth(current_month);
                    }
                const result = res?.data;
                setGetPlan(result);

        });

    }

    useEffect(()=>{
        getPlandetails();
    }, [])

const journey_id = sessionStorage.getItem('journey_id')


    const [sliderValue, setSliderValue] = useState(0);
    const [inputValue, setInputValue] = useState({ hours: 0, minutes: 0 });
    

    const [sliderValue2, setSliderValue2] = useState(0);
    const [inputValue2, setInputValue2] = useState({ hours: 0, minutes: 0 });

    const [sliderValue3, setSliderValue3] = useState(0);
    const [inputValue3, setInputValue3] = useState({ hours: 0, minutes: 0 });

    const [sliderValue4, setSliderValue4] = useState(0);
    const [inputValue4, setInputValue4] = useState({ hours: 0, minutes: 0 });

    const minutesInHour = 60;

    const handleSliderChange = (event) => {
        // const newValue = parseInt(event.target.value, 10);
            const newValue = parseFloat(event.target.value);
            setSliderValue(newValue);
            const hours = Math.floor(newValue);
            const minutes = Math.round((newValue - hours) * 60);

            setInputValue({ hours, minutes });
        // if (!isNaN(newValue)) {
        //     setSliderValue(newValue);
        //     setInputValue(newValue);
        // }

    };

    const handleSliderChange2 = (event) => {
        // const newValue = parseInt(event.target.value, 10);
        // if (!isNaN(newValue)) {
        //     setSliderValue2(newValue);
        //     setInputValue2(newValue);
        // }
        const newValue = parseFloat(event.target.value);
        setSliderValue2(newValue);
        const hours = Math.floor(newValue);
        const minutes = Math.round((newValue - hours) * 60);

        setInputValue2({ hours, minutes });
    };

    const handleSliderChange3 = (event) => {
        // const newValue = parseInt(event.target.value, 10);
        // if (!isNaN(newValue)) {
        //     setSliderValue3(newValue);
        //     setInputValue3(newValue);
        // }
        const newValue = parseFloat(event.target.value);
        setSliderValue3(newValue);
        const hours = Math.floor(newValue);
        const minutes = Math.round((newValue - hours) * 60);

        setInputValue3({ hours, minutes });
    };

    const handleSliderChange4 = (event) => {
        // const newValue = parseInt(event.target.value, 10);
        // if (!isNaN(newValue)) {
        //     setSliderValue4(newValue);
        //     setInputValue4(newValue);
        // }
        const newValue = parseFloat(event.target.value);
        setSliderValue4(newValue);
        const hours = Math.floor(newValue);
        const minutes = Math.round((newValue - hours) * 60);

        setInputValue4({ hours, minutes });
    };

    const handleInputChange = (event) => {
        const newValue = parseInt(event.target.value, 10);
        if (!isNaN(newValue)) {
            setSliderValue(newValue);
            setInputValue(newValue);
        } else {
            setSliderValue(0);
            setInputValue('0');
        }
    };

    const handleInputChange2 = (event) => {
        const newValue = parseInt(event.target.value, 10);
        if (!isNaN(newValue)) {
            setSliderValue2(newValue);
            setInputValue2(newValue);
        } else {
            setSliderValue2(0);
            setInputValue2('0');
        }
    };

    const handleInputChange3 = (event) => {
        const newValue = parseInt(event.target.value, 10);
        if (!isNaN(newValue)) {
            setSliderValue3(newValue);
            setInputValue3(newValue);
        } else {
            setSliderValue3(0);
            setInputValue3('0');
        }
    };

    const handleInputChange4 = (event) => {
        const newValue = parseInt(event.target.value, 10);
        if (!isNaN(newValue)) {
            setSliderValue4(newValue);
            setInputValue4(newValue);
        } else {
            setSliderValue4(0);
            setInputValue4('0');
        }
    };


const [data, setData] = useState({
    user_id: '',
    category_id: getCatId,
    // activities: {
    //   active: { inputValue: '', sliderValue: 0 },
    //   moderately: { inputValue: '', sliderValue: 0 },
    //   low: { inputValue: '', sliderValue: 0 },
    //   sedentary: { inputValue: '', sliderValue: 0 },
    // },
    answer:[],
    journey_id: journey_id,
    plan_data_id: '0',
    month: '0',
  });

  const [questID, setQuestID] = useState([]);
  const [getSleepData, setSleepData] = useState();
  const [message, setMessage ] = useState()
  const [getSleepscaleData, setSleepscaleData] = useState([]);

const handleFormSubmit = (e) => {


    const combinedValue = `${inputValue?.hours || 0}:${inputValue?.minutes || 0}`;
    const combinedValue2 = `${inputValue2?.hours || 0}:${inputValue2?.minutes || 0}`;
    const combinedValue3 = `${inputValue3?.hours || 0}:${inputValue3?.minutes || 0}`;
    const combinedValue4 = `${inputValue4?.hours || 0}:${inputValue4?.minutes || 0}`;

    const combinedValueArray = [
        combinedValue, combinedValue2, combinedValue3, combinedValue4
    ]

    sessionStorage.setItem('question5', combinedValue);
    sessionStorage.setItem('question6', combinedValue2);
    sessionStorage.setItem('question7', combinedValue3);
    sessionStorage.setItem('question8', combinedValue4);

    // console.log('combinedValue',combinedValue);

    sessionStorage.setItem('question5', combinedValue);
    sessionStorage.setItem('question6',combinedValue2);
    sessionStorage.setItem('question7',combinedValue3);
    sessionStorage.setItem('question8',combinedValue4);

    // sessionStorage.setItem('question5', inputValue?.hours ? inputValue.minutes : inputValue.hours);
    // sessionStorage.setItem('question6',inputValue2?.hours ? inputValue2.minutes : inputValue2.hours);
    // sessionStorage.setItem('question7',inputValue3?.hours ? inputValue3.minutes : inputValue3.hours);
    // sessionStorage.setItem('question8',inputValue4?.hours ? inputValue4.minutes : inputValue4.hours);

    const dayAnswers  = [
        parseInt(sessionStorage.getItem('question1'), 10) || 0,
        parseInt(sessionStorage.getItem('question2'), 10) || 0,
        parseInt(sessionStorage.getItem('question3'), 10) || 0,
        parseInt(sessionStorage.getItem('question4'), 10) || 0,
    ];

    const hourMinuteAnswers = [
        parseInt(sessionStorage.getItem('question5'), 10) || 0,
        parseInt(sessionStorage.getItem('question6'), 10) || 0,
        parseInt(sessionStorage.getItem('question7'), 10) || 0,
        parseInt(sessionStorage.getItem('question8'), 10) || 0,
    ];


    const [storedHours, storedMinutes] = combinedValue.split(':').map(Number);
    const [storedHours2, storedMinutes2] = combinedValue2.split(':').map(Number);
    const [storedHours3, storedMinutes3] = combinedValue3.split(':').map(Number);
    const [storedHours4, storedMinutes4] = combinedValue4.split(':').map(Number);
    console.log('combinedValueArray', combinedValueArray);

    const questionData = new FormData();

    //const defaultAnswers = [98, 99, 100, 101];

    const defaultAnswers = [98, 99, 100, 101];

    const mapDayAnswers = (defaultAnswers, dayValues) => {
        return defaultAnswers.map((dayValue, index) => ({
          answer: dayValue,
          day: dayValues[index],
        }));
      };
      
    const selectedDayAnswers = mapDayAnswers(defaultAnswers, dayAnswers);
      
    const mapHourMinuteAnswers = (defaultAnswers, hourMinuteValues, storedHours, storedMinutes) => {
        return defaultAnswers.map(hourMinuteValue => ({
          answer: hourMinuteValue,
        //   hours:storedHours, 
        //   mins:storedMinutes,
          hours: hourMinuteValues[defaultAnswers.indexOf(hourMinuteValue)] % 60, //Math.floor(hourMinuteValues[defaultAnswers.indexOf(hourMinuteValue)] / 60),
          mins: '',
        }));
      };

      
    const selectedHourMinuteAnswers = mapHourMinuteAnswers(defaultAnswers, hourMinuteAnswers);

    console.log('',selectedHourMinuteAnswers)

    const dayAnswer = {
        question_id: "55",
        answer: selectedDayAnswers,
      };
      
    const hourMinuteAnswer = {
        question_id: "56",
        answer: selectedHourMinuteAnswers,
    };
    const getCatId = sessionStorage.getItem('getActivityID');
    const answer = [dayAnswer, hourMinuteAnswer];
    const getUID = sessionStorage.getItem('getUID')
    const userId = getUID;
    questionData.append('user_id', userId);
    questionData.append('category_id', getCatId);
    //questionData.append('answer', updatedData);
    questionData.append('answer', JSON.stringify(answer)); // Convert to JSON string
    questionData.append('journey_id', journey_id);
    questionData.append('plan_data_id', planDataId);
    questionData.append('month', month);
    
    console.log('FormData content:', questionData);

    PostAPI({ url: '/add_onboarding_questionnaires_data', data: questionData }, (res) => {
        console.log('Questions 55', res?.data);
        if(res?.status == 1 ){

            setSleepData(res?.data);
            const sleep_scale = res?.scale;
            console.log('scale', sleep_scale);
            setSleepscaleData(sleep_scale);
          //  const journey_id = res?.journey_id;
            const sleep_score = res?.score;
            setSleepData(sleep_score);
            const activity_scale = res?.scale;
            console.log('scale', activity_scale);
setQuestID(res?.data);
          //  const journey_id = res?.journey_id;
            const activity_score = res?.score;
            
           // sessionStorage.setItem('journey_id', journey_id);
            sessionStorage.setItem('activity_score', activity_score);
            sessionStorage.setItem('activity_scale', JSON.stringify(activity_scale)); 
          }
      }).catch((error) => {
        console.error('API Error:', error);
      });
}
    return(
        <div>
            <div className="kywBgImages">
                <div className="kywBgImagesInner">
                    <img alt="" id="kywBgLeft" src={ Assets.kywBg1 } />
                    <img alt="" id="kywBgRight" src={ Assets.kywBg2 } />
                </div>
            </div>
            {/* <NavbarKyw /> */}
            <NavbarHomePage />
            <MainContentKyw />
                <ContentFrameKyw9 
                    handleFormSubmit={handleFormSubmit} 
                    questionId={questionId} 
                    setQuestionId={setQuestionId}
                    sliderValue={sliderValue} setSliderValue={setSliderValue}
                    inputValue={inputValue} setInputValue={setInputValue}
                    sliderValue2={sliderValue2} setSliderValue2={setSliderValue2}
                    inputValue3={inputValue3} setInputValue3={setInputValue3}
                    sliderValue3={sliderValue3} setSliderValue3={setSliderValue3}
                    inputValue2={inputValue2} setInputValue2={setInputValue2}
                    sliderValue4={sliderValue4} setSliderValue4={setSliderValue4}
                    inputValue4={inputValue4} setInputValue4={setInputValue4}
                    handleSliderChange={handleSliderChange}
                    handleSliderChange2={handleSliderChange2}
                    handleSliderChange3={handleSliderChange3}
                    handleSliderChange4={handleSliderChange4}
                    handleInputChange={handleInputChange}
                    handleInputChange2={handleInputChange2}
                    handleInputChange3={handleInputChange3}
                    handleInputChange4={handleInputChange4}
                    data={data}
                    setData={setData}
                    setSleepData={setSleepData}
                    getSleepData={getSleepData}
                    message={message}
                    setMessage={setMessage}
                    getSleepscaleData={getSleepscaleData} 
                    setSleepscaleData={setSleepscaleData}
                    questID={questID} setQuestID={setQuestID}
                />
            <FooterKyw />
        </div>
    )
}

export default KnowYourWellness7;