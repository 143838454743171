// Home Blog Section

import blogContentImg1 from '../assets/blog-img-1.png'
import blogContentImg2 from '../assets/blog-img-2.png'
import blogContentImg3 from '../assets/blog-img-3.png'
import arrowUpRight from '../assets/arrow-up-right.png'
import arrowUpRightPink from '../assets/arrow-up-right-pink.png'
import blogContentBanner from '../assets/blog-content-bottom-banner.png'
import blogContentBannerResp from '../assets/blog-content-bottom-banner-resp.png'
import booking_confimation from  '../assets/Teleconsultation/booking_confimation.png'

// Home Teleconsultation Section

import consultationContentBg from '../assets/consultation-content-bg3.png'
import consultationContentBgResp from '../assets/consultaton-content-bg4.png'
import nutritionist from '../assets/nutritionist.png'
import nutritionistWhite from '../assets/nutritionist_white.png'
import gynaecologist from '../assets/Gynaecologist.png'
import gynaecologistWhite from '../assets/Gynaecologist_white.png'
import psychologist from '../assets/Psychologist.png'
import psychologistWhite from '../assets/Psychologist_white.png'
import specialistBubbleInner from '../assets/specialist-image-wave-inner.png'
import specialistBubbleOuter from '../assets/specialist-image-wave-outer.png'
import startQuotes from '../assets/quotes-home-screen.png'
import endQuotes from '../assets/end-quotes-home-screen.png'
import arrowUpRightBlack from '../assets/arrow-up-right-black.png'

// Featured And Footer

import HT from '../assets/HT.png'
import NDTV from '../assets/NDTV.png'
import VOGUE from '../assets/VOGUE.png'
import laiqaLogoPink from '../assets/laiqaLogoPink.png'
import downloadFromStore from '../assets/download-from-store.png'
import X from '../assets/twitter-mono.png'
import facebook from '../assets/facebook-mono.png'
import instagram from '../assets/instagram-mono.png'
import linkedin from '../assets/linkedin-mono.png'

// Know Your Wellness BMI Result Screen

import bmiLogo from "../assets/bmi_vector.svg"
import bmiBg from "../assets/Kyw/kyw3bg.svg"

// Know Your Wellness MWA Result Screen

import mwaLogo from "../assets/mwa-vector.svg"
import painActivityBg from "../assets/Kyw/kyw4bg.svg"

// Know Your Wellness Physical Activity Assessment

import kyw8bg from "../assets/Kyw/kyw8bg.svg"
import paaLogo from "../assets/activity_vector.svg"

// Know Your Wellness Sleep Test

import kyw11Bg from "../assets/Kyw/kyw5bg.svg"
import sleepLogo from "../assets/Kyw/sleep_vector.svg"

// Know Your Wellness Stress

import kyw14Bg from "../assets/Kyw/kyw14bg.svg"
import kyw14Bg2 from "../assets/Kyw/kyw15bg.svg"
import kyw14Bg3 from "../assets/Kyw/kyw16bg.svg"
import perceivedStressLogo from "../assets/Kyw/non-working-stress.svg"
import occupationalStressLogo from "../assets/Kyw/working-stress.svg"
import stressLogo from "../assets/Kyw/stress-logo.svg"

// Wellness Report

import wellnessReportBg from "../assets/Kyw/kyw-wellness-report-bg.svg"
import redHeart from "../assets/Kyw/red-heart.svg"
import greenHeart from "../assets/Kyw/green-heart.svg"
import orangeHeart from "../assets/Kyw/orange-heart.svg"
import mobileAppAd from "../assets/Kyw/mobile-app-ad.svg"
import appQr from "../assets/Kyw/app-qr.svg"
import appStore from "../assets/Kyw/app-store.svg"
import playStore from "../assets/Kyw/play-store.svg"

// Home Page Banner

import laiqaMainContentBgImg from '../assets/landing-page-banner-bg.png'
import laiqaMainContentBgImgResp from '../assets/responsive/landing_page_banner_responsive.png';
import phoneCalling from '../assets/phone-calling.png'
import phoneCallingWhite from '../assets/phone-calling-white.png'

// Home Navigation Bar

import laiqaLogo from '../assets/laiqaLogo.png';
import shoppingCartLogo from '../assets/shopping-cart-plus.png';
import arrowRight from '../assets/arrow-right.png';

// Login Popup

import loginPopupBg from '../assets/login-popup-bg.svg'

// Home Shop And Feedback

import shopAndFeedbackBg from '../assets/shop-and-feedback-bg.png'
import bigProductImage from '../assets/sample-product-1.png'
import shoppingCartWhite from '../assets/shopping-cart-plus.png'
import shoppingCartPink from '../assets/shopping-cart-plus-pink.png'
import shoppingCartViewImg from '../assets/shop-content-eye.png'
import shoppingCartViewImgWhite from '../assets/shop-content-eye-pink.png'
import shoppingCartLike from '../assets/heart.svg'
import shoppingCartLikeWhite from '../assets/heart-white.svg'
import ratingStar from '../assets/Star-1.png'
import product2 from '../assets/sample-product-2.png'
import product3 from '../assets/sample-product-3.png'
import product4 from '../assets/sample-product-4.png'
import product5 from '../assets/sample-product-5.png'
import product6 from '../assets/sample-product-6.png'
import product7 from '../assets/sample-product-7.png'
import feedbackContentImage from '../assets/feedbackContent-Image.png'
import feedbackQuotes from '../assets/feedback-quotes.png'
import feedbackRatingStars from '../assets/feedback-rating-stars.png'

// Home Why Only Us Section

// import whyOnlyUsBg1 from '../assets/why-only-us-bg-1.png'
// import whyOnlyUsBg2 from '../assets/why-only-us-bg-2.png'
// import whyOnlyUsImage from '../assets/why-only-us-image.png'
// Home Why Only Us Section

import whyOnlyUsBg1 from '../assets/why-only-us-bg-1.png'
import whyOnlyUsBg2 from '../assets/why-only-us-bg-2.png'
import whyOnlyUsImage1 from '../assets/why-only-us-image1.png'
import whyOnlyUsImage2 from '../assets/why-only-us-image2.png'
import whyOnlyUsImage3 from '../assets/why-only-us-image3.png'
import whyOnlyUsImage4 from '../assets/why-only-us-image4.png'
import whyOnlyUsHoverImage1 from "../assets/why-only-us-image-hover1.png"
import whyOnlyUsHoverImage2 from "../assets/why-only-us-image-hover2.png"
import whyOnlyUsHoverImage3 from "../assets/why-only-us-image-hover3.png"
import whyOnlyUsHoverImage4 from "../assets/why-only-us-image-hover4.png"

// Corporate Explore Section

import wellnessPlans from '../assets/Corporate/explore-corp-wellness-plans.png'
import teleconsultation from '../assets/Corporate/explore-corp-telecons.png'
import femaleWellnessProducts from '../assets/Corporate/explore-corp-fwp.png'
import askLaiqa from '../assets/Corporate/explore-corp-ask-laiqa.png'
import complianceContentBanner from '../assets/WellnessPlans/compliance-bottom-banner.png'

// Corporate Banner

import corporateBannerImg from '../assets/Corporate/corporate-banner-img.png'

// Corporate Why Only Us

import whyOnlyUsCorpImage from '../assets/Corporate/why-only-us-corp-image.png'

// Direct Plans Banner

import directPlansMainContentBg from '../assets/DirectPlans/directPlansMainContentBg.svg'

// Direct Plans Our Plans

import shoppingBag from '../assets/WellnessPlans/shopping-bag.png'
import phoneCall from '../assets/WellnessPlans/phone-call.png'
import phoneCallWhite from '../assets/WellnessPlans/phone-call-white.png'
import shoppingBagWhite from '../assets/WellnessPlans/shopping-bag-white.png'

// Direct Plans Schedule Call

import scheduleCallBg from "../assets/DirectPlans/schedule-call-direct-plans-bg.png"
import check from "../assets/Workshop/checkmark.svg"

// Direct Plans Understand How

import uhdpImage from "../assets/DirectPlans/understand-how-direct-plans-image.svg"

// Direct Plans Why Choose Us

import wcuStats from '../assets/Teleconsultation/telecons-wcu-stats.png'
import wcuList from '../assets/Teleconsultation/wcuList.png'
import arrowUpBlack from '../assets/arrow-up-right-black.png'

// Know Your Wellness 1

import kywBg1 from "../assets/Kyw/kywBgLeft.svg"
import kywBg2 from "../assets/Kyw/kywBgRight.svg"

// Know Your Wellness 1 Content Frame

import kyw1BgImage from "../assets/Kyw/kyw1bg.svg"
import kywListImages from "../assets/Kyw/kyw1MainContentListImages.png"

// Know Your Wellness Banner

import kywBg from '../assets/Kyw/kyw-page-heading-bg.svg'

// Know Your Wellness 2 Content Frame

import kyw2BgImage from "../assets/Kyw/kyw2bg.svg"
import kyw2ListImages from "../assets/Kyw/kyw2MainContentListImages.png"

// Know Your Wellness 3 Content Frame

import backArrow from "../assets/Kyw/arrow-narrow-left.svg"

// Self Prognosis Banner

import selfPrognosisMainContentBg from '../assets/SelfPrognosis/self-prognosis-main-content-bg.svg'

// Self Prognosis Schedule Call Section

import scheduleCallSpBg from "../assets/SelfPrognosis/schedule-call-self-prognosis-bg.svg"

// Self Prognosis Intro

import selfPrognosisIntroImage from '../assets/SelfPrognosis/self-prognosis-intro-image.svg'

// Teleconsultation Search Select Specialist

import arrowSort from '../assets/Teleconsultation/arrows-sort.png'
import arrowSortWhite from '../assets/Teleconsultation/arrows-sort-white.png'
import filterImage from '../assets/Teleconsultation/filter-edit.png'
import filterPlus from '../assets/Teleconsultation/plus-filter.png';
import filterPlusWhite from '../assets/Teleconsultation/plus-filter-white.png';
import filterCrossWhite from '../assets/Teleconsultation/cross.png';
import doctorPlaceholder from '../assets/Teleconsultation/doctor-placeholder.png'

// Teleconsultation Landing

import libido from '../assets/Teleconsultation/libido.png'
import cognitive from '../assets/Teleconsultation/cognitive.png'
import skinAndHair from '../assets/Teleconsultation/skin-and-hair.png'
import anxietyStressDepression from '../assets/Teleconsultation/anxiety-stress-depression.png'

// FAQ Content

import plus from "../assets/Teleconsultation/plus.svg"
import minus from "../assets/Teleconsultation/minus.svg"

// Teleconsultation Banner

import teleconsultationMainContentBg from '../assets/Teleconsultation/Telecons-main-content2.png'
import teleconsultationMainContentBgResp from '../assets/responsive/Telecons-main-content-resp.png'
import teleconsBottomBanner from '../assets/Teleconsultation/Telecons-main-content-bottom.png'

// Teleconsultation Navbar

import listPointerPink from '../assets/list-pointer-pink.png'

// Our Doctors Section

import ourDoctorsMainDoctorImg from '../assets/Teleconsultation/our-doctors-main-doctor-image.png'

// Teleconsultation Steps

import teleconsStep1 from '../assets/Teleconsultation/telecons-step-1.png';
import teleconsStep2 from '../assets/Teleconsultation/telecons-step-2.png'
import teleconsStep3 from '../assets/Teleconsultation/telecons-step-3.png'

// Wellness Plans Data Chart

import hormonalImbalanceBg from '../assets/WellnessPlans/hormonal-imbalance-bg.png';
import hormonalImbalanceBgResp from '../assets/responsive/hormonal-imbalance-bg-resp.png';
import years from '../assets/WellnessPlans/2013-2022.png';
import bubbleStats from '../assets/WellnessPlans/bubble-stats.png'

// Wellness Plans Banner

import laiqaMainContentMidLayer from '../assets/WellnessPlans/wellness-plans-middle-layer-bg2.png'
import laiqaMainContentMidLayerResp from '../assets/responsive/wellness-plans-middle-layer-bg-resp.png'

// Wellness Plans Scroll Section

import wellnessPlansScroll1 from '../assets/WellnessPlans/wellness-plans-scroll1.png'
import wellnessPlansScroll2 from '../assets/WellnessPlans/wellness-plans-scroll2.png'
import wellnessPlansScroll3 from '../assets/WellnessPlans/wellness-plans-scroll3.png'
import wellnessPlansScroll4 from '../assets/WellnessPlans/wellness-plans-scroll4.png'
import wellnessPlansScroll5 from '../assets/WellnessPlans/wellness-plans-scroll5.png'
import wellnessPlansScroll6 from '../assets/WellnessPlans/wellness-plans-scroll6.png'

// Wellness Plans Mission

import wellnessPlansMission from '../assets/WellnessPlans/wellness-plans-mission.png'

// Wellness Plans Women's Wellness

import womensPlansBg from '../assets/WellnessPlans/womenWellnessBg.png'
import womensPlansBgTopLayer from '../assets/WellnessPlans/wellness-plans-top-layer-bg.png'
import womensProblemsBgResp from '../assets/responsive/womens-problems-bg-resp.png'
import menstrualCramps from '../assets/WellnessPlans/menstrual-cramps.png'
import sleepIssues from '../assets/WellnessPlans/sleep-issues.png'
import weightGain from '../assets/WellnessPlans/weight-gain.png'
import thyroid from '../assets/WellnessPlans/thyroid.png'
import vaginalInfection from '../assets/WellnessPlans/vaginal-infection.png'

// Wellness Plans Checkout

import indianFlag from '../assets/indian-flag.png'
import wellnessPlansCheckoutBg from '../assets/Checkout/checkout-main-content-bg.png'

// Wellness Plans Checkout Success

import wellnessPlansCheckoutConfirmBg from '../assets/Checkout/checkout-confirm-main-content-bg.png'

// Wellness Plans Checkout Login Success

import successPartyImg from '../assets/Checkout/success-party.png'
import successCheckoutEndImage from '../assets/Checkout/checkout-success-download-links.png'

// Wellness Plans Price Listing Banner

import menstrualWellbeingBottomBanner from '../assets/WellnessPlans/menstrual-wellbeing-bottom-banner.png'

// Wellness Plans Price Listing Price List

import benefitsOfThePlan from '../assets/WellnessPlans/benefits-of-the-plan.png'

// Workshop Banner

import mainContentWorkshop from '../assets/Workshop/workshop-lp-banner.png'

// Workshop Register For Workshop Popup

import workshopPopupImage from "../assets/Workshop/rfw-popup-image.png"
import popupCloseCross from "../assets/Workshop/popup-close.svg"

// Workshop Register For Workshop Successful

import popupSuccessIcon from "../assets/Workshop/popup-success-icon.svg"

// Workshop Register For Workshop

import registerWorkshopBannerImg from '../assets/Workshop/register-workshop-banner-bg-img.png'
import rfwDoctorImage from '../assets/Teleconsultation/sample-doctor-female.png'
import calender from '../assets/Workshop/calendar.png'
import clock from '../assets/Workshop/clock.png'
import rfwRegisterBanner from '../assets/Workshop/register-workshop-banner.png'

// Workshop Intro

import workshopIntroImage from '../assets/Workshop/workshop-intro-image.png'

// Store
import storeMainContentBanner from "../assets/Store/store-main-content-banner.svg"
import storeMainContentBottomBanner from "../assets/Store/store-main-content-bottom-banner.svg"

// Blog
import blogMainContentBanner from "../assets/Blog/blog-main-content-bg.svg"
import blogImage1 from "../assets/Blog/blog-page-image-1.svg"
import blogImage2 from "../assets/Blog/blog-page-image-2.svg"
import blogImage3 from "../assets/Blog/blog-page-image-3.svg"
import blogBigImage1 from "../assets/Blog/blog-big-image-1.svg"

// Schedule Call Popup
import scheduleCallPopupBg from "../assets/callback-popup.svg"

// Contact Us
import mainContentContactUs from "../assets/contact-us-bg.svg"
import contactUsFormImage from "../assets/contact-us-form-image.svg"
import contactUsPhone from "../assets/contact-us-phone.svg"
import contactUsLocation from "../assets/contact-us-location.svg"
import contactUsMail from "../assets/contact-us-mail.svg"
import twitterWhite from "../assets/twitter-mono.svg"
import instagramWhite from "../assets/instagram-mono.svg"
import facebookWhite from "../assets/facebook-mono.svg"
import linkedInWhite from "../assets/linkedin-mono.svg"

// About Us
import mainContentAboutUS from "../assets/about-us-main-content-bg.svg"
import aboutUsIntroImage from "../assets/about-us-intro-image.svg"
import aboutUsKeyFeaturesBg from "../assets/about-us-key-features-bg.svg"
import app_store from '../assets/success/app_store.png'
import banner from '../assets/success/banner.png'
import checked from '../assets/success/check.png'
import qr from '../assets/success/qr.png'
import gp from '../assets/success/gp.png'
import aboutUsScroll1 from '../assets/AboutUs/about-us-scroll1.png'
import aboutUsScroll2 from '../assets/AboutUs/about-us-scroll2.png'
import aboutUsScroll3 from '../assets/AboutUs/about-us-scroll3.png'
import aboutUsScroll4 from '../assets/AboutUs/about-us-scroll4.png'
import checkk from '../assets/check.png'
import vectorDefault from '../assets/vector.jpg'

import doctor_profile from '../assets/telebook/Rectangle15613.png'
import Group163988 from '../assets/telebook/Group163988.png'
import Group163987 from '../assets/telebook/Group163987.png'
import Group163986 from '../assets/telebook/Group163986.png'
import Group_163746 from './../assets/telebook/Group_163746.png'
import calendar_icon from './../assets/telebook/calendar.png'
import arrow_up_right from './../assets/telebook/arrow-up-right.png'
import Homepagebanner from"../assets/responsive/Homepagebanner.webp"

import backdoe from"../assets/responsive/backdoe.jpeg"

import whnkl from"../assets/responsive/whnkl.webp"

import banner01 from '../assets/WellnessPlans/banners/banner_01.png'
import banner02 from '../assets/WellnessPlans/banners/banner_02.png'
import banner03 from '../assets/WellnessPlans/banners/banner_03.png'
import banner04 from '../assets/WellnessPlans/banners/banner_04.png'
import banner05 from '../assets/WellnessPlans/banners/banner_05.png'
import banner06 from '../assets/WellnessPlans/banners/banner_06.png'
import App_store from '../assets/App_store.png'
import play_store from '../assets/play_store.png'
import Group163633 from '../assets/Group163633.png'
import Group163634 from '../assets/Group163634.png'
import profile_default from '../assets/profile_default.jpg'
import Rectangle_15619 from '../assets/Whychoose/Rectangle_15619.png'
import Rectangle_15620 from '../assets/Whychoose/Rectangle_15620.png'
import Rectangle_15621 from '../assets/Whychoose/Rectangle_15621.png'
import selfprognosisbg from '../assets/SelfPrognosis/self-prognosis-bg.png'
import Group_163701 from '../assets/SelfPrognosis/Group_163701.png'
import Group_1637012 from '../assets/SelfPrognosis/Group_1637012.png'
import Group_1637013 from '../assets/SelfPrognosis/Group_1637013.png'
import coming_soon_Banner from '../assets/coming_soon_(Banner_(Landscape)).png'
import br_down from '../assets/br_down.webp'
import Group_164162 from '../assets/Teleconsultation/New/Group_164162.png'
import Group_164102 from '../assets/Teleconsultation/New/Group_164102.png'
import Group_164468 from '../assets/Teleconsultation/New/Group_164468.png'
import Group_164469 from '../assets/Teleconsultation/New/Group_164469.png'
import Group_164470 from '../assets/Teleconsultation/New/Group_164470.png'
import safe_secure from '../assets/booking/safe_secure.png'
import safesecure from '../assets/booking/safesecure.png'
import bit_256 from '../assets/booking/256_bit.png'

export const Assets = {
    
    blogContentImg1,
    blogContentImg2,
    blogContentImg3,
    arrowUpRight,
    arrowUpRightPink,
    blogContentBanner,
    blogContentBannerResp,
    booking_confimation,
    consultationContentBg,
    consultationContentBgResp,
    nutritionist,
    nutritionistWhite,
    gynaecologist,
    gynaecologistWhite,
    psychologist,
    psychologistWhite,
    specialistBubbleInner,
    specialistBubbleOuter,
    startQuotes,
    endQuotes,
    arrowUpRightBlack,

    HT,
    NDTV,
    VOGUE,
    laiqaLogoPink,
    downloadFromStore,
    X,
    facebook,
    instagram,
    linkedin,

    laiqaMainContentBgImg,
    laiqaMainContentBgImgResp,
    phoneCalling,
    phoneCallingWhite,

    laiqaLogo,
    shoppingCartLogo,
    arrowRight,

    loginPopupBg,

    shopAndFeedbackBg,
    bigProductImage,
    shoppingCartWhite,
    shoppingCartPink,
    shoppingCartViewImg,
    shoppingCartViewImgWhite,
    shoppingCartLike,
    shoppingCartLikeWhite,
    ratingStar,
    product2,
    product3,
    product4,
    product5,
    product6,
    product7,
    feedbackContentImage,
    feedbackQuotes,
    feedbackRatingStars,

    whyOnlyUsBg1,
    whyOnlyUsBg2,
    whyOnlyUsImage1,
    whyOnlyUsImage2,
    whyOnlyUsImage3,
    whyOnlyUsImage4,
    whyOnlyUsHoverImage1,
    whyOnlyUsHoverImage2,
    whyOnlyUsHoverImage3,
    whyOnlyUsHoverImage4,

    wellnessPlans,
    teleconsultation,
    femaleWellnessProducts,
    askLaiqa,
    complianceContentBanner,

    corporateBannerImg,

    whyOnlyUsCorpImage,

    directPlansMainContentBg,

    shoppingBag,
    phoneCall,
    phoneCallWhite,
    shoppingBagWhite,

    scheduleCallBg,
    check,

    uhdpImage,

    wcuStats,
    wcuList,
    arrowUpBlack,

    kywBg1,
    kywBg2,

    kyw1BgImage,
    kywListImages,

    kywBg,

    kyw2BgImage,
    kyw2ListImages,

    backArrow,

    bmiLogo,
    bmiBg,

    mwaLogo,
    painActivityBg,

    kyw8bg,
    paaLogo,

    kyw11Bg,
    sleepLogo,

    kyw14Bg,
    kyw14Bg2,
    kyw14Bg3,
    perceivedStressLogo,
    occupationalStressLogo,
    stressLogo,

    selfPrognosisMainContentBg,

    scheduleCallSpBg,

    selfPrognosisIntroImage,

    arrowSort,
    arrowSortWhite,
    filterImage,
    filterPlus,
    filterPlusWhite,
    filterCrossWhite,
    doctorPlaceholder,

    libido,
    cognitive,
    skinAndHair,
    anxietyStressDepression,

    plus,
    minus,

    teleconsultationMainContentBg,
    teleconsultationMainContentBgResp,
    teleconsBottomBanner,

    listPointerPink,

    ourDoctorsMainDoctorImg,

    teleconsStep1,
    teleconsStep2,
    teleconsStep3,

    wellnessPlansCheckoutConfirmBg,

    hormonalImbalanceBg,
    hormonalImbalanceBgResp,
    years,
    bubbleStats,

    laiqaMainContentMidLayer,
    laiqaMainContentMidLayerResp,

    wellnessPlansScroll1,
    wellnessPlansScroll2,
    wellnessPlansScroll3,
    wellnessPlansScroll4,
    wellnessPlansScroll5,
    wellnessPlansScroll6,

    wellnessPlansMission,

    womensPlansBg,
    womensPlansBgTopLayer,
    womensProblemsBgResp,
    menstrualCramps,
    sleepIssues,
    weightGain,
    thyroid,
    vaginalInfection,

    indianFlag,
    wellnessPlansCheckoutBg,

    successPartyImg,
    successCheckoutEndImage,    

    menstrualWellbeingBottomBanner,

    benefitsOfThePlan,

    mainContentWorkshop,

    workshopPopupImage,
    popupCloseCross,

    popupSuccessIcon,

    registerWorkshopBannerImg,
    rfwDoctorImage,
    calender,
    clock,
    rfwRegisterBanner,

    workshopIntroImage,

    storeMainContentBanner,
    storeMainContentBottomBanner,

    blogMainContentBanner,
    blogImage1,
    blogImage2,
    blogImage3,
    blogBigImage1,

    scheduleCallPopupBg,

    mainContentContactUs,
    contactUsFormImage,
    contactUsMail,
    contactUsPhone,
    contactUsLocation,

    twitterWhite,
    instagramWhite,
    facebookWhite,
    linkedInWhite,

    mainContentAboutUS,
    aboutUsIntroImage,
    aboutUsKeyFeaturesBg,

    wellnessReportBg,
    greenHeart,
    redHeart,
    orangeHeart,
    mobileAppAd,
    appQr,
    appStore,
    playStore,

    checked,
    app_store,
    banner,
    qr,
    gp,
    aboutUsScroll1,
    aboutUsScroll2,
    aboutUsScroll3,
    aboutUsScroll4,
    checkk,
    vectorDefault,

    doctor_profile,
    Group163988,
    Group163987,
    Group163986,
    Group_163746,
    calendar_icon,
    arrow_up_right,

    Homepagebanner,
    backdoe,
    whnkl,
    banner01,
    banner02,
    banner03,
    banner04,
    banner05,
    banner06,
    App_store,
    play_store,
    Group163633,
    Group163634,
    profile_default,
    Rectangle_15619,
    Rectangle_15620,
    Rectangle_15621,
    selfprognosisbg,
    Group_1637012,
    Group_1637013,
    Group_163701,
    coming_soon_Banner,
    br_down,
    Group_164162,
    Group_164102,
    Group_164468,
    Group_164469,
    Group_164470,
    safe_secure,
    safesecure,
    bit_256,
};