import { useNavigate } from "react-router-dom";
import { Assets } from "../Assets"

import { useState } from 'react';

const WhyChooseUsDirectPlans = () => {

    const navigate = useNavigate();

    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const doctor_list = () => {
        navigate(`/teleconsultation/select-specialist`)
    }
    return (
        <div className="whyChooseUsDirectPlansWrapper why_us">
            <div className="whyChooseUsWrapper">
                <div className="whyChooseUsStats">
                    <div className="wcuStatsWrapper">
                        {/* <img src={ Assets.wcuStats } alt="Why Choose Us" /> */}
                        <div className="grid_cnvt_content">
                            <div className="grid_bx">
                                <h2>1 <span> lakh+</span></h2>
                                <p>Women trusted us</p>
                            </div>
                            <div className="grid_bx">
                                <h2>4.7/5</h2>
                                <p>Rating across Services</p>
                            </div>
                            <div className="grid_bx">
                                <h2>90+</h2>
                                <p>Parameters</p>
                            </div>
                        </div>
                        <div className="wcuStatsButtonWrapper">
                            <button id="wcuStatsButton"
                                className={`${isHovered ? 'wcsStatsButtonHovered' : ''}`}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                onClick={doctor_list()}
                            >
                                <p>Let's Start</p>
                                {isHovered ? <img src={Assets.arrowUpRight} /> : <img src={Assets.arrowUpBlack} />}
                            </button>
                        </div>
                        <div className="wcuContactUsWrapper">
                            <button id="wcuContactUsButton"
                                className={`${isHovered ? 'wcsStatsButtonHovered' : ''}`}
                                onMouseEnter={() => setIsHovered2(true)}
                                onMouseLeave={() => setIsHovered2(false)}>
                                <p>Contact Us</p>
                                {isHovered2 ? <img src={Assets.phoneCallWhite} /> : <img src={Assets.phoneCall} />}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="whyChooseUsContent">
                    <div className="whyChooseUsTextContent">
                        <div className="whyChooseUsHeading">
                            <p className="wcuHeading1">Why us?</p>
                            <p className="wcuHeading2">We settle for nothing but the best for you! Embark on an unforgettable wellness journey with Laiqa.</p>
                        </div>
                    </div>
                    <div className="whyChooseUsList">
                        {/* <img src={ Assets.wcuList } /> */}
                        <div className="parent_why">
                            <div className="child_why">
                                <div className="grid_content">
                                    <img alt="" src={Assets.Rectangle_15619} />
                                    <div className="contentbx">
                                        <h4>For Corporates</h4>
                                        <p> Interactive workshops, team-based fitness challenges, mindfulness and breathwork sessions </p>
                                    </div>
                                </div>
                                <div className="grid_content">
                                    <img alt="" src={Assets.Rectangle_15620} />
                                    <div className="contentbx">
                                        <h4>For Employees</h4>
                                        <p> Personalized nutrition plans and dietary advice, workshops  to address employee well-being, and counselling sessions. </p>
                                    </div>
                                </div>
                                <div className="grid_content">
                                    <img alt="" src={Assets.Rectangle_15621} />
                                    <div className="contentbx">
                                        <h4>For Leaders</h4>
                                        <p> Peer interactions, fostering healthier workplace environments, and enhancing leadership effectiveness via self-reflection and skill-building activities. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyChooseUsDirectPlans;