import { Assets } from "../Assets"
import { Link } from "react-router-dom";
import { PostAPI } from "../utility/APiCall";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const FeaturedAndFooter = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0
        }
      }
    ]
  };

  const [data, setData] = useState({
    user_id: '',
    email: '',
  })
  ///web/mail_subscription
  const scrollToTop = () => {
    // setLoading(true);
    // setTimeout(() => {
    // setLoading(false);
    // }, 2000);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const user_id = sessionStorage.getItem('getUID');

    console.log('UserID Subscription', user_id);

    if (form.checkValidity()) {
      if (!user_id) {
        // Show alert if user is not logged in
        alert('Please log in first to subscribe.');
      } else {
        // Proceed with the API call if user_id is present
        const mailSubscriptionData = new FormData();

        mailSubscriptionData.append('user_id', user_id);
        mailSubscriptionData.append('email', data?.email);

        PostAPI({ url: '/web/mail_subscription', data: mailSubscriptionData }, (confirmSubscriptionData) => {
          if (confirmSubscriptionData.status == 1) {
            toast.success("Mail Subscribed");
          } else {
            // Handle failure
            if (confirmSubscriptionData.status == 0) {
              toast.error(confirmSubscriptionData.message);
              alert(confirmSubscriptionData.message);
              console.log('failure:', confirmSubscriptionData.message);
            }
          }
        });
      }
    }
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const listGallery = [{
    id: 1,
    src: Assets.HT,
    name: 'Hindustan Times',
  },
  {
    id: 2,
    src: Assets.NDTV,
    name: 'NDTV',
  },
  {
    id: 3,
    src: Assets.VOGUE,
    name: 'VOGUE'
  },
  {
    id: 4,
    src: Assets.HT,
    name: 'HT'
  },
  {
    id: 5,
    src: Assets.NDTV,
    name: 'NDTV'
  }]

  useEffect(() => {
    let interval = setInterval(() => {
      setCurrentIndex(prevIndex =>
        prevIndex === listGallery.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="featuredAndFooterContent featuredAndFooterContent_new">
        <div className="featuredContent">
          <div className="featuredContentWrapper">
            <div className="featuredContentHeadingWrapper">
              <p className="featuredContentHeading1">Recognized</p>
              <p className="featuredContentHeading2">We got featured!</p>
            </div>
            <ul className="featuredContentList new">
              <Slider {...settings}>
                <div>
                  <img src={Assets.NDTV} alt="NDTV" />
                </div>
                <div>
                  <img src={Assets.HT} alt="HT" />
                </div>
                <div>
                  <img src={Assets.VOGUE} alt="VOGUE" />
                </div>
                {/* <div>
                  <img src={Assets.NDTV} alt="NDTV" />
                </div> */}
              </Slider>
            </ul>
          </div>
        </div>
        <div className="footerContent">
          <div className="footerContentWrapper">
            <div className="footerContentWrapperInner">
              <div className="footerBrandingBottomLeft">
                <Link to="/">
                  <img
                    className="footerLaiqaLogoPink"
                    src={Assets.laiqaLogoPink}
                    alt="My Laiqa"
                  />
                </Link>
                <p>Download Mobile App today!</p>
                <Link to="https://apps.apple.com/us/app/period-pal-by-laiqa/id1591718639">
                  <img
                    className="footerDownloadLaiqa"
                    src={Assets.App_store}
                    alt="App store"
                  />
                </Link>
                <Link to="https://play.google.com/store/apps/details?id=com.laiqa&pli=1">
                  <img
                    className="footerDownloadLaiqa"
                    src={Assets.play_store}
                    alt="play store"
                  />
                </Link>
                <div className="socialMedia">
                  <Link to="https://x.com/i/flow/login?redirect_after_login=%2FOurLaiqa"><img src={Assets.X} alt="X (Twitter)" /></Link>
                  <Link to="https://www.facebook.com/OurLaiqa/"><img src={Assets.facebook} alt="Facebook" /></Link>
                  <Link to="https://www.instagram.com/ourlaiqa/"><img src={Assets.instagram} alt="Instagram" /></Link>
                  {/* <Link to="/"><img src={Assets.linkedin} alt="LinkedIn" /></Link> */}
                </div>
              </div>
              <div className="footerQuickLinks">
                <p>Quick Links</p>
                <ul className="footerQuickLinksUl">
                  <li>
                    <Link
                      onClick={scrollToTop}
                      to="/wellness-plans"
                    >
                      Wellness Plans
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollToTop} to="/workshops">
                      Workshop
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollToTop}
                      to="/teleconsultation"
                    >
                      Teleconsultation
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollToTop} to="/blog">
                      Blogs
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollToTop} to="/about-us">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollToTop}
                      to="/contact-us"
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footerEngageAndOther">
                <p className="footerEngageHeading">Engage</p>
                <ul className="footerEngageUl">
                  <li>
                    <Link to="https://www.mylaiqa.com">Shop</Link>
                  </li>
                  <li>
                    <Link onClick={scrollToTop} to="/corporate">
                      Corporate
                    </Link>
                  </li>
                </ul>
                <p className="footerOtherHeading">Other</p>
                <ul className="footerEngageUl">
                  <li>
                    <Link to="https://mylaiqa.com/privacy-policy/">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="https://mylaiqa.com/terms-and-conditions/">
                      Terms &amp; Conditions
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footerNewsletter">
                <p>Allow us to slip into your inbox</p>
                <form
                  onSubmit={handleFormSubmit}
                  name={data?.id ? "update" : "insert"}>
                  <input
                    placeholder="Your email address"
                    className="footerNewsletterEmail"
                    type="text"
                    onChange={(e) => setData({ ...data, email: e.target.value })} value={data.email}
                    name="email"
                    autocomplete="off"
                  />
                  <button type="submit" className="footerNewsletterSubmitButton">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default FeaturedAndFooter;